import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimelineUploadService {

  public uploader;

  public filePreview;
  public mediaPreview;

  // who can see the post
  public typeSee = '1';

  // Text
  public text;
  public height;


  constructor() { }

  onClearData() {
    this.uploader = null;
    this.text = '';
    this.height = '80px';
    this.mediaPreview = [];
    this.filePreview = [];
    this.typeSee = '1';
  }
}
