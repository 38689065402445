import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {DefaultThemeComponent} from '../../../../component/Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {JobService} from '../../../../service/Job/job.service';

@Component({
  selector: 'app-staff-dialog',
  templateUrl: './staff-dialog.component.html',
  styleUrls: ['./staff-dialog.component.css']
})
export class StaffDialogComponent implements OnInit {

  private jobs;
  public theme: DefaultThemeComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialog: MatDialog,
              private themeService: ThemeService, private jobService: JobService) { }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.jobService.getAllJob().subscribe(data => {
      this.jobs = data;
    }, error => {
      console.log(error);
    });
  }

  onClose() {
    this.dialog.closeAll();
  }

  getJobLabel(id) {
    if (this.jobs) {
      return this.jobs.find((f) => {
        return f.id == id;
      }).label;
    }
  }

  getJobById(id) {
    if (this.jobs) {
      return this.jobs.find((f) => {
        return f.id == id;
      });
    }
  }
}
