import {Component, Input, OnInit} from '@angular/core';
import {UserService} from "../../../../../service/User/user.service";
import {DeleteDialogComponent} from "../../../../../dialog/delete-dialog/delete-dialog.component";
import {MatDialog} from "@angular/material";
import {DarkmodeService} from "../../../../../service/darkmode.service";
import {GifDialogComponent} from "../../../../../dialog/menu/timeline/gif-dialog/gif-dialog.component";
import * as $ from 'jquery';
import {TimelineService} from "../../../../../service/Timeline/timeline.service";
import {ThemeService} from "../../../../../service/Theme/theme.service";
import {DefaultThemeComponent} from "../../../../Common/Theme/default-theme/default-theme.component";
import {AvatarService} from "../../../../../service/common/avatar.service";
import {MentionService} from "../../../../../service/common/mention.service";

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {

  @Input() data;
  @Input() isDarkMode;
  @Input() refresh;
  @Input() isPreview;

  private tmpData;
  public admin;

  //comment
  public commentData;
  public commentLength;

  public haveMoreComment;
  public saveNextComment;
  public currentComment;

  public openEmoji;
  public menuOpen;
  public tmpEditText;

  // theme
  public theme: DefaultThemeComponent;

  constructor(private userService: UserService, private dialog: MatDialog, public darkmodeService: DarkmodeService,
              private timelineService: TimelineService, private themeService: ThemeService,
              private avatarService: AvatarService, public mentionService: MentionService) { }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.admin = this.userService.getToken().user;
    this.menuOpen = false;
    this.updateData();
    this.darkmodeService.isEmojiOpen().subscribe(data => {
      this.openEmoji = data;
    });

    this.refresh.subscribe(data => {
      this.updateData();
    });

    // SET CURSOR POSITION
    $.fn.setCursorPosition = function(pos) {
      this.each(function(index, elem) {
        if (elem.setSelectionRange) {
          elem.setSelectionRange(pos, pos);
        } else if (elem.createTextRange) {
          var range = elem.createTextRange();
          range.collapse(true);
          range.moveEnd('character', pos);
          range.moveStart('character', pos);
          range.select();
        }
      });
      return this;
    };

    const self = this;
    $(document).on('click', function(e) {
      if(e.target.nodeName == "DIV" || e.target.nodeName.includes('MAT-CARD') || e.target.nodeName.includes('MAT-CHIP')){
        if(e.target.className != "emoji-mart") {
          if(e.target.childNodes[0] && e.target.childNodes[0].nodeName == 'svg') {
          }else{
            self.openEmoji = false;
          }
        }
      }
    });
  }

  public updateData() {
    this.timelineService.getCommentByPostId(this.data._id).subscribe(data => {
      this.currentComment = data;
      if(this.currentComment.length > 0) {
        this.getNextComment(this.currentComment[0]._id);
      }
    }, error => {
      console.log(error);
    });
  }

  // get next Comment before hand
  getNextComment(lastCommentID) {
    this.timelineService.getMoreComment(this.data._id, lastCommentID).subscribe(data => {
      this.saveNextComment = data;
      this.haveMoreComment = this.saveNextComment.length > 0;
    }, error => {
      console.log(error);
    });
  }

  onMoreComment() {
    if (this.haveMoreComment && this.saveNextComment.length > 0) {
      this.currentComment = this.saveNextComment.concat(this.currentComment);
      this.saveNextComment = [];
      this.getNextComment(this.currentComment[0]._id);
    }
  }

  convertText(comment) {
    comment = this.mentionService.convertMentionStringToViewString(comment);
    return comment;
  }

  // edit and delete comment
  onEdit(comment) {
    comment.editComment = true;
    this.tmpEditText = comment.message;
    setTimeout(() => {
      $('#editCommentArea').focus().height($('#editCommentArea').prop('scrollHeight')+15);
    }, 100);
  }

  onEditComment(event, comment) {
    if(event.key === 'Enter') {
      comment.message = this.removeEnter(comment.message);
      if(comment.message == '') {
        this.onDelete(event, comment);
      } else {
        const sendData = {
          contentType : "post",
          message: this.removeEnter(comment.message)
        };
        this.timelineService.updateComment(comment._id, sendData).subscribe(data => {
          this.updateData();
        }, error => {
          console.log(error);
        })
      }
    }
  }

  removeEnter(data) {
    return data.replace(/(\r\n|\n|\r)/gm, "");
  }

  onDelete(event, comment) {
    const dialogDelete = this.dialog.open(DeleteDialogComponent, {
      width: '500px'
    });

    dialogDelete.afterClosed().subscribe(data => {
      if(data) {
        this.timelineService.deleteMainComment(comment._id).subscribe(data => {
          this.updateData();
        }, error => {
          console.log(error);
        });
      } else {
        if(event) {
          event.target.style.height = '20px';
        }
      }
    })
  }

  onCancelEdit(comment) {
    comment.editComment = false;
    comment.message = this.tmpEditText;
  }

  onGifDialog(comment) {
    const sentData = {
      post: comment,
      type: 'update-comment'
    };

    const dia = this.dialog.open(GifDialogComponent, {
      data: sentData,
      maxWidth: '360px',
    });

    dia.afterClosed().subscribe((result) => {
      if(result) {
        this.updateData();
      }
    });
  }

  selectedEditEmoji(event, data) {
    data.message += event.emoji.native;
  }

  // like / unlike comment / replay comment
  onLikeComment(comment) {
    if(comment.isLike) {
      this.timelineService.postUnlikeComment(comment._id).subscribe(data => {
        comment.isLike = false;
        comment.contentStat.totalLikes--;
      }, error => {
        console.log(error);
      })
    } else {
      this.timelineService.postLikeComment(comment._id).subscribe(data => {
        comment.isLike = true;
        comment.contentStat.totalLikes++;
      }, error => {
        console.log(error);
      });
    }
  }

  onReplyComment(comment) {
    comment.openSubComment = true;
    setTimeout( () => {
      $(`#${comment._id}`).focus().setCursorPosition(1);
    }, 100);
  }

  /*comment input*/
  onComment(event, data) {
    if(event.key === 'Enter' && data.comment) {
      const sendData = {
        contentType : "post",
        message: this.removeEnter(data.comment)
      };

      this.timelineService.postCommentByPostId(data._id, sendData).subscribe(data => {
        this.updateData();
      }, error => {
        console.log(error);
      });

      data.comment = '';
      event.target.value = '';
      event.target.style.height = '20px';
    }
  }

  onTypeComment(event) {
    event.target.style.height = "20px";
    event.target.style.height = Math.min(event.target.scrollHeight+15) + "px";
  }

  onReleaseComment(event) {
    if(!event.target.value) {
      event.target.style.height = '20px';
    } else {
      event.target.style.height = "20px";
      event.target.style.height = Math.min(event.target.scrollHeight+15) + "px";
    }
  }

  selectedEmoji(event, data){
    if(data.message) {
      data.message += event.emoji.native;
    } else {
      data.message = '';
      data.message += event.emoji.native;
    }
  }
}
