import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material';
import {GroupDialogComponent} from '../../../../dialog/menu/group/group-dialog/group-dialog.component';
import {AddGroupDialogComponent} from '../../../../dialog/menu/group/add-group-dialog/add-group-dialog.component';
import {DeleteDialogComponent} from '../../../../dialog/delete-dialog/delete-dialog.component';
import {DarkmodeService} from '../../../../service/darkmode.service';
import {UserService} from '../../../../service/User/user.service';
import {GroupService} from '../../../../service/Group/group.service';
import {DefaultThemeComponent} from "../../../Common/Theme/default-theme/default-theme.component";
import {ThemeService} from "../../../../service/Theme/theme.service";


@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

  public isDarkmode;

  public groupData;
  private userData;
  private tmpData;

  public sompomLogo = '../../../../../assets/image/sompom-logo.png';

  public numberOfGroup;

  // theme
  public theme: DefaultThemeComponent;

  constructor(public dialog: MatDialog, private darkmodeService: DarkmodeService, private themeService: ThemeService,
              private userService: UserService, private groupService: GroupService) {
  }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.isDarkmode = this.darkmodeService.getLocalDarkMode();
    this.darkmodeService.getDarkMode().subscribe(data => {
      this.isDarkmode = data;
    });
    this.userService.getAllUser().subscribe(data => {
      this.userData = data;
    });
    this.updateData();
  }

  onAddData() {
    const dia = this.dialog.open(AddGroupDialogComponent, {
      width: '500px'
    });

    dia.afterClosed().subscribe(result => {
      if (result) {
        this.updateData();
      }
    });
  }

  onDeleteData(id) {
    const deleteDialog = this.dialog.open(DeleteDialogComponent, {
      width: '500px'
    });

    deleteDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.groupService.updateGroup(id, {isDeleted: 'true'}).subscribe(data => {
          this.updateData();
          this.groupService.getConversationByGroupId(id).subscribe(data => {
            this.tmpData = data;
            this.groupService.updateConversation(this.tmpData._id, {isDeleted: 'true'}).subscribe(data => {
            }, error => {
              console.log(error);
            });
          }, error => {
            console.log(error);
          });
        }, error => {
          console.log(error);
        });
      }
    });
  }

  onEdit(id) {
    const clickData = this.groupData.find((f) => {
      return f.id == id;
    });

    const dia = this.dialog.open(GroupDialogComponent, {
      data: clickData,
      width: '700px'
    });

    dia.afterClosed().subscribe(result => {
      if (result) {
        this.updateData();
      }
    });
  }

  getParticipantsFullName(id) {
    if (this.userData) {
      const user = this.userData.find((f) => {
        return f.id == id;
      });
      return user.firstName + ' ' + user.lastName;
    }
  }

  getUser(id) {
    if (this.userData) {
      return this.userData.find(f => {
        return f.id == id;
      });
    }
  }


  updateData() {
    this.groupService.getAllGroup().subscribe(data => {
      this.groupData = data;
      this.groupData = this.groupData.filter((f) => {
        return f.isDeleted != 'true';
      });
      this.groupData = this.groupData.sort(function(a, b) {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      this.numberOfGroup = this.groupData.length;
    }, error => {
      console.log(error);
    });


  }

  applyFilter(filterValue) {
    if (filterValue) {
      this.groupService.getAllGroup().subscribe(data => {
        this.groupData = data;
        this.groupData = this.groupData.filter((f) => {
          return !f.isDeleted;
        });
        this.groupData = this.groupData.filter((f) => {
          return f.name.trim().toLowerCase().includes(filterValue.trim().toLowerCase());
        });
      }, error => {
        console.log(error);
      });
    } else {
      this.updateData();
    }
  }

  async getAllMember(group) {
    // group.participants.forEach((g) => {
    //   const user = this.menuService.getUserById(g).toPromise();
    //   console.log(user);
    // });
    console.log(group);
  }
}
