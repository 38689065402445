import { BrowserModule } from '@angular/platform-browser';
import {NgModule, TRANSLATIONS, TRANSLATIONS_FORMAT} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MatAutocompleteModule,
  MatButtonModule, MatButtonToggleModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDividerModule,
  MatInputModule,
  MatListModule,
  MatMenuModule, MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSidenavModule, MatSlideToggleModule,
  MatSortModule, MatTableModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {AddNewDialogComponent} from './dialog/organizationchart/add-new-dialog/add-new-dialog.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { ErrorDialogComponent } from './dialog/organizationchart/error-dialog/error-dialog.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { EditDialogComponent } from './dialog/organizationchart/edit-dialog/edit-dialog.component';
import {MatTabsModule} from '@angular/material/tabs';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { StaffComponent } from './component/dashboard/menu/staff/staff.component';
import { IntervenantsComponent } from './component/dashboard/menu/intervenants/intervenants.component';
import { OrganizationComponent } from './component/dashboard/menu/organization/organization.component';
import { GroupsComponent } from './component/dashboard/menu/groups/groups.component';
import { TimelineComponent } from './component/dashboard/menu/timeline/timeline.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import {CdkTableModule} from '@angular/cdk/table';
import { GroupDialogComponent } from './dialog/menu/group/group-dialog/group-dialog.component';
import { AddGroupDialogComponent } from './dialog/menu/group/add-group-dialog/add-group-dialog.component';
import { AddStaffDialogComponent } from './dialog/menu/staff/add-staff-dialog/add-staff-dialog.component';
import { StaffDialogComponent } from './dialog/menu/staff/staff-dialog/staff-dialog.component';
import { AddIntervenantDialogComponent } from './dialog/menu/intervenant/add-intervenant-dialog/add-intervenant-dialog.component';
import { IntervenantDialogComponent } from './dialog/menu/intervenant/intervenant-dialog/intervenant-dialog.component';
import { AddExistDialogComponent } from './dialog/organizationchart/add-exist-dialog/add-exist-dialog.component';
import { DeleteDialogComponent } from './dialog/delete-dialog/delete-dialog.component';
import {HttpConfigInterceptor} from './service/common/httpconfig.interceptor';
import { JobComponent } from './component/dashboard/menu/job/job.component';
import { AddJobDialogComponent } from './dialog/menu/job/add-job-dialog/add-job-dialog.component';
import { JobDialogComponent } from './dialog/menu/job/job-dialog/job-dialog.component';
import { ExceptionDialogComponent } from './dialog/organizationchart/exception-dialog/exception-dialog.component';
import {DatePipe} from '@angular/common';
import { PreviewMediaDialogComponent } from './dialog/menu/timeline/preview-media-dialog/preview-media-dialog.component';
import { ErrorComponent } from './component/error/error.component';
import { EditCompanyNodeDialogComponent } from './dialog/organizationchart/edit-company-node-dialog/edit-company-node-dialog.component';
import {FileUploadModule} from 'ng2-file-upload';
import { AddDepartmentComponent } from './dialog/organizationchart/add-department/add-department.component';
import { ChangeUserDialogComponent } from './dialog/organizationchart/change-user-dialog/change-user-dialog.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { InputDialogComponent } from './dialog/menu/timeline/input-dialog/input-dialog.component';
import { SettingComponent } from './component/setting/setting.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PostComponent} from './component/dashboard/menu/timeline/post/post.component';
import { GifDialogComponent } from './dialog/menu/timeline/gif-dialog/gif-dialog.component';
import { SubcommentComponent } from './component/dashboard/menu/timeline/subcomment/subcomment.component';
import { MediaComponent } from './component/dashboard/menu/timeline/media/media.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { RightPreviewMediaDialogComponent } from './dialog/menu/timeline/preview-media-dialog/right-preview-media-dialog/right-preview-media-dialog.component';
import { CommentComponent } from './component/dashboard/menu/timeline/comment/comment.component';
import { MatLinkPreviewModule } from '@angular-material-extensions/link-preview';
import {NgxLinkifyjsModule} from 'ngx-linkifyjs';
import { LinkPreviewComponent } from './component/Common/link-preview/link-preview.component';
import { UpdatePostDialogComponent } from './dialog/menu/timeline/update-post-dialog/update-post-dialog.component';
import { DefaultThemeComponent } from './component/Common/Theme/default-theme/default-theme.component';
import { DarkThemeComponent } from './component/Common/Theme/dark-theme/dark-theme.component';
import { RedThemeComponent } from './component/Common/Theme/red-theme/red-theme.component';
import { NotificationDialogComponent } from './dialog/notification-dialog/notification-dialog.component';
import { AvatarUpdateComponent } from './component/avatar-update/avatar-update.component';
import { PostDetailDialogComponent } from './dialog/menu/timeline/post-detail-dialog/post-detail-dialog.component';
import { ViewListDialogComponent } from './dialog/menu/timeline/view-list-dialog/view-list-dialog.component';
import { LikedListDialogComponent } from './dialog/menu/timeline/liked-list-dialog/liked-list-dialog.component';
import { CurrentProfileDialogComponent } from './dialog/current-profile-dialog/current-profile-dialog.component';
import { ProfileDialogComponent } from './dialog/menu/timeline/profile-dialog/profile-dialog.component';
import { AgmCoreModule } from '@agm/core';
import { NotificationSettingComponent } from './component/setting/notification-setting/notification-setting.component';
import { DayNotificationSettingComponent } from './component/setting/notification-setting/day-notification-setting/day-notification-setting.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AddNewDialogComponent,
    ErrorDialogComponent,
    EditDialogComponent,
    StaffComponent,
    IntervenantsComponent,
    OrganizationComponent,
    GroupsComponent,
    TimelineComponent,
    DashboardComponent,
    GroupDialogComponent,
    AddGroupDialogComponent,
    AddStaffDialogComponent,
    StaffDialogComponent,
    AddIntervenantDialogComponent,
    IntervenantDialogComponent,
    AddExistDialogComponent,
    DeleteDialogComponent,
    JobComponent,
    AddJobDialogComponent,
    JobDialogComponent,
    ExceptionDialogComponent,
    PreviewMediaDialogComponent,
    ErrorComponent,
    EditCompanyNodeDialogComponent,
    AddDepartmentComponent,
    ChangeUserDialogComponent,
    InputDialogComponent,
    SettingComponent,
    PostComponent,
    GifDialogComponent,
    SubcommentComponent,
    MediaComponent,
    RightPreviewMediaDialogComponent,
    CommentComponent,
    LinkPreviewComponent,
    UpdatePostDialogComponent,
    DefaultThemeComponent,
    DarkThemeComponent,
    RedThemeComponent,
    NotificationDialogComponent,
    AvatarUpdateComponent,
    PostDetailDialogComponent,
    ViewListDialogComponent,
    LikedListDialogComponent,
    CurrentProfileDialogComponent,
    ProfileDialogComponent,
    NotificationSettingComponent,
    DayNotificationSettingComponent
  ],
  entryComponents: [
    AddNewDialogComponent,
    ErrorDialogComponent,
    EditDialogComponent,
    GroupDialogComponent,
    AddGroupDialogComponent,
    AddStaffDialogComponent,
    StaffDialogComponent,
    AddIntervenantDialogComponent,
    IntervenantDialogComponent,
    AddExistDialogComponent,
    DeleteDialogComponent,
    AddJobDialogComponent,
    JobDialogComponent,
    ExceptionDialogComponent,
    PreviewMediaDialogComponent,
    EditCompanyNodeDialogComponent,
    AddDepartmentComponent,
    ChangeUserDialogComponent,
    InputDialogComponent,
    GifDialogComponent,
    UpdatePostDialogComponent,
    NotificationDialogComponent,
    PostDetailDialogComponent,
    ViewListDialogComponent,
    LikedListDialogComponent,
    CurrentProfileDialogComponent,
    ProfileDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatToolbarModule,
    MatCardModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    MatSnackBarModule,
    MatTabsModule,
    HttpClientModule,
    MatMenuModule,
    CdkTableModule,
    MatTableModule,
    MatSortModule,
    MatSidenavModule,
    MatDividerModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    FileUploadModule,
    PickerModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    InfiniteScrollModule,
    AgmCoreModule.forRoot({
      apiKey: ''
    }),
    MatLinkPreviewModule.forRoot(),
    NgxLinkifyjsModule.forRoot({enableHash: false}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
