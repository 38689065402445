import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {UploadService} from '../../../service/common/upload.service';
import {UserService} from '../../../service/User/user.service';
import {SettingService} from '../../../service/Setting/setting.service';
import {JobService} from '../../../service/Job/job.service';
import {DefaultThemeComponent} from '../../../component/Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../service/Theme/theme.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.css']
})
export class EditDialogComponent implements OnInit, AfterViewInit {

  public pageLoaded;

  // form
  public editUserForm: FormGroup;
  public oldUserData;
  public didChangePassword;
  public updateUserData;

  // job
  public jobs;

  // upload image
  public uploader: FileUploader;
  private uploadPreset;
  private uploadUrl;
  private uploadClouldName;
  public doneProcess;

  // cover picture
  public profileCover;

  // theme
  public theme: DefaultThemeComponent;

  @ViewChild('pass') pass: ElementRef;

  constructor(private dialog: MatDialogRef<EditDialogComponent>, private themeService: ThemeService,
              private settingService: SettingService, @Inject(MAT_DIALOG_DATA) public data,
              private uploadService: UploadService, private userService: UserService, private jobService: JobService) {
  }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.doneProcess = true;
    this.uploadClouldName = this.uploadService.uploadClouldName;
    this.uploadUrl = this.uploadService.uploadUrl;
    this.uploadPreset = this.uploadService.uploadProfilePreset;

    // init form
    this.editUserForm = new FormGroup({
        name: new FormControl(''),
        encryption: new FormControl(''),
        video: new FormControl(''),
        audio: new FormControl(''),
        contact: new FormControl('')
    });

    // save old data
    this.oldUserData = {
      name: this.data.name,
      encryption: this.data.encryption,
      video: this.data.video,
      audio: this.data.audio,
      contact: this.data.contact
    };

    // set data to form
    this.editUserForm.get('name').setValue(this.data.name);
    this.editUserForm.get('encryption').setValue(this.data.encryption);
    this.editUserForm.get('video').setValue(this.data.video);
    this.editUserForm.get('audio').setValue(this.data.audio);
    this.editUserForm.get('contact').setValue(this.data.contact);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 1000);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.jobs.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  onClose() {
    this.dialog.close(false);
  }

  detectChange() {
    const userData = this.editUserForm.value;
    const allow = [];
    if (userData.name !== this.oldUserData.name) { allow.push('name'); }
    if (userData.encryption !== this.oldUserData.encryption) { allow.push('encryption'); }
    if (userData.video !== this.oldUserData.video) { allow.push('video'); }
    if (userData.audio !== this.oldUserData.audio) { allow.push('audio'); }
    if (userData.contact !== this.oldUserData.contact) { allow.push('contact'); }
    return allow;
  }

  sentUpdateCustomer(id, updateUserData) {
    this.userService.updateCustomer(id, updateUserData).subscribe(response => {
      this.dialog.close(true);
    });
  }

  onSubmit() {
    if (this.editUserForm.valid) {
      const data = this.editUserForm.value;
      const clientID = this.data.id;
      this.sentUpdateCustomer(clientID, data);
    }
  }

  onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    this.viewImageOffline(file);
  }

  viewImageOffline(input) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.data.profileUrl = e.target.result;
    };
    reader.readAsDataURL(input);
  }

  // bool
  isAllowChange() {
    return this.editUserForm.valid && this.isDataChange();
  }

  onSlideChange(slideName) {
    const eachSlideToggleValues = this.editUserForm.value;

    switch (slideName) {
        case 'encryption':
            const encryption  = !eachSlideToggleValues.encryption;
            this.editUserForm.get('encryption').setValue(encryption);
            break;
        case 'video':
            const video  = !eachSlideToggleValues.video;
            this.editUserForm.get('video').setValue(video);
            break;
        case 'audio':
            const audio  = !eachSlideToggleValues.audio;
            this.editUserForm.get('audio').setValue(audio);
            break;
        case 'contact':
            const contact  = !eachSlideToggleValues.contact;
            this.editUserForm.get('contact').setValue(contact);
            break;
    }
  }

  onChange(event) {
      console.log(event)
  }

  isDataChange() {
    return this.oldUserData.name !== this.editUserForm.get('name').value ||
      this.oldUserData.encryption !== this.editUserForm.get('encryption').value ||
      this.oldUserData.video !== this.editUserForm.get('video').value ||
      this.oldUserData.audio !== this.editUserForm.get('audio').value ||
      this.oldUserData.contact !== this.editUserForm.get('contact').value;
  }
}
