import { Component, OnInit } from '@angular/core';
import {DefaultThemeComponent} from '../default-theme/default-theme.component';

@Component({
  selector: 'app-dark-theme',
  template: ''
})
export class DarkThemeComponent extends DefaultThemeComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

  backgroundPrimary() { return 'background-primary-darkmode'; }
  backgroundSecondary() { return 'background-secondary-darkmode'; }
  colorPrimary() { return 'color-primary-darkmode'; }
  bubblePrimary() { return 'bubble-primary-darkmode'; }
  bubbleSecondary() { return 'bubble-secondary-darkmode'; }
  bubbleDisable() { return 'bubble-disable-darkmode'; }
  iconPrimary() { return 'icon-primary-darkmode'; }
  iconButton() { return 'icon-button-darkmode'; }
  buttonHover() { return 'button-hover-darkmode'; }
}
