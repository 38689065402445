import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TenantService} from '../tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  // local
  // public url = 'http://localhost:1337/';

  // development
  // public url = 'http://209.97.161.132:7580/';

  // Production
  public url = 'https://api.proappcustomers.sompom.com/';

  constructor(private http: HttpClient, private tenantService: TenantService) {
    this.getDomain();
  }

  getDomain() {
    // domain is the head of host url
    // ex: we host proapp admin at abc.proapp.sompom.com, so the domain = abc;
    const domain = this.tenantService.getTenant();

    if (domain) {
      // we will serve from [head]-api.proapp.sompom.com
      // ex: [head] = abc, so the api will serve from abc-api.proapp.sompom.com
      this.url = 'http://' + domain + '-api.proappcustomers.sompom.com/';
      console.log('this is ' + domain);
    } else {
      console.log('there no head');
    }
  }

  get(customUrl, baseUrlCustom = this.url) {
    return this.http.get(baseUrlCustom + customUrl);
  }

  post(customUrl, data) {
    return this.http.post(this.url + customUrl, data);
  }

  put(customUrl, data) {
    return this.http.put(this.url + customUrl, data);
  }

  delete(customUrl) {
    return this.http.delete(this.url + customUrl);
  }
}
