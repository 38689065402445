import { Component, OnInit } from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {DeleteDialogComponent} from '../../../../dialog/delete-dialog/delete-dialog.component';
import {JobDialogComponent} from '../../../../dialog/menu/job/job-dialog/job-dialog.component';
import {AddJobDialogComponent} from '../../../../dialog/menu/job/add-job-dialog/add-job-dialog.component';
import {ErrorDialogComponent} from '../../../../dialog/organizationchart/error-dialog/error-dialog.component';
import {DarkmodeService} from '../../../../service/darkmode.service';
import {UserService} from '../../../../service/User/user.service';
import {OrganizationService} from '../../../../service/Organization/organization.service';
import {JobService} from "../../../../service/Job/job.service";

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit {

  public isDarkmode;

  public jobData;
  public tmpData;

  displayedColumns: string[] = ['index', 'label', 'action'];

  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar,
              private darkmodeService: DarkmodeService, private userService: UserService,
              private organizationService: OrganizationService, private jobService: JobService) { }

  ngOnInit() {
    this.isDarkmode = this.darkmodeService.getLocalDarkMode();
    this.darkmodeService.getDarkMode().subscribe(data => {
      this.isDarkmode = data;
    });
    this.updateData();
  }

  onDelete(id) {
    const matDia = this.dialog.open(DeleteDialogComponent, {
      width: '500px'
    });

    matDia.afterClosed().subscribe((result) => {
      if (result) {
        this.userService.getAllUser().subscribe(data => {
          this.tmpData = data;
          const findData = this.tmpData.find(f => {
            return f.job == id;
          });
          if (findData) {
            this._snackBar.openFromComponent(ErrorDialogComponent, {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition: 'end',
              data: 'cantDeleteJob'
            });
          } else {
            this.jobService.updateJob(id, {isDeleted: 'true'}).subscribe(data => {
              this.updateData();
            }, error => {
              console.log(error);
            });
          }
        }, error => {
          console.log(error);
        });
      }
    });
  }

  onCreate() {
    const matDia = this.dialog.open(AddJobDialogComponent, {
      width: '500px'
    });

    matDia.afterClosed().subscribe(result => {
      if (result) {
        this.updateData();
      }
    });
  }

  onEdit(user) {
    const matDia = this.dialog.open(JobDialogComponent, {
      data: user,
      width: '500px'
    });

    matDia.afterClosed().subscribe(result => {
      if (result) {
        this.updateData();
      }
    });
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.jobService.getAllJob().subscribe(data => {
        this.jobData = data;
        this.jobData = this.jobData.filter((f) => {
          return !f.isDeleted;
        });
        this.jobData = this.jobData.filter((f) => {
          return f.label.trim().toLowerCase().includes(filterValue.trim().toLowerCase());
        });
      }, error => {
        console.log(error);
      });
    } else {
      this.updateData();
    }
  }

  updateData() {
    this.jobService.getAllJob().subscribe(
      data => {
        this.jobData = data;
        this.jobData = this.jobData.filter((f) => {
          return f.isDeleted != 'true';
        });
        this.jobData = this.jobData.sort(function(a, b) {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });
      }, error => {
        console.log(error);
      }
    );
  }
}
