import { Injectable } from '@angular/core';
import {ProfileDialogComponent} from '../../dialog/menu/timeline/profile-dialog/profile-dialog.component';
import {MatDialog} from '@angular/material';
import {UserService} from "../User/user.service";
import {StaffDialogComponent} from '../../dialog/menu/staff/staff-dialog/staff-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  constructor(private dialog: MatDialog, private userService: UserService) { }

  hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i) {
    // tslint:disable-next-line:no-bitwise
    const c = (i & 0x00FFFFFF).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

  avatarBackground(user) {
    return this.intToRGB(this.hashCode(user.firstName + ' ' + user.lastName));
  }

  avatarText(user) {
    return user.firstName.substring(0, 1).toUpperCase() + user.lastName.substring(0, 1).toUpperCase();
  }

  // open profile dialog
  onOpenProfileDialog(member) {
    const user = {
      firstName: member.firstName,
      lastName: member.lastName,
      jobLabel: member.job,
      profileUrl: member.profileUrl,
      email: member.email,
      _id: member._id,
      didShowMenuButton: true
    };
    this.dialog.open(ProfileDialogComponent, {
      width: '500px',
      data: {
        user,
        isUser: true
      }
    });
  }

  onOpenProfileDialogViaId(id) {
    this.userService.getUserById(id).subscribe((user) => {
      this.dialog.open(ProfileDialogComponent, {
        width: '500px',
        data: {
          user,
          isUser: true
        }
      });
    });
  }
}
