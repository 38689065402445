import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  public imageTransformTooBig = 'c_scale,q_auto,w_480';
  public videoTransformTooBig = 'c_scale,f_mp4,q_auto,vc_auto,w_480';
  public imageTransformNormal = 'c_scale,q_auto';
  public videoTransformNormal = 'c_scale,f_mp4,q_auto,vc_auto';

  public uploadClouldName = 'testuploaddemo';
  public uploadUrl = `https://api.cloudinary.com/v1_1/${this.uploadClouldName}/upload`;
  public uploadPreset = 'ecih4nzm';

  public uploadImagePreset = 'tookitup_image_preset';
  public uploadVideoPortPreset = 'tookitup_video_port_preset';
  public uploadVideoLandPreset = 'tookitup_video_land_preset';
  public uploadFilePreset = 'tookitup_file_preset';
  public uploadProfilePreset = 'tookitup_profile_preset';
  public uploadCoverPreset = 'tookitup_profile_cover_preset';

  public getUrl;
  public autoData;
  public mediaSrc;
  public videoThm;

  // width & height
  public width;
  public height;

  constructor() { }

  getClouldinaryUrl(res) {
    this.getUrl = `https://res.cloudinary.com/${this.uploadClouldName}/${res.resource_type}/upload`;

    // transform data if data is image or video
    // generate thm for video as image
    if (res.width >= 480) {
      this.autoData = res.resource_type == 'image' ? '/' + this.imageTransformTooBig + '/v' : '/' + this.videoTransformTooBig + '/v';
      this.videoThm = this.getUrl + '/' + this.imageTransformTooBig + '/v' + res.version + '/' + res.public_id + '.' + 'jpg';
      this.width = this.onScaleImage(res.width, res.height).width;
      this.height = this.onScaleImage(res.width, res.height).height;
    } else {
      this.autoData = res.resource_type == 'image' ? '/' + this.imageTransformNormal + '/v' : '/' + this.videoTransformNormal + '/v';
      this.videoThm = this.getUrl + '/' + this.imageTransformNormal + '/v' + res.version + '/' + res.public_id + '.' + 'jpg';
      this.width = res.width;
      this.height = res.height;
    }

    // the transformed url
    this.mediaSrc = this.getUrl + this.autoData + res.version + '/' + res.public_id + '.' + res.format;

    // mediaTransformUrl: pic, picThm & video
    // videoThm: vidThm
    return {
      mediaTransformUrl: this.mediaSrc,
      videoThm: this.videoThm,
      width: this.width,
      height: this.height
    };
  }

  onScaleImage(width, height) {
    const MAX_WIDTH = 480;
    const MAX_HEIGHT = 480;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }

    return {width, height};
  }
}
