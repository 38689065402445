import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {DefaultThemeComponent} from '../../../component/Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../service/Theme/theme.service';
import {UserService} from '../../../service/User/user.service';
import {OrganizationService} from '../../../service/Organization/organization.service';
import {JobService} from '../../../service/Job/job.service';

@Component({
  selector: 'app-add-exist-dialog',
  templateUrl: './add-exist-dialog.component.html',
  styleUrls: ['./add-exist-dialog.component.css']
})
export class AddExistDialogComponent implements OnInit {

  public staffData;
  public doneGetData;
  public allSub = [];
  public job;
  public tmpData;

  public theme: DefaultThemeComponent;

  constructor(private dialog: MatDialogRef<AddExistDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private themeService: ThemeService, private userService: UserService,
              private organizationService: OrganizationService, private jobService: JobService) {
    this.doneGetData = false;
  }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.userService.getAllUserByType('staff').subscribe(data => {
      this.staffData = data;
      this.staffData = this.staffData.filter((f) => {
        return f.isDeleted != 'true';
      });
      if (this.staffData) {
        this.staffData.forEach((f) => {
          f.checked = false;
        });
      }
      this.organizationService.getAllSubordination().subscribe(data => {
        this.tmpData = data;
        this.tmpData.forEach((f, i) => {
          if (i != 0) {
            this.allSub.push(f.user);
          }
        });
        this.staffData = this.staffData.filter(f => !this.allSub.includes(f.id));
        this.doneGetData = true;
      }, error => {
        console.log(error);
      });
    }, error => {
      console.log(error);
    });

    this.jobService.getAllJob().subscribe(job => {
      this.job = job;
    });
  }

  onUpdateNumClick() {
    if (this.staffData) {
      for (const u of this.staffData) {
        if (u.checked) {
          return false;
        }
      }
    }
    return true;
  }

  onClose() {
    this.dialog.close(false);
  }

  onSave() {
    const checkStaffData = this.staffData.filter((f) => {
      return f.checked;
    });

    checkStaffData.forEach((f) => {
      this.organizationService.createSubordination({
        user: f.id,
        parent: this.data
      }).subscribe(data => {
      }, error => {
        console.log(error);
      });
    });

    this.dialog.close(true);
  }

  // profile picture
  hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i) {
    const c = (i & 0x00FFFFFF).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

  renderBackgroundColor(user) {
    return this.intToRGB(this.hashCode(user.firstName + ' ' + user.lastName));
  }

  renderInitial(user) {
    return user.firstName.substring(0, 1).toUpperCase() + user.lastName.substring(0, 1).toUpperCase();
  }

  // get
  getJobById(id) {
    if (this.job && this.job.length > 0) {
      return (this.job.find((jobObject) => {
        return jobObject.id === id;
      }).label);
    }
    return 'Employee';
  }
}
