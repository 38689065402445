import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {AddStaffDialogComponent} from '../../../../dialog/menu/staff/add-staff-dialog/add-staff-dialog.component';
import {StaffDialogComponent} from '../../../../dialog/menu/staff/staff-dialog/staff-dialog.component';
import {DeleteDialogComponent} from '../../../../dialog/delete-dialog/delete-dialog.component';
import {EditDialogComponent} from '../../../../dialog/organizationchart/edit-dialog/edit-dialog.component';
import {ErrorDialogComponent} from '../../../../dialog/organizationchart/error-dialog/error-dialog.component';
import {DarkmodeService} from '../../../../service/darkmode.service';
import {Router} from '@angular/router';
import {DefaultThemeComponent} from '../../../Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {UserService} from '../../../../service/User/user.service';
import {GroupService} from '../../../../service/Group/group.service';
import {OrganizationService} from '../../../../service/Organization/organization.service';
import {JobService} from '../../../../service/Job/job.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  public isDarkMode;

  public staffData;
  public dataSource;
  public jobs;
  public tmpData;

  private groupData;
  private subordinationData;

  public isSwitch;
  public staffDataDeleted = [];
  public dataSourceDeleted;

  public theme: DefaultThemeComponent;

  displayedColumns: string[] = ['image', 'firstName', 'lastName', 'email', 'type', 'job', 'action'];

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar,
              private darkmodeService: DarkmodeService, private router: Router, private themeService: ThemeService,
              private userService: UserService, private groupService: GroupService,
              private organizationService: OrganizationService, private jobService: JobService) {
    this.updateData();
  }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.isSwitch = false;

    this.isDarkMode = this.darkmodeService.getLocalDarkMode();
    this.darkmodeService.getDarkMode().subscribe(data => {
      this.isDarkMode = data;
    });

    this.groupService.getAllGroup().subscribe(data => {
      this.groupData = data;
    }, error => {
      console.log(error);
    });

    this.organizationService.getAllSubordination().subscribe(data => {
      this.subordinationData = data;
      this.subordinationData = this.subordinationData.filter(f => {
        if (this.subordinationData.fullUser) {
        return f.fullUser.isDeleted !== 'true';
        }
      });
    }, error => {
      console.log(error);
    });
  }

  // view function
  applyFilter(filterValue: string) {
    if (this.jobs && this.staffData) {
      this.staffData.forEach((f) => {
        f.jobLabel = this.getJobLabelById(f.job);
      });
      this.dataSource = new MatTableDataSource(this.staffData);
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterDeleted(filterValue: string) {
    if (this.jobs && this.staffDataDeleted) {
      this.staffDataDeleted.forEach((f) => {
        f.jobLabel = this.getJobLabelById(f.job);
      });
      this.dataSourceDeleted = new MatTableDataSource(this.staffDataDeleted);
    }
    this.dataSourceDeleted.filter = filterValue.trim().toLowerCase();
  }

  removeGroupParticipants(groupId, userId, participants) {
    const part = [];
    participants.forEach((f) => {
      if (f._id !== userId) {
      part.push(f._id);
      }
    });

    this.groupService.updateGroup(groupId, {participants: part}).subscribe(() => {
    }, error => {
      console.log(error);
    });
  }

  removeConversationParticipants(groupId, userId) {
    this.groupService.getConversationByGroupId(groupId).subscribe(data => {
      this.tmpData = data;
      const part = [];
      this.tmpData.participants.forEach((f) => {
        if (f !== userId) {
          part.push(f);
        }
      });
      this.groupService.updateConversation(this.tmpData._id, {participants: part}).subscribe(() => {
      }, error => {
        console.log(error);
      });
    }, error => {
      console.log(error);
    });
  }

  updateData() {
    this.updateJob();
    this.staffData = [];
    this.getUserByType('staff');
    this.getUserByType('intervenant');
    this.getUserByType('client');
  }

  getUserByType(type) {
    this.userService.getAllUserByType(type).subscribe((userList: any) => {

      // filter for user that active
      const filterStaffData = userList.filter((user) => {
        return user.isDeleted !== 'true' && user.isDeleted !== 'archived' && user.isDeleted !== 'deleted';
      });
      this.staffData = this.staffData.concat(filterStaffData);
      this.dataSource = new MatTableDataSource(this.staffData.reverse());
      this.dataSource.sort = this.sort;

      // filter for all archived user
      const filterDeletedStaffData = userList.filter((user) => {
        return (user.isDeleted === 'true' || user.isDeleted === 'archived');
      });
      this.staffDataDeleted = this.staffDataDeleted.concat(filterDeletedStaffData);
      this.dataSourceDeleted = new MatTableDataSource(this.staffDataDeleted.reverse());
      this.dataSourceDeleted.sort = this.sort;
    }, error => {
      console.log(error);
    });
  }

  // action function
  onAddStaff() {
    const addStaffDialog = this.dialog.open(AddStaffDialogComponent, {
      width: '600px'
    });

    addStaffDialog.afterClosed().subscribe(() => {
      this.updateData();
    });
  }

  onView(staffData) {
    this.dialog.open(StaffDialogComponent, {
      data: staffData,
      width: '500px'
    });
  }

  onEdit(data) {
    data.jobLabel = this.getJobLabelById(data.job);
    data.user = data.id;
    const dia = this.dialog.open(EditDialogComponent, {
      data,
      width: '500px'
    });

    dia.afterClosed().subscribe(result => {
      if (result) {
        this.updateData();
      }
    });
  }

  onRecover(data) {
    this.userService.updateUser(data._id, {isDeleted: 'recovered'}).subscribe(() => {
      this.updateData();
    }, error => {
      console.log(error);
    });
  }

  onArchive(archiveData) {
    const dialogArchive = this.dialog.open(DeleteDialogComponent, {});

    dialogArchive.afterClosed().subscribe((result) => {
      if (result) {
        let findGroupOwner;
        let findSubordination;

        this.organizationService.getAllSubordination().subscribe(data => {
          this.subordinationData = data;
          if (this.groupData && this.subordinationData) {
            findGroupOwner = this.groupData.find(f => {
              return f.owner === archiveData._id;
            });
            findSubordination = this.subordinationData.find(f => {
              return (f.user === archiveData._id);
            });
          }
          if (findGroupOwner || findSubordination) {
            if (findGroupOwner) {
              this.snackBar.openFromComponent(ErrorDialogComponent, {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'end',
                data: 'cantDeleteStaffGroup'
              });
            } else if (findSubordination) {
              this.snackBar.openFromComponent(ErrorDialogComponent, {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'end',
                data: 'cantDeleteStaffOrganization'
              });
            }
          } else {
            this.userService.updateUser(archiveData._id, {isDeleted: 'archived'}).subscribe(() => {
              this.updateData();
              this.groupService.getGroupByParticipants(archiveData._id).subscribe((group: any) => {
                group.list.forEach((f) => {
                  this.removeGroupParticipants(f.groupId, archiveData._id, f.participants);
                  this.removeConversationParticipants(f.groupId, archiveData._id);
                });
              }, error => {
                console.log(error);
              });
            }, error => {
              console.log(error);
            });
          }
        }, error => {
          console.log(error);
        });
      }
    });
  }

  onDelete(deleteData) {
    const dialogDelete = this.dialog.open(DeleteDialogComponent, {});

    dialogDelete.afterClosed().subscribe((result) => {
      if (result) {
        let findGroupOwner;
        let findSubordination;

        this.organizationService.getAllSubordination().subscribe(data => {
          this.subordinationData = data;
          if (this.groupData && this.subordinationData) {
            findGroupOwner = this.groupData.find(f => {
              return f.owner === deleteData._id;
            });
            findSubordination = this.subordinationData.find(f => {
              return (f.user === deleteData._id);
            });
          }
          if (findGroupOwner || findSubordination) {
            if (findGroupOwner) {
              this.snackBar.openFromComponent(ErrorDialogComponent, {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'end',
                data: 'cantDeleteStaffGroup'
              });
            } else if (findSubordination) {
              this.snackBar.openFromComponent(ErrorDialogComponent, {
                duration: 3000,
                verticalPosition: 'top',
                horizontalPosition: 'end',
                data: 'cantDeleteStaffOrganization'
              });
            }
          } else {
            this.userService.updateUser(deleteData._id, {isDeleted: 'deleted'}).subscribe(() => {
              this.updateData();
              this.groupService.getGroupByParticipants(deleteData._id).subscribe((group: any) => {
                group.list.forEach((f) => {
                  this.removeGroupParticipants(f.groupId, deleteData._id, f.participants);
                  this.removeConversationParticipants(f.groupId, deleteData._id);
                });
              }, error => {
                console.log(error);
              });
            }, error => {
              console.log(error);
            });
          }
        }, error => {
          console.log(error);
        });
      }
    });
  }

  // helper function
  updateJob() {
    this.jobService.getAllJob().subscribe(data => {
      this.jobs = data;
    }, error => {
      console.log(error);
    });
  }

  getJobLabelById(id) {
    if (this.jobs) {
      const job = this.jobs.find((f) => {
        return f.id === id;
      });
      if (job) {
        return job.label;
      } else {
        return '';
      }
    }
    return '';
  }

  getJobById(id) {
    if (this.jobs) {
      return this.jobs.find((f) => {
        return f.id === id;
      });
    }
  }
}
