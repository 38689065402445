import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatAutocomplete, MatAutocompleteSelectedEvent,
  MatDialogRef, MatSelect
} from '@angular/material';
import {AbstractControl, FormControl, ValidatorFn, Validators} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {UploadService} from '../../../../service/common/upload.service';
import {UserService} from '../../../../service/User/user.service';
import {SettingService} from '../../../../service/Setting/setting.service';
import {GroupService} from '../../../../service/Group/group.service';
import {DefaultThemeComponent} from '../../../../component/Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../service/Theme/theme.service';

@Component({
  selector: 'app-group-dialog',
  templateUrl: './group-dialog.component.html',
  styleUrls: ['./../add-group-dialog/add-group-dialog.component.css']
})
export class GroupDialogComponent implements OnInit, AfterViewInit {

  // Edit data
  public pageLoaded;
  public userData;
  public selectedOwner: string;
  private sentEditData;

  // upload
  public uploader: FileUploader;
  private uploadPreset;
  private uploadUrl;
  private uploadClouldName;

  // upload process
  public picture;
  public doneProcess;

  // cover picture
  public profileCover;

  // select data
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredMembers: Observable<string[]>;
  filteredAdmins: Observable<string[]>;
  members: string[] = [];
  admins: string[] = [];
  allMembers: string[] = [];
  allAdmins: string[] = [];

  // DOM
  @ViewChild('memberInput') memberInput: ElementRef<HTMLInputElement>;
  @ViewChild('adminInput') adminInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('autoAdmin') matAutocompleteAdmin: MatAutocomplete;
  @ViewChild('memberDOM') memberDOM: ElementRef;
  @ViewChild('adminDOM') adminDOM: ElementRef;

  // form
  groupName = new FormControl('', [Validators.required]);
  memberCtrl = new FormControl('', [this.chipNumber(this.members.length)]);
  adminCtrl = new FormControl('', [this.chipNumber(this.admins.length)]);

  // Theme
  public theme: DefaultThemeComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialog: MatDialogRef<GroupDialogComponent>,
              private uploadService: UploadService, private userService: UserService, private themeService: ThemeService,
              private settingService: SettingService, private groupService: GroupService) {
    this.pageLoaded = false;

    // get all user expect the deleted user
    this.userService.getAllUser().subscribe((user: any) => {
      this.userData = user;
      this.userData.forEach((f) => {
        if (f.isDeleted !== 'true') {
          this.allMembers.push(f.id);
          this.allAdmins.push(f.id);
        }
      });
    }, error => {
      console.log(error);
    });

    // set group name
    this.groupName.setValue(this.data.name);

    // set group owner
    this.userService.getUserById(this.data.owner).subscribe((user: any) => {
      this.admins.push(user.id);
      this.allAdmins = this.allAdmins.filter(val => !this.admins.includes(val));
    }, error => {
      console.log(error);
    });

    // set group participants
    this.data.participants.forEach((f) => {
      this.userService.getUserById(f).subscribe((participant: any) => {
        this.members.push(participant.id);
        this.allMembers = this.allMembers.filter(val => !this.members.includes(val));
      }, error => {
        console.log(error);
      });
    });

    this.onUpdateData();
  }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.doneProcess = true;
    this.uploadClouldName = this.uploadService.uploadClouldName;
    this.uploadUrl = this.uploadService.uploadUrl;
    this.uploadPreset = this.uploadService.uploadProfilePreset;

    this.getCoverPicture();
    this.initUpload();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 1000);
  }

  initUpload() {
    const uploaderOptions: FileUploaderOptions = {
      url: this.uploadUrl,
      autoUpload: false,
      isHTML5: true,
      removeAfterUpload: true,
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onBuildItemForm = (fileItem, form) => {
      const fileType = fileItem.file.type.split('/')[0];
      form.append('upload_preset', this.uploadPreset);
      form.append('file', fileItem);
      form.append('tag', fileType);
      form.append('public_id', 'group_' + new Date().getTime());

      fileItem.withCredentials = false;
      this.doneProcess = false;
      return {fileItem, form};
    };

    this.uploader.onCompleteItem = (item, response) => {

      const res = JSON.parse(response);
      const getData = this.uploadService.getClouldinaryUrl(res);

      this.sentEditData.picture = getData.mediaTransformUrl;
      this.sentEditGroup();
      this.doneProcess = true;
    };
  }

  onFileSelected(event) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.picture = e.target.result;
    };
    reader.readAsDataURL(event[0]);
  }

  onClose() {
    this.dialog.close(false);
  }

  /// add, delete and filter chip
  remove(member: string): void {
    const index = this.members.indexOf(member);

    if (index >= 0) {
      this.allMembers.push(member);
      this.members.splice(index, 1);
      this.allMembers = this.allMembers.filter(val => !this.members.includes(val));
      this.memberCtrl = new FormControl('', [this.chipNumber(this.members.length)]);
    }
  }

  removeAdmin(admin: string): void {
    const index = this.admins.indexOf(admin);

    if (index >= 0) {
      this.allAdmins.push(admin);
      this.admins.splice(index, 1);
      this.allAdmins = this.allAdmins.filter(val => !this.admins.includes(val));
      this.memberCtrl = new FormControl('', [this.chipNumber(this.admins.length)]);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.members.push(event.option.value);
    this.allMembers = this.allMembers.filter(val => !this.members.includes(val));
    this.memberInput.nativeElement.value = '';
    this.memberCtrl.setValue(null);
    this.memberCtrl = new FormControl('', [this.chipNumber(this.members.length)]);
    this.onUpdateData();
  }

  selectedAdmin(event: MatAutocompleteSelectedEvent): void {
    this.admins.push(event.option.value);
    this.allAdmins = this.allAdmins.filter(val => !this.admins.includes(val));
    this.adminInput.nativeElement.value = '';
    this.adminCtrl.setValue(null);
    this.adminCtrl = new FormControl('', [this.chipNumber(this.admins.length)]);
    this.onUpdateData();
  }

  private _filter(value): string[] {
    const filterValue = value.toLowerCase();
    return this.allMembers.filter(member => this.getFullNameById(member).toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterAdmin(value): string[] {
    const filterValue = value.toLowerCase();
    return this.allAdmins.filter(admin => this.getFullNameById(admin).toLowerCase().indexOf(filterValue) === 0);
  }

  /// update when filter
  onUpdateData() {
    this.filteredMembers = this.memberCtrl.valueChanges.pipe(
      startWith(null),
      map((member: string | null) => member ? this._filter(member) : this.allMembers.slice())
    );

    this.filteredAdmins = this.adminCtrl.valueChanges.pipe(
      startWith(null),
      map((admin: string | null) => admin ? this._filterAdmin(admin) : this.allAdmins.slice())
    );
  }

  /// create new group
  onSave() {
    this.detectChange();
    if (this.picture) {
      this.uploader.uploadAll();
    } else {
      this.sentEditGroup();
    }
  }

  detectChange() {
    this.sentEditData = {
      owner: '',
      participants: '',
      name: '',
      picture: ''
    };
    if (this.data.owner !== this.admins[0]) {
      this.sentEditData.owner = this.admins[0];
    }
    const memberNotChange = this.members.length === this.data.participants.length &&
      this.members.sort().every((value, index) => {
        return value === this.data.participants.sort()[index];
      });
    if (!memberNotChange) {
      this.sentEditData.participants = this.members;
    }
    if (this.groupName.value !== this.data.name) {
      this.sentEditData.name = this.groupName.value;
    }
    this.sentEditData = this.cleanNullData(this.sentEditData);
  }

  sentEditGroup() {
    this.groupService.updateGroup(this.data.id, this.sentEditData).subscribe(() => {
      this.dialog.close(true);
    }, error => {
      console.log(error);
    });

    if (this.sentEditData.name || this.sentEditData.participants) {
      let sentData = {
        name: this.sentEditData.name,
        participants: this.sentEditData.participants
      };
      sentData = this.cleanNullData(sentData);
      this.groupService.getConversationByGroupId(this.data.id).subscribe((conversation: any) => {
        this.groupService.updateConversation(conversation._id, sentData).subscribe(() => {
        }, error => {
          console.log(error);
        });
      }, error => {
        console.log(error);
      });
    }
  }

  chipNumber(num: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && (num <= 0)) {
        return { length: true };
      }
      return null;
    };
  }

  getFullNameById(id) {
    if (this.userData) {
      const user = this.userData.find((f) => {
        return f.id === id;
      });
      if (user) {
        return user.firstName + ' ' + user.lastName;
      }
    }
  }

  // cover picture
  getCoverPicture() {
    this.settingService.getSetting().subscribe(setting => {
      if (setting[0].contactBackground) {
        this.profileCover = setting[0].contactBackground;
      } else {
        this.profileCover = './assets/image/logo/default_cover_store.jpg';
      }
    }, error => {
      console.log(error);
    });
  }

  getUserById(id) {
    if (this.userData) {
      return this.userData.find((user) => {
        return user._id === id;
      });
    }
  }

  // bool
  allowEdit() {
    const memberNotChange = this.members.length === this.data.participants.length &&
    this.members.sort().every((value, index) => {
      return value === this.data.participants.sort()[index];
    });
    return (this.groupName.value !== this.data.name) ||
      (this.admins[0] && (this.admins[0] !== this.data.owner)) ||
      (this.members.length > 0 && (!memberNotChange)) || this.picture;
  }

  cleanNullData(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || !obj[propName]) {
        delete obj[propName];
      }
    }
    return obj;
  }
}
