import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmitService {

  // image preview (timeline)
  private fullHeight: EventEmitter<any> = new EventEmitter();
  private inputID: EventEmitter<any> = new EventEmitter();

  constructor() {

  }

  // right preview media dialog - get image height to make scroll at the right side for comment
  public setFullHeight(num) {
    this.fullHeight.emit(num);
  }

  public getFullHeight() {
    return this.fullHeight;
  }


}
