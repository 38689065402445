import {Injectable} from '@angular/core';
import {Link, NgxLinkifyjsService} from "ngx-linkifyjs";
import {BaseService} from "../common/base.service";

@Injectable({
  providedIn: 'root'
})
export class LinkPreviewService {

  private saveLink;
  private linkArray;
  private saveMetaData;

  constructor(private linkifyService: NgxLinkifyjsService, private baseService: BaseService) {}

  checkIfUrl(str) {
    const foundLinks: Link[] = this.linkifyService.find(str);
    return foundLinks.length > 0;
  }

  async requestLinkPreview(str, self) {
    this.linkArray = this.linkifyService.find(str);
    if(this.linkArray.length > 0) {
      for(let i=0; i<this.linkArray.length; i++) {
        const url = this.linkArray[i].href;
        const type = this.linkArray[i].type;
        if(type === 'url') {
          if (this.saveLink !== url) {
            try {
              self.linkLoading = true;
              const metaData = await this.baseService.post('api/linkpreview', {url: url}).toPromise();
              if (this.checkMetaDataEmpty(metaData)) {
                if (this.checkValidFileUrl(metaData).type !== 'unknown') {
                  this.saveMetaData = metaData;
                  this.saveLink = url;
                  return this.saveMetaData;
                }
              } else {
                metaData['type'] = 'link';
                this.saveMetaData = metaData;
                this.saveLink = url;
                return this.saveMetaData;
              }
            } catch (e) {
              console.log(e);
              return null;
            }
          } else {
            return this.saveMetaData;
          }
        }
      }
    }
    return null;
  }

  async requestMultipleLinkPreview(str) {
    const metaPreview = [];

    this.linkArray = this.linkifyService.find(str);
    if(this.linkArray.length > 0) {
      for (let i = 0; i < this.linkArray.length; i++) {
        const url = this.linkArray[i].href;
        try {
          let metaData: any = await this.baseService.post('api/linkpreview', {url: url}).toPromise();
          metaData = this.cleanNullData(metaData);
          if (this.checkMetaDataEmpty(metaData)) {
            const urlType = this.checkValidFileUrl(metaData).type;
            if (urlType !== 'unknown') {
              if(urlType === 'image') {
                const imageData: any = await this.loadImageData(metaData.url);
                metaData.width = imageData.width;
                metaData.height = imageData.height;
                delete metaData.fileName;
                delete metaData.fileExtension;
              } else if(urlType === 'video') {
                const videoData: any = await this.loadVideoData(metaData.url);
                metaData.width = videoData.width;
                metaData.height = videoData.height;
                // metaData.duration = videoData.duration;
                delete metaData.fileName;
                delete metaData.fileExtension;
              }
              metaData.shouldPreview = true;
              metaData.fileType = urlType;
              metaData.type = 'file';
              metaPreview.push(metaData);
            }
          } else {
            metaData.shouldPreview = true;
            metaData.type = 'link';
            metaPreview.push(metaData);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
    return metaPreview;
  }

  async requestMultipleLinkPreviewBeforePost(str) {
    const metaPreview = [];

    this.linkArray = this.linkifyService.find(str);
    if(this.linkArray.length > 0) {
      for (let i = 0; i < this.linkArray.length; i++) {
        const url = this.linkArray[i].href;
        try {
          let metaData: any = await this.baseService.post('api/linkpreview', {url: url}).toPromise();
          metaData = this.cleanNullData(metaData);
          if (this.checkMetaDataEmpty(metaData) && this.checkValidFileUrl(metaData).type !== 'unknown') {
            delete metaData.fileName;
            delete metaData.fileExtension;
            metaData.type = 'file';
            metaPreview.push(metaData);
          } else {
            delete metaData.logo;
            metaData.type = 'link';
            metaPreview.push(metaData);
          }
          metaData.shouldPreview = true;
        } catch (e) {
          console.log(e);
        }
      }
    }
    return metaPreview;
  }

  loadImageData(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({width: img.width, height: img.height});
      img.onerror = reject;
      img.src = url;
    });
  }

  loadVideoData(url) {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => resolve({ duration: video.duration, width: video.videoWidth, height: video.videoHeight });
      video.onerror = reject;
      video.src = url;
    });
  }

  // check if it actually a file
  checkValidFileUrl(metaData) {
    const splitedUrl = metaData.url.split('/');
    const fileName = splitedUrl[splitedUrl.length - 1]; // last piece is the file name
    const fileExtension = fileName.split('.')[1]; // after dot is extension
    metaData.fileName = fileName;
    metaData.fileExtension = fileExtension;
    metaData.type = this.checkUrlFileType(metaData.url);
    if(!fileExtension || fileExtension.length > 4) {
      return {type: 'unknown'};
    }
    return metaData;
  }

  // check if the link is file/image/video
  checkUrlFileType(url) {
    if(url.toString().endsWith('.png') || url.toString().endsWith('.jpg') || url.toString().endsWith('.gif') || url.toString().endsWith('.jpeg')) {
      return 'image';
    }else if(url.toString().endsWith('.mp4') || url.toString().endsWith('.mav')) {
      return 'video';
    } else {
      return 'file';
    }
  }

  checkMetaDataEmpty(metaData) {
    return (!metaData.title && !metaData.description && !metaData.image);
  }

  cleanNullData(obj) {
    for (let propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }
}
