import {Component, OnInit} from '@angular/core';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {DarkmodeService} from '../../service/darkmode.service';
import {LanguageService} from '../../service/Setting/language.service';
import {UserService} from '../../service/User/user.service';
import {UploadService} from '../../service/common/upload.service';
import {ThemeService} from '../../service/Theme/theme.service';
import {DefaultThemeComponent} from '../Common/Theme/default-theme/default-theme.component';
// @ts-ignore
import { version } from '../../../../package.json';
import {SettingService} from '../../service/Setting/setting.service';
import {OrganizationService} from '../../service/Organization/organization.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  // admin name
  public firstName = '';
  public lastName = '';
  public oldAdminName;

  // tab
  public tab = 'general';

  // setting data
  public oldSettingData;
  public organisationName = '';
  public sentSettingData;
  public sentUserData;

  // Organisation data
  public rootId;
  public oldOrganisationData;
  public sentOrganisationData;

  // theme
  public currentTheme;

  // language
  public currentLanguage;

  // company logo
  public companyLogoLight;
  public companyLogoDark;
  public companyAvatarLight;
  public companyAvatarDark;

  // contact background
  public contactBackground;

  // upload image
  public uploader: FileUploader;
  private uploadPreset;
  private uploadUrl;
  private uploadClouldName;
  public uploadType;

  // upload process
  public avatarDarkFile;
  public avatarLightFile;
  public logoDarkFile;
  public logoLightFile;
  public coverFile;
  public doneProgress;
  public progress;

  // Theme
  public theme: DefaultThemeComponent;
  public version: string = version;

  constructor(private darkmodeService: DarkmodeService,
              private languageService: LanguageService, public userService: UserService,
              private settingService: SettingService, private uploadService: UploadService,
              private themeService: ThemeService, private organizationService: OrganizationService) {
    this.oldAdminName = { firstName: '', lastName: '' };
    this.sentUserData = { firstName: '', lastName: '' };
    this.oldSettingData = {
      organisationName: '',
      theme: '',
      language: '',
      companyLogoLight: '',
      companyLogoDark: '',
      contactBackground: ''
    };
    this.sentSettingData = {
      organisationName: '',
      theme: '',
      language: '',
      companyLogoLight: '',
      companyLogoDark: '',
      contactBackground: ''
    };

    this.oldOrganisationData = { iconLightmode: '', iconDarkmode: '' };
    this.sentOrganisationData = { iconLightmode: '', iconDarkmode: '' };
  }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.getAdminName();
    this.getSettingData();
    this.getOrganisationData();

    // upload image
    this.uploadClouldName = this.uploadService.uploadClouldName;
    this.uploadUrl = this.uploadService.uploadUrl;
    this.uploadPreset = this.uploadService.uploadCoverPreset;

    this.initUpload();
  }

  // admin name
  getAdminName() {
    this.userService.getUserById(this.userService.getUserId()).subscribe((userData: any) => {
      this.firstName = userData.firstName;
      this.lastName = userData.lastName;
      this.oldAdminName.firstName = userData.firstName;
      this.oldAdminName.lastName = userData.lastName;
    });
  }

  getSettingData() {
    this.settingService.getSetting().subscribe((settingDataList: any) => {
      const settingData = settingDataList[0];
      // organisation
      this.organisationName = settingData.organisationName;
      this.oldSettingData.organisationName = settingData.organisationName;
      // theme
      this.currentTheme = settingData.theme;
      this.themeService.setTheme(settingData.theme);
      this.oldSettingData.theme = settingData.theme;
      // Language
      this.currentLanguage = settingData.language;
      this.languageService.setLanguage(settingData.language);
      this.oldSettingData.language = settingData.language;
      // company logo
      this.companyLogoDark = settingData.pictureDarkmode;
      this.companyLogoLight = settingData.pictureLightmode;
      this.oldSettingData.companyLogoLight = settingData.pictureLightmode;
      this.oldSettingData.companyLogoDark = settingData.pictureDarkmode;
      // contact background
      this.contactBackground = settingData.contactBackground;
      this.oldSettingData.contactBackground = settingData.contactBackground;
    });
  }

  getOrganisationData() {
    this.organizationService.getAllSubordination().subscribe((organisation) => {
      const root = organisation[0];
      this.rootId = root._id;
      this.companyAvatarLight = root.iconLightmode;
      this.companyAvatarDark = root.iconDarkmode;
      this.oldOrganisationData.iconLightmode = root.iconLightmode;
      this.oldOrganisationData.iconDarkmode = root.iconDarkmode;
    });
  }

  // image
  initUpload() {
    const uploaderOptions: FileUploaderOptions = {
      url: this.uploadUrl,
      autoUpload: false,
      isHTML5: true,
      removeAfterUpload: true,
      headers: [
        {name: 'X-Requested-With', value: 'XMLHttpRequest'}
      ]
    };

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onAfterAddingFile = (fileItem) => {
      if (this.uploadType === 'avatarLight') {
        this.avatarLightFile = fileItem.file;
      } else if (this.uploadType === 'avatarDark') {
        this.avatarDarkFile = fileItem.file;
      } else if (this.uploadType === 'logoDark') {
        this.logoDarkFile = fileItem.file;
      } else if (this.uploadType === 'logoLight') {
        this.logoLightFile = fileItem.file;
      } else if (this.uploadType === 'cover') {
        this.coverFile = fileItem.file;
      }
    };

    this.uploader.onBuildItemForm = (fileItem, form) => {
      const fileType = fileItem.file.type.split('/')[0];
      form.append('upload_preset', this.uploadPreset);
      form.append('file', fileItem);
      form.append('tags', fileType);

      if (this.avatarDarkFile === fileItem.file || this.avatarLightFile === fileItem.file) {
        form.append('public_id', 'logo_small_' + new Date().getTime());
      } else if (this.logoLightFile === fileItem.file || this.logoDarkFile === fileItem.file) {
        form.append('public_id', 'logo_' + new Date().getTime());
      } else if (this.coverFile === fileItem.file) {
        form.append('public_id', 'cover_' + new Date().getTime());
      }

      fileItem.withCredentials = false;
      this.doneProgress = false;
      this.progress = 0;
      return {fileItem, form};
    };

    this.uploader.onCompleteItem = (item, response, status, headers) => {
      const res = JSON.parse(response);

      const getData = this.uploadService.getClouldinaryUrl(res);

      if (res.resource_type === 'image') {
        if (this.avatarDarkFile === item.file) {
          this.sentOrganisationData.iconDarkmode = getData.mediaTransformUrl;
        } else if (this.avatarLightFile === item.file) {
          this.sentOrganisationData.iconLightmode = getData.mediaTransformUrl;
        } else if (this.logoLightFile === item.file) {
          this.sentSettingData.pictureLightmode = getData.mediaTransformUrl;
        } else if (this.logoDarkFile === item.file) {
          this.sentSettingData.pictureDarkmode = getData.mediaTransformUrl;
        } else if (this.coverFile === item.file) {
          this.sentSettingData.contactBackground = getData.mediaTransformUrl;
        }
      }

      this.doneProgress = true;
    };

    this.uploader.onCompleteAll = () => {
      this.sentUpdateSettingData();
    };

    this.uploader.onProgressAll = (progress) => {
      this.progress = progress;
    };
  }

  onFileSelected(type, event) {
    this.uploadType = type;
    if (type === 'avatarLight') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.companyAvatarLight = e.target.result;
      };
      reader.readAsDataURL(event[0]);
    } else if (type === 'avatarDark') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.companyAvatarDark = e.target.result;
      };
      reader.readAsDataURL(event[0]);
    } else if (type === 'logoLight') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.companyLogoLight = e.target.result;
      };
      reader.readAsDataURL(event[0]);
    } else if (type === 'logoDark') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.companyLogoDark = e.target.result;
      };
      reader.readAsDataURL(event[0]);
    } else if (type === 'cover') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.contactBackground = e.target.result;
      };
      reader.readAsDataURL(event[0]);
    }
  }

  onSave() {
    this.detectChange();
    if (this.uploader.queue.length > 0) {
      this.uploader.uploadAll();
      return;
    }
    this.sentUpdateSettingData();
  }

  onCancel() {
    this.uploader.clearQueue();
    this.getAdminName();
    this.getSettingData();
    this.getOrganisationData();
  }

  sentUpdateSettingData() {
    if (this.sentSettingData) {
      this.settingService.updateSetting(this.sentSettingData).subscribe(() => {
        this.getSettingData();
      });
    }
    if (this.sentUserData) {
      this.userService.updateUser(this.userService.getUserId(), this.sentUserData).subscribe(() => {
        this.getAdminName();
      });
    }
    if (this.sentOrganisationData && this.rootId) {
      this.sentOrganisationData = this.cleanNullData(this.sentOrganisationData);
      this.organizationService.updateSubordination(this.rootId, this.sentOrganisationData).subscribe(() => {
        this.getOrganisationData();
      });
    }
  }

  // bool
  checkAllowChange() {
    return (this.oldAdminName.firstName !== this.firstName) ||
      (this.oldAdminName.lastName !== this.lastName) ||
      (this.oldSettingData.organisationName !== this.organisationName) ||
      (this.oldSettingData.theme !== this.currentTheme) ||
      (this.oldSettingData.language !== this.currentLanguage) ||
      (this.oldSettingData.companyLogoDark !== this.companyLogoDark) ||
      (this.oldSettingData.companyLogoLight !== this.companyLogoLight) ||
      (this.oldOrganisationData.iconDarkmode !== this.companyAvatarDark) ||
      (this.oldOrganisationData.iconLightmode !== this.companyAvatarLight) ||
      (this.oldSettingData.contactBackground !== this.contactBackground);
  }

  detectChange() {
    this.sentSettingData = {
      organisationName: ''
    };

    this.sentUserData = {
      firstName: '',
      lastName: ''
    };

    if (this.oldAdminName.firstName !== this.firstName) {
      this.sentUserData.firstName = this.firstName;
    }
    if (this.oldAdminName.lastName !== this.lastName) {
      this.sentUserData.lastName = this.lastName;
    }

    if (this.oldSettingData.organisationName !== this.organisationName) {
      this.sentSettingData.organisationName = this.organisationName;
    }
    if (this.oldSettingData.theme !== this.currentTheme) {
      this.sentSettingData.theme = this.currentTheme;
    }
    if (this.oldSettingData.language !== this.currentLanguage) {
      this.sentSettingData.language = this.currentLanguage;
    }

    this.sentSettingData = this.cleanNullData(this.sentSettingData);
    this.sentUserData = this.cleanNullData(this.sentUserData);
  }

  cleanNullData(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || !obj[propName]) {
        delete obj[propName];
      }
    }
    return obj;
  }
}


