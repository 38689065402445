import * as moment from 'moment';


export default class DatePostUtil {
  static changeLanguage(language) {
    moment.locale(language);
  }

  static convertDateToPostDate(date) {
    const now = moment(new Date());
    const postDate = moment(date);
    const diff = now.diff(postDate);
    const diffDuration = moment.duration(diff);

    const day = Math.round(diffDuration.asDays());
    const hour = Math.round(diffDuration.asHours());
    const min = Math.round(diffDuration.asMinutes());
    const month = diffDuration.get('M');

    if (day === 0 && min === 0) {
      // now
      if (moment.locale() === 'fr') {
        return 'maintenant';
      }
      return 'now';
    } else if (day === 0) {
      if (hour < 1) {
        // 10 min ago
        if (moment.locale() === 'fr') {
          return min + ' il y a min';
        }
        return min + ' min ago';
      } else {
        // 10:20 AM
        return postDate.format('h:mm A');
      }
    } else {
      if (day <= 7) {
        // Monday
        return postDate.format('dddd');
      } else if (month > 6) {
        // 02/12/2020
        return postDate.format('DD/MM/YYYY');
      } else {
        // Apr 8
        return postDate.format('MMM D');
      }
    }
    return postDate.format('DD/MM/YYYY');
  }
}
