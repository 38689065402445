import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {JobService} from '../../../../service/Job/job.service';

@Component({
  selector: 'app-job-dialog',
  templateUrl: './job-dialog.component.html',
  styleUrls: ['./job-dialog.component.css']
})
export class JobDialogComponent implements OnInit {

  public label;
  constructor(private dialog: MatDialogRef<JobDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
              private jobService: JobService) {
    this.label = this.data.label;
  }

  ngOnInit() {}


  onClose() {
    this.dialog.close(false);
  }

  onSave() {
    if (this.label) {
      const tmp = {
        label: this.label
      };
      if (this.label != this.data.label) {
        this.jobService.updateJob(this.data.id, tmp).subscribe(data => {
          this.dialog.close(true);
        }, error => {
          console.log(error);
        });
      } else {
        this.dialog.close(false);
      }
    }
  }

}
