import {EventEmitter, Injectable, Output} from '@angular/core';
import {UserService} from './User/user.service';
import {SettingService} from './Setting/setting.service';

@Injectable({
  providedIn: 'root'
})
export class DarkmodeService {

  private tmpData;

  @Output() fire: EventEmitter<any> = new EventEmitter();
  @Output() openEmoji: EventEmitter<any> = new EventEmitter();
  @Output() logo: EventEmitter<any> = new EventEmitter();
  @Output() avatar: EventEmitter<any> = new EventEmitter();


  constructor(private userService: UserService, private settingService: SettingService) {
    if (this.userService.getToken()) {
      this.settingService.getSetting().subscribe(data => {
        localStorage.setItem('theme', data[0].theme);
        this.fire.emit(data[0].theme);
      });
      this.downloadLogo();
      this.downloadAdminName();
    }
  }

  setDarkMode(theme) {
    const sendData = {
      theme
    };
    this.settingService.getSetting().subscribe(data => {
      this.settingService.updateSetting(sendData).subscribe(data => {
        this.setLocalDarkMode(theme);
      }, error => {
        console.log(error);
      });
    });
  }

  // when change it update in an instant
  getDarkMode() {
    return this.fire;
  }

  setLocalDarkMode(theme) {
    localStorage.setItem('theme', theme);
    this.fire.emit(theme);
  }

  getLocalDarkMode() {
    return localStorage.getItem('theme');
  }

  // openEmoji
  setEmojiOpen(data) {
    this.openEmoji.emit(data);
  }

  isEmojiOpen() {
    return this.openEmoji;
  }

  // logo
  uploadLogo(sentData) {
    this.settingService.getSetting().subscribe(data => {
      this.settingService.updateSetting(sentData).subscribe(data => {
        this.setLogo(sentData);
      }, error => {
        console.log(error);
      });
    }, error => {
      console.log(error);
    });
  }

  downloadLogo() {
    const sentData = {
      pictureDarkmode: null,
      pictureLightmode: null
    };

    this.settingService.getSetting().subscribe(data => {
      if (data[0].pictureLightmode && data[0].pictureDarkmode) {
        sentData.pictureLightmode = data[0].pictureLightmode;
        sentData.pictureDarkmode = data[0].pictureDarkmode;
      } else {
        if (!data[0].pictureDarkmode) {
          sentData.pictureDarkmode = './assets/image/logo/logo_white.png';
        }
        if (!data[0].pictureLightmode) {
          sentData.pictureLightmode = './assets/image/logo/logo_black.png';
        }
      }
      this.setLogo(sentData);
    }, error => {
      console.log(error);
    });
  }

  setLogo(data) {
    this.logo.emit(data);
  }

  getLogo() {
    return this.logo;
  }

  // avatar
  downloadAdminName() {
    if (this.userService.getToken()) {
      this.userService.getUserById(this.userService.getToken().user._id).subscribe(data => {
        this.tmpData = data;
        const token = this.userService.getToken();
        token.user = this.tmpData;
        this.userService.saveToken(token);
        this.setAvatar(this.tmpData);
      }, error => {
        console.log(error);
      });
    }
  }

  uploadAdminName(getData) {
    this.userService.getUserById(getData.user._id).subscribe(data => {
      this.tmpData = data;
      const sendData = {
        firstName: getData.user.firstName,
        lastName: getData.user.lastName
      };
      this.userService.updateUser(this.tmpData.id, sendData).subscribe(data => {
        this.setAvatar(getData.user);
      }, error => {
        console.log(error);
      });
    }, error => {
      console.log(error);
    });
  }

  setAvatar(data) {
    this.avatar.emit(data);
  }

  getAvatar() {
    return this.avatar;
  }
}
