import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {GifDialogComponent} from '../../../../../dialog/menu/timeline/gif-dialog/gif-dialog.component';
import {UserService} from '../../../../../service/User/user.service';
import {MatDialog} from '@angular/material';
import * as $ from 'jquery';
import {DarkmodeService} from '../../../../../service/darkmode.service';
import {DeleteDialogComponent} from '../../../../../dialog/delete-dialog/delete-dialog.component';
import {EmitService} from '../../../../../service/emit.service';
import {TimelineService} from '../../../../../service/Timeline/timeline.service';
import {DefaultThemeComponent} from '../../../../Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../../service/Theme/theme.service';
import {MentionService} from '../../../../../service/common/mention.service';

@Component({
  selector: 'app-subcomment',
  templateUrl: './subcomment.component.html',
  styleUrls: ['./subcomment.component.css']
})
export class SubcommentComponent implements OnInit {

  @Input() commentData;
  @Input() comment;
  @Input() isDarkMode;
  @Input() admin;
  @Input() data;
  @Input() isPreview;
  @ViewChild('textarea') textArea;

  public tmpData;
  public openSubEmoji;
  public openEditSubEmoji;

  public subCommentData;
  public subCommentLength;

  // mention
  public showMentionMenu;

  // edit comment
  public tmpEditText;

  // theme
  public theme: DefaultThemeComponent;

  constructor(private userService: UserService, private dialog: MatDialog, public darkmodeService: DarkmodeService,
              public emitService: EmitService, private timelineService: TimelineService, private themeService: ThemeService,
              public mentionService: MentionService) { }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    if (this.comment) {
      this.subCommentData = this.comment.subComments;
      this.subCommentLength = this.comment.totalSubComments;
    }

    // mention
    this.mentionService.singleMention.subscribe((showMentionMenu) => {
      this.showMentionMenu = showMentionMenu;
    });

    this.darkmodeService.isEmojiOpen().subscribe(data => {
      this.openSubEmoji = data;
    });

    const self = this;
    $(document).on('click', function(e) {
      if (e.target.nodeName == 'DIV' || e.target.nodeName.includes('MAT-CARD') || e.target.nodeName.includes('MAT-CHIP')) {
        if (e.target.className != 'emoji-mart') {
          if (e.target.childNodes[0] && e.target.childNodes[0].nodeName == 'svg') {
          } else {
            self.openSubEmoji = false;
            self.openEditSubEmoji = false;
          }
        }
      }
    });

    // SET CURSOR POSITION
    $.fn.setCursorPosition = function(pos) {
      this.each(function(index, elem) {
        if (elem.setSelectionRange) {
          elem.setSelectionRange(pos, pos);
        } else if (elem.createTextRange) {
          const range = elem.createTextRange();
          range.collapse(true);
          range.moveEnd('character', pos);
          range.moveStart('character', pos);
          range.select();
        }
      });
      return this;
    };
  }

  updateData() {
    if (this.comment) {
      this.timelineService.getSubCommentByCommentId(this.comment._id).subscribe(data => {
        this.subCommentData = data;
        this.subCommentLength = this.subCommentData.length;
        this.limitSubComment();
      }, error => {
        console.log(error);
      });
    }
  }

  convertText(subComment) {
    subComment = this.mentionService.convertMentionStringToViewString(subComment);
    return subComment;
  }

  /*comment media*/
  selectedSubEmoji(event, comment) {
    if (comment.subComment) {
      comment.subComment += event.emoji.native;
    } else {
      comment.subComment = '';
      comment.subComment += event.emoji.native;
    }
  }

  selectedEditSubEmoji(event, subComment) {
    if (subComment.message) {
      subComment.message += event.emoji.native;
    } else {
      subComment.message = '';
      subComment.message += event.emoji.native;
    }
  }

  onGifSubDialog(post, comment) {
    const sentData = {
      post,
      comment,
      type: 'sub-comment'
    };

    const dia = this.dialog.open(GifDialogComponent, {
      data: sentData,
      maxWidth: '360px',
    });

    dia.afterClosed().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });
  }

  /*comment press release*/
  onTypeComment(event) {
    event.target.style.height = '';
    event.target.style.height = Math.min(event.target.scrollHeight + 15) + 'px';
  }

  onReleaseSubComment(event) {
    if (this.showMentionMenu) {
      this.mentionService.onKeyUp(event);
    }
    if (!event.target.value) {
      event.target.style.height = '16px';
    } else {
      event.target.style.height = '';
      event.target.style.height = Math.min(event.target.scrollHeight + 15) + 'px';
    }
  }

  /*Comment*/
  limitSubComment() {
    if (this.subCommentData) {
      if (this.subCommentData.length > 2) {
        this.subCommentData.splice(0, this.subCommentData.length - 2);
      }
    }
  }

  onSubComment(event, post, comment) {
    if (event.key === 'Enter') {
      if (this.mentionService.lastKeyHaveMenu) {
        event.preventDefault();
        this.mentionService.lastKeyHaveMenu = false;
        return;
      }

      if (comment.subComment.trim()) {
        const sendData = {
          contentType: 'post',
          message: this.mentionService.convertStringToMentionString(comment.subComment.trim())
        };

        this.timelineService.postSubCommentByCommentId(post._id, comment._id, sendData).subscribe(data => {
          this.updateData();
        }, error => {
          console.log(error);
        });
      }

      comment.subComment = '';
      event.target.value = '';
      event.target.style.height = '16px';
    }
  }

  onTextChange(event) {
    this.showMentionMenu = this.mentionService.onTextChange(event);
  }

  onKeyDown(event, comment) {
    if (this.showMentionMenu) {
      comment.subComment = this.mentionService.onKeyDown(event);
      if (event.key === 'Enter') {
        this.showMentionMenu = false;
      }
    } else {
      this.mentionService.singleMention.emit(false);
    }
  }

  onSelectedMention(mention, comment) {
    this.textArea.nativeElement.focus();
    comment.subComment = this.mentionService.onSelectMention(mention, this.data.comment);
    this.showMentionMenu = false;
  }

  subCommentOrder(comment) {
    if (comment.totalSubComments > 2) {
      return comment.subComments.reverse();
    }
    return comment.subComments;
  }

  checkMoreSubComment() {
    if (this.subCommentData) {
      if (this.subCommentLength - this.subCommentData.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  onMoreSubComment() {
    if (this.subCommentData) {
      if (this.subCommentLength - this.subCommentData.length > 0) {
        this.timelineService.getMoreSubComment(this.comment._id, this.subCommentData[0]._id).subscribe(data => {
          this.tmpData = data;

          this.subCommentData = this.tmpData.concat(this.subCommentData);
        }, error => {
          console.log(error);
        });
      }
    }
  }

  onReplyComment(comment) {
    comment.openSubComment = true;
    setTimeout( () => {
      $(`#${comment._id}`).focus().setCursorPosition(1);
    }, 1);
  }

  /*like and unlike*/
  onLikeSubComment(subComment) {
    if (subComment.isLike) {
      this.timelineService.postUnlikeSubComment(subComment._id).subscribe(data => {
        subComment.isLike = false;
        subComment.contentStat.totalLikes--;
      }, error => {
        console.log(error);
      });
    } else {
      this.timelineService.postLikeSubComment(subComment._id).subscribe(data => {
        subComment.isLike = true;
        subComment.contentStat.totalLikes++;
      }, error => {
        console.log(error);
      });
    }
  }

  removeEnter(data) {
    return data.replace(/(\r\n|\n|\r)/gm, '');
  }

  // edit and delete comment
  onEdit(subComment) {
    subComment.editComment = true;
    this.tmpEditText = subComment.message;
    setTimeout(() => {
      $('#editArea').focus().height($('#editArea').prop('scrollHeight') + 15);
    }, 100);

  }

  onEditSubComment(event, subComment) {
    if (event.key === 'Enter') {
      subComment.message = this.removeEnter(subComment.message);
      if (subComment.message === '') {
        this.onDelete(event, subComment);
      } else {
        const sendData: any = {
          contentType : 'post',
          message: subComment.message
        };
        if (subComment.media) {
          sendData.media = subComment.media;
        }
        const subCommentID = subComment._id;
        const contentID = this.comment.contentId;
        const commentID = this.comment._id;
        this.timelineService.updateSubComment(contentID, commentID, subCommentID, sendData).subscribe(data => {
          this.updateData();
        }, error => {
          console.log(error);
        });
      }
    }
  }

  onUpdateGifDialog(subComment) {
    const sentData = {
      subCommentID: subComment._id,
      contentID: this.comment.contentId,
      commentID: this.comment._id,
      message: subComment.message,
      type: 'update-sub-comment'
    };

    const dia = this.dialog.open(GifDialogComponent, {
      data: sentData,
      maxWidth: '360px',
    });

    dia.afterClosed().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });
  }

  onDelete(event, subComment) {
    const dialogDelete = this.dialog.open(DeleteDialogComponent, {
      width: '500px'
    });

    dialogDelete.afterClosed().subscribe(data => {
      if (data) {
        this.timelineService.deleteSubComment(this.comment._id, subComment._id).subscribe(data => {
          this.updateData();
        }, error => {
          console.log(error);
        });
      } else {
        if (event) {
          event.target.style.height = '20px';
        }
      }
    });
  }

  onCancelEdit(comment) {
    comment.editComment = false;
    comment.message = this.tmpEditText;
  }
}
