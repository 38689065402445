import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../service/User/user.service';
import {DefaultThemeComponent} from '../Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../service/Theme/theme.service';
// @ts-ignore
import { version } from '../../../../package.json';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  // Form
  public loginForm: FormGroup;

  public loginError;
  public errorMessage = '';

  public version: string = version;
  public theme: DefaultThemeComponent;

  constructor(private router: Router, private route: ActivatedRoute, private userService: UserService,
              private themeService: ThemeService) {
  }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    // init form
    this.loginForm = new FormGroup({
      identifier: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });

    // is user already login
    if (this.userService.getToken()) {
      this.router.navigate([`/dashboard`], {relativeTo: this.route});
    }
  }

  // validation form
  public hasError = (controlName, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  };

  onSubmit() {
    if (this.loginForm.valid) {
      this.userService.login(this.loginForm.value).subscribe( data => {
          const log = {user: undefined};
          log.user = data;
          if (log.user.user.role.type === 'authenticated') {
            this.userService.saveToken(data);
            const self = this;
            setTimeout(() => {
              self.router.navigate([`/dashboard`], {relativeTo: self.route});
            }, 100);
          } else {
            this.errorMessage = 'You can only login with admin account';
          }
        }, error => {
          this.errorMessage = 'Incorrect email or password';
        }
      );
    }
  }

  // bool
  allowLogin() {
    return this.loginForm.valid;
  }

}
