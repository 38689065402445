import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PreviewMediaDialogComponent} from '../../../../../dialog/menu/timeline/preview-media-dialog/preview-media-dialog.component';
import {MatDialog} from '@angular/material';
import {UserService} from '../../../../../service/User/user.service';
import {DefaultThemeComponent} from "../../../../Common/Theme/default-theme/default-theme.component";
import {ThemeService} from "../../../../../service/Theme/theme.service";
import {TimelineService} from "../../../../../service/Timeline/timeline.service";

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {

  @Input() data;
  @Input() isDarkMode;
  @Input() type;

  @Output() refresh: EventEmitter<any> = new EventEmitter();

  public admin;

  public theme: DefaultThemeComponent;

  constructor(private dialog: MatDialog, private userService: UserService, private themeService: ThemeService,
              private timelineService: TimelineService) { }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.admin = this.userService.getToken().user;
    this.type = 'link';
  }

  // show image
  onShowImage(media, index, createDate, text, type) {
    const data = {
      media,
      index,
      user: this.admin,
      createdAt: createDate,
      text,
      type,
      postData: this.data
    };

    const dia = this.dialog.open(PreviewMediaDialogComponent, {
      data,
      minWidth: '1020px',
      maxWidth: '100vw'
    });

    dia.afterClosed().subscribe((result) => {
      this.timelineService.getPostById(this.data._id).subscribe((post) => {
        this.data = post;
      });
      this.refresh.emit();
    });
  }

  // calculate priview image condition
  getTypeImage(imageData): string {
    if (imageData && imageData.length > 0 && (imageData[0].type == 'image' || imageData[0].type == 'video')) {
      if (imageData.length == 2) {
        if ((imageData[0].width > imageData[0].height) && (imageData[1].width > imageData[1].height)) {
          return 'land2';
        } else if ((imageData[0].width < imageData[0].height) && (imageData[1].width < imageData[1].height)) {
          return 'port2';
        } else {
          return 'q2';
        }
      } else if (imageData.length == 3) {
        if (imageData[0].width > imageData[0].height) {
          return 'land3';
        } else if (imageData[0].width < imageData[0].height) {
          return 'port3';
        } else if (imageData[0].width == imageData[0].height) {
          return 'q3';
        }
      } else if (imageData.length == 4) {
        if (imageData[0].width > imageData[0].height) {
          return 'land4';
        } else if (imageData[0].width < imageData[0].height) {
          return 'port4';
        } else if (imageData[0].width == imageData[0].height) {
          return 'q4';
        }
      } else if (imageData.length >= 5) {
        if ((imageData[0].width == imageData[0].height) && (imageData[1].width == imageData[1].height) && (imageData[2].width == imageData[2].height)) {
          return 'q5';
        } else if (imageData[0].width > imageData[0].height) {
          return 'land5';
        } else if (imageData[0].width < imageData[0].height) {
          return 'port5';
        } else {
          if ((imageData[0].width == imageData[0].height) && (imageData[1].width > imageData[1].height)) {
            return 'land5';
          } else if ((imageData[0].width == imageData[0].height) && (imageData[1].width < imageData[1].height)) {
            return 'port5';
          } else if ((imageData[0].width == imageData[0].height) && (imageData[1].width == imageData[1].height) && (imageData[2].width > imageData[2].height)) {
            return 'land5';
          } else {
            return 'port5';
          }
        }
      }
    } else {
      return null;
    }
  }

  checkMetaData() {
    return this.data.metaPreview.filter((meta) => {
      return meta.type !== 'map';
    }).length > 0;
  }
}
