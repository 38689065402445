import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {UserService} from "../../../../service/User/user.service";
import * as $ from "jquery";
import {v4 as uuid} from 'uuid';
import {TimelineService} from "../../../../service/Timeline/timeline.service";
import {ThemeService} from "../../../../service/Theme/theme.service";
import {DefaultThemeComponent} from "../../../../component/Common/Theme/default-theme/default-theme.component";

@Component({
  selector: 'app-gif-dialog',
  templateUrl: './gif-dialog.component.html',
  styleUrls: ['./gif-dialog.component.css']
})
export class GifDialogComponent implements OnInit {
  public theGif = [];
  public searchData = '';
  public searchTimer;

  private apikey = 'LIVDSRZULELA';
  private anonId = '827a6bc5c7cf4e2084a9368bcc258b41';

  public moreData;

  // theme
  public theme: DefaultThemeComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private userService: UserService, private dialog: MatDialogRef<any>,
              private timelineService: TimelineService, private themeService: ThemeService) { }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.grab_data();
    const self = this;

    $('.gif-container').bind('scroll', (e) => {
      var elem = $(e.currentTarget);
      if (elem[0].scrollHeight - elem.scrollTop() == elem.outerHeight()) {
        self.getMoreData();
      }
    });
  }

  httpGetAsync(theUrl, callback) {
    const xmlHttp = new XMLHttpRequest();

    xmlHttp.onreadystatechange = () => {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        callback(xmlHttp.responseText, this);
      }
    };

    xmlHttp.open('GET', theUrl, true);
    xmlHttp.send(null);

    return;
  }

  tenorCallback_search(responsetext, self) {
    const responseObjects = JSON.parse(responsetext);
    self.moreData = responseObjects.next;
    responseObjects.results.forEach(f => {
      self.theGif.push(f);
    });

    return;
  }

  grab_data() {
    this.theGif = [];

    let searchUrl;
    if (this.searchData) {
      searchUrl = 'https://api.tenor.com/v1/search?key=' + this.apikey + '&contentfilter=low&anon_id='+this.anonId+'&media_filter=minimal&q=' + this.searchData;
    } else {
      searchUrl = 'https://api.tenor.com/v1/trending?key=' + this.apikey + '&contentfilter=low&anon_id='+this.anonId+'&media_filter=minimal';
    }
    this.httpGetAsync(searchUrl, this.tenorCallback_search);

    return;
  }

  getMoreData() {
    if(this.moreData) {
      let searchUrl;
      if (this.searchData) {
        searchUrl = 'https://api.tenor.com/v1/search?key=' + this.apikey + '&contentfilter=low&anon_id='+this.anonId+'&media_filter=minimal&q=' + this.searchData +"&pos="+this.moreData;
      } else {
        searchUrl = 'https://api.tenor.com/v1/trending?key=' + this.apikey + '&contentfilter=low&anon_id='+this.anonId+'&media_filter=minimal&pos='+this.moreData;
      }
      this.httpGetAsync(searchUrl, this.tenorCallback_search);

      return;
    }
  }

  onSelectGif(gif) {
    const sendData = {
      contentType : "post",
      media: [{
        id: uuid(),
        width: gif.media[0].gif.dims[0],
        height: gif.media[0].gif.dims[1],
        url: gif.media[0].gif.url,
        type: 'tenorGif'
      }]
    };

    if(this.data.type == 'comment') {
      const post = this.data.post;
      this.timelineService.postCommentByPostId(post._id, sendData).subscribe(data => {
        this.dialog.close(true);
      }, error => {
        console.log(error);
      });
    }else if(this.data.type == 'sub-comment') {
      const post = this.data.post;
      const comment = this.data.comment;
      this.timelineService.postSubCommentByCommentId(post._id, comment._id, sendData).subscribe(data => {
        this.dialog.close(true);
      }, error => {
        console.log(error);
      });
    } else if(this.data.type == 'update-comment') {
      const commentID = this.data.post._id;
      this.timelineService.updateComment(commentID, sendData).subscribe(data => {
        this.dialog.close(true);
      }, error => {
        console.log(error);
      });
    } else if(this.data.type == 'update-sub-comment') {
      sendData["message"] = this.data.message;
      this.timelineService.updateSubComment(this.data.contentID, this.data.commentID, this.data.subCommentID, sendData).subscribe(data => {
        this.dialog.close(true)
      }, error => {
        console.log(error);
      });
    }
  }

  applyFilter(data) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.searchData = data;
      this.grab_data();
    }, 1000);
  }

}
