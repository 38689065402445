import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from "@angular/material";

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html'
})
export class ErrorDialogComponent implements OnInit {

  constructor(private _snackBar: MatSnackBar, @Inject(MAT_SNACK_BAR_DATA) public data) { }

  ngOnInit() {
  }

  onClose(){
    this._snackBar.dismiss();
  }
}
