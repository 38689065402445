import { Injectable } from '@angular/core';
import {BaseService} from '../common/base.service';
import {ProfileDialogComponent} from '../../dialog/menu/timeline/profile-dialog/profile-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private baseService: BaseService) { }

  /// User
  addNewUser(data) {
    return this.baseService.post('auth/local/register', data);
  }

  getUserById(id) {
    return this.baseService.get('getusers/' + id);
  }

  updateUser(id, data) {
    return this.baseService.put('users/' + id, data);
  }

  // Get customer
  getCustomer() { return this.baseService.get('customers'); }
  // Add customer
  addCustomer(data) { return this.baseService.post('customers', data); }
  // Update custoer
  updateCustomer(id, data) { return this.baseService.put('customers/' + id, data); }
  // Delete customer
  deleteCustomer(id) { return this.baseService.delete('customers/' + id); }

  getAllUserByType(type) {
    return this.baseService.get('api/pro/users/' + type);
  }

  getAllUser() {
    return this.baseService.get('api/pro/users/');
  }

  // Notification
  getNotificationBadge() {
    return this.baseService.get('api/notifications/badges');
  }

  getNewNotification() {
    return this.baseService.get('api/newnotifications');
  }

  getOldNotification() {
    return this.baseService.get('api/notifications/history/0/25');
  }

  // Current login user
  /// Authorization
  login(data) {
    return this.baseService.post('auth/local', data);
  }

  /// Token
  saveToken(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getToken() {
    return JSON.parse(localStorage.getItem('user'));
  }

  logout() {
    localStorage.removeItem('user');
  }

  getUserId() {
    return this.getToken().user._id;
  }
}
