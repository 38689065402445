import {AfterViewInit, Component, EventEmitter, OnInit} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ErrorDialogComponent} from '../../../organizationchart/error-dialog/error-dialog.component';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {UploadService} from '../../../../service/common/upload.service';
import {UserService} from '../../../../service/User/user.service';
import {DefaultThemeComponent} from '../../../../component/Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {SettingService} from '../../../../service/Setting/setting.service';
import {JobService} from '../../../../service/Job/job.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-add-staff-dialog',
  templateUrl: './add-staff-dialog.component.html',
  styleUrls: ['./add-staff-dialog.component.css']
})
export class AddStaffDialogComponent implements OnInit, AfterViewInit {

  public pageLoaded;
  public addStaffForm: FormGroup;
  public jobs;
  public inputData;

  // filter job
  filteredOptions: Observable<string[]>;

  // upload image
  public uploader: FileUploader;
  private uploadPreset;
  private uploadUrl;
  private uploadClouldName;
  public picture;
  public doneProcess;

  // cover picture
  public profileCover;

  // theme
  public theme: DefaultThemeComponent;

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private uploadService: UploadService,
              private userService: UserService, private settingService: SettingService,
              private themeService: ThemeService, private jobService: JobService) { }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    // upload image
    this.doneProcess = true;
    this.uploadClouldName = this.uploadService.uploadClouldName;
    this.uploadUrl = this.uploadService.uploadUrl;
    this.uploadPreset = this.uploadService.uploadProfilePreset;

    // get cover picture
    this.getCoverPicture();

    // init form
    this.addStaffForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      proType: new FormControl('staff'),
      job: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', []),
    }, {
      validators: [this.MatchPassword]
    });

    // get all job
    this.getAllJobs();

    // init upload image
    this.initUpload();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 1000);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.jobs.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  getAllJobs() {
    this.jobService.getAllJob().subscribe((data) => {
        this.jobs = data;

        // filter job search
        this.filteredOptions = this.addStaffForm.controls.job.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );

        // filter job delete
        this.jobs = this.jobs.filter((f) => {
          return f.isDeleted !== 'true';
        });

        // filter job sort
        this.jobs = this.jobs.sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);
      }, (error) => {
        console.log(error);
      }
    );
  }

  initUpload() {
    const uploaderOptions: FileUploaderOptions = {
      url: this.uploadUrl,
      autoUpload: false,
      isHTML5: true,
      removeAfterUpload: true,
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onBuildItemForm = (fileItem, form) => {
      const fileType = fileItem.file.type.split('/')[0];
      form.append('upload_preset', this.uploadPreset);
      form.append('file', fileItem);
      form.append('tag', fileType);
      form.append('public_id', 'profile_' + new Date().getTime());

      fileItem.withCredentials = false;
      this.doneProcess = false;
      return {fileItem, form};
    };

    this.uploader.onCompleteItem = (item, response, status, headers) => {
      const res = JSON.parse(response);

      const getData = this.uploadService.getClouldinaryUrl(res);
      this.inputData.profileUrl = getData.mediaTransformUrl;
      this.doneProcess = true;
      this.onAddNewUser(this.inputData);
    };
  }

  onClearImage() {
    this.picture = null;
  }

  public hasError = (controlName, errorName: string) => {
    return this.addStaffForm.controls[controlName].hasError(errorName);
  };

  onClose() {
    this.dialog.closeAll();
  }

  findJob(label) {
    return this.jobs.find((job) => {
      return job.label.toLowerCase() === label.toLowerCase();
    });
  }

  onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    this.viewImageOffline(file);
  }

  onAddNewUser(inputData) {
    this.userService.addNewUser(inputData).subscribe(data => {
      this.snackBar.openFromComponent(ErrorDialogComponent, {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'end',
        data: 'addUser'
      });
      this.dialog.closeAll();
    }, error => {
      if (error.error.message === 'Email is already taken.') {
        this.snackBar.openFromComponent(ErrorDialogComponent, {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: 'emailTaken'
        });
      }
      console.log(error);
    });
  }

  onSubmit() {
    if (this.addStaffForm.valid) {
      const inputData = this.addStaffForm.value;
      inputData.proType = inputData.proType.toLowerCase();
      // inputData.profileUrl = this.picture;
      if (!this.picture) { delete inputData.profileUrl; }
      delete inputData.confirmPassword;

      const job = this.findJob(inputData.job);
      if (job) {
        inputData.job = job._id;
        if (!this.picture) {
          this.onAddNewUser(inputData);
        } else {
          this.uploader.uploadAll();
          this.inputData = inputData;
        }
      } else {
        this.jobService.addNewJob({label: inputData.job}).subscribe((response: any) => {
          inputData.job = response._id;
          if (!this.picture) {
            this.onAddNewUser(inputData);
          } else {
            this.uploader.uploadAll();
            this.inputData = inputData;
          }
        });
      }
    }
  }

  viewImageOffline(input) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.picture = e.target.result;
    };
    reader.readAsDataURL(input);
  }

  MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value;
    const confirmPassword = AC.get('confirmPassword').value;
    if (confirmPassword === '') {
      AC.get('confirmPassword').setErrors( {required: true} );
    } else if (password !== confirmPassword) {
      AC.get('confirmPassword').setErrors( {MatchPassword: true} );
    } else {
      AC.get('confirmPassword').setErrors( null );
      return null;
    }
  }

  // cover picture
  getCoverPicture() {
    this.settingService.getSetting().subscribe(setting => {
      if (setting[0].contactBackground) {
        this.profileCover = setting[0].contactBackground;
      } else {
        this.profileCover = './assets/image/logo/default_cover_store.jpg';
      }
    }, error => {
      console.log(error);
    });
  }

  // bool
  isAllowCreate() {
    return this.addStaffForm.valid;
  }
}
