import {Injectable} from '@angular/core';
import {BaseService} from '../common/base.service';
import {UserService} from '../User/user.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  private settingId;

  constructor(private service: BaseService, private userService: UserService) {
    if (this.userService.getToken()) {
      this.getSettingId();
    }
  }

  // get Organization name
  async getOrganizationName() {
    const settingData = await this.getSetting().toPromise();
    return settingData[0].organisationName;
  }

  async getSettingId() {
    const settingData = await this.getSetting().toPromise();
    this.settingId = settingData[0]._id;
  }

  // setting request data
  getSetting() {
    return this.service.get('prosettings');
  }

  updateSetting(data) {
    if (!this.settingId) {
      this.getSettingId().then(() => {
        return this.service.put('prosettings/' + this.settingId, data);
      });
    }
    return this.service.put('prosettings/' + this.settingId, data);
  }
}
