import {Component, Inject, OnInit} from '@angular/core';
import {DefaultThemeComponent} from '../../../../component/Common/Theme/default-theme/default-theme.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {UserService} from '../../../../service/User/user.service';

@Component({
  selector: 'app-post-detail-dialog',
  templateUrl: './post-detail-dialog.component.html',
  styleUrls: ['./post-detail-dialog.component.css']
})
export class PostDetailDialogComponent implements OnInit {

  public theme: DefaultThemeComponent;

  constructor(private postDetailDialog: MatDialogRef<PostDetailDialogComponent>, @Inject(MAT_DIALOG_DATA) public data,
              private dialog: MatDialog, private userService: UserService, private themeService: ThemeService) { }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });
  }

  // make first letter capital
  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
  }

  onGotoMap() {
    window.open('http://maps.google.com/maps?z=12&t=m&q=loc:' + this.data.latitude + '+' + this.data.longitude, '_blank');
  }
  // end of profile

  onClose() {
    this.postDetailDialog.close();
  }
}
