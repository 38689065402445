export default class NotificationMessage {
  public static notificationMessageArrayEnglish = [
    ['post', 'added a new post.'],
    ['likePost', 'like your post.'],
    ['likeMedia', 'like your photo.'],
    ['likeVideo', 'like your video.'],
    ['likeCommentPost', 'like your comment.'],
    ['likeCommentMedia', 'like your comment on a photo.'],
    ['likeCommentVideo', 'like your comment on a video.'],
    ['likeSubCommentPost', 'like your comment.'],
    ['likeSubCommentMedia', 'like your comment on a photo.'],
    ['likeSubCommentVideo', 'like your comment on a video.'],
    ['commentPost', 'comments on your post.'],
    ['commentMedia', 'comments on your photo.'],
    ['commentVideo', 'comments on your video.'],
    ['replyCommentPost', 'replied to your comment.'],
    ['replyCommentMedia', 'replied to your comment on a photo.'],
    ['replyCommentVideo', 'replied to your comment on a video.'],
    ['replySubCommentPost', 'replied to your comment.'],
    ['replySubCommentMedia', 'replied to your comment on a photo.'],
    ['replySubCommentVideo', 'replied to your comment on a video.'],
    ['mentionPost', 'mentioned you in a post.'],
    ['mentionCommentPost', 'mentioned you in a comment.'],
    ['mentionCommentMedia', 'mentioned you in a photo.'],
    ['mentionCommentVideo', 'mentioned you in a video'],
    ['mentionSubCommentPost', 'mentioned you in a comment.'],
    ['mentionSubCommentMedia', 'mentioned you in a photo.'],
    ['mentionSubCommentVideo', 'mentioned you in a video']
  ];
}
