import {Component, Inject, OnInit} from '@angular/core';
import {DefaultThemeComponent} from '../../../../component/Common/Theme/default-theme/default-theme.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {UserService} from '../../../../service/User/user.service';
import {Router} from '@angular/router';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {SettingService} from "../../../../service/Setting/setting.service";

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.css']
})
export class ProfileDialogComponent implements OnInit {
  public contactBgUrl;
  public show = 4;
  public didHideShowMore = false;

  // license
  public tChat;

  public theme: DefaultThemeComponent;

  constructor(private dialog: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data, private userService: UserService,
              private router: Router, private snackBar: MatSnackBar, private openDialog: MatDialog,
              private themeService: ThemeService, private settingService: SettingService) { }

  async ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.getContactBackground();

    // this.tChat = licenseData.synchronize.features.call;
    // this.userService.getSetting().subscribe(data => {
    //   this.contactBgUrl = data[0].contactBackground;
    // });
    // // get additional info
    // const jsonConvert: JsonConvert = new JsonConvert();
    // jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    // jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // allow null
    // if (!this.data.user.email && this.data.isUser) {
    //   const data = await this.userService.getOtherUserById(this.data.user._id).toPromise();
    //   try {
    //     const user = jsonConvert.deserializeObject(data, UserObj);
    //     this.data.user.jobLabel = user['jobLabel'];
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
  }

  getContactBackground() {
    this.settingService.getSetting().subscribe((settingList) => {
      const settingData = settingList[0];
      this.contactBgUrl = settingData.contactBackground;
    })
  }

  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
  }

  onCloseDialog() {
    this.dialog.close();
  }

  generateConversationId(userId1, userId2) {
    let convId = userId1 + '_' + userId2;
    if (userId1 > userId2) {
      convId = userId2 + '_' + userId1;
    }
    return convId;
  }

  openSnackbar(message, action) {
    const snackBarRef = this.snackBar.open(message, action, {
      duration: 20000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: ['snackbar']
    });
    snackBarRef.afterDismissed().subscribe(() => {
    });
    snackBarRef.onAction().subscribe(() => {
    });
  }
}
