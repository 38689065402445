import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DefaultThemeComponent} from '../../../component/Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../service/Theme/theme.service';
import {OrganizationService} from '../../../service/Organization/organization.service';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.css']
})
export class AddDepartmentComponent implements OnInit {

  public departmentName;
  public subordinationData;
  public subordinationID;

  public theme: DefaultThemeComponent;

  constructor(private dialog: MatDialogRef<AddDepartmentComponent>,
              @Inject(MAT_DIALOG_DATA) public data, private themeService: ThemeService,
              private organizationService: OrganizationService) { }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.organizationService.getAllSubordination().subscribe(data => {
      this.subordinationData = data;
      const findData = this.subordinationData.find((f) => {
        return f.user == this.data;
      });
      this.subordinationID = findData._id;
      if (findData.department) { this.departmentName = findData.department; }
    }, error => {
      console.log(error);
    });
  }

  onClose() {
    this.dialog.close(false);
  }

  onSubmit() {
    const sentData = {
      department: this.departmentName
    };

    this.organizationService.updateSubordination(this.subordinationID, sentData).subscribe(data => {
    }, error => {
      console.log(error);
    });
    this.dialog.close(true);
  }

  // bool
  isNameValid() {
    return !(this.departmentName && this.departmentName.length > 0);
  }

}
