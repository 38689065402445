import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  ViewChild
} from '@angular/core';
import {UserService} from '../../../../service/User/user.service';
import {MatDialog} from '@angular/material';
import {InputDialogComponent} from '../../../../dialog/menu/timeline/input-dialog/input-dialog.component';
import {DarkmodeService} from '../../../../service/darkmode.service';
import {TimelineUploadService} from '../../../../service/Timeline/timeline-upload.service';
import {LinkPreviewService} from '../../../../service/Timeline/link-preview.service';
import {TimelineService} from '../../../../service/Timeline/timeline.service';
import {DefaultThemeComponent} from "../../../Common/Theme/default-theme/default-theme.component";
import {ThemeService} from "../../../../service/Theme/theme.service";
import {RealTimeService} from '../../../../service/common/real-time.service';
import * as $ from 'jquery';
import {MentionService} from "../../../../service/common/mention.service";

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {

  public isDarkMode;
  public openInput;
  public nextData;
  public tmpData;

  // get post data
  public postData: IPost[];
  public admin;
  public loadTimer;

  // upload post
  public openEmoji;
  public mediaPreview;
  public filePreview;

  // load more
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 1;

  // who can see the post
  public typeSee = '2';

  // open file auto
  private inputDialog;

  // reload page
  public reload: EventEmitter<any> = new EventEmitter();

  // text
  public quoteLimit = 100;
  @ViewChild('Text') textArea: ElementRef;
  @ViewChild('theScroll') theScroll;

  // theme
  public theme: DefaultThemeComponent;

  constructor(private userService: UserService, private dialog: MatDialog, private darkmodeService: DarkmodeService,
              public timelineUploadService: TimelineUploadService, private linkPreviewService: LinkPreviewService,
              private timelineService: TimelineService, private themeService: ThemeService,
              private realTimeService: RealTimeService, private cdRef : ChangeDetectorRef,
              private mentionService: MentionService) {
    /// get all post Data and admin info
    this.updateData(0);
    this.admin = this.userService.getToken().user;
  }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    // upload post
    this.openEmoji = false;
    this.darkmodeService.getAvatar().subscribe(data => {
      this.admin = data;
    });

    this.isDarkMode = this.darkmodeService.getLocalDarkMode();
    this.darkmodeService.getDarkMode().subscribe(data => {
      this.isDarkMode = data;
    });
    this.openInput = false;

    // reload page
    this.reload.subscribe(() => {
      this.updateData(0);
    });

    this.realTimeService.notificationBadgeListener.emit(true);

    $('#main-div').scroll(() => {
      this.mentionService.singleMention.emit(false);
    });
  }

  onInput() {
    this.inputDialog = this.dialog.open(InputDialogComponent, {
      width: '650px',
    });
    this.inputDialog.updatePosition({ top: '3.7%' });

    this.inputDialog.afterClosed().subscribe(data => {
      if (data) {
        this.updateData(0);
      }
      // this.textArea.nativeElement.style.height = this.timelineUploadService.height;
      // this.mediaPreview = this.timelineUploadService.mediaPreview;
      // this.filePreview = this.timelineUploadService.filePreview;
      // this.typeSee = this.timelineUploadService.typeSee;
    });
  }

  updateData(nextPost) {
    this.timelineService.getAllTimelinePost(nextPost).subscribe((data) => {
        this.tmpData = data;
        if (this.tmpData.next) {
          this.nextData = this.tmpData.next;
        }
        this.postData = this.tmpData.list;
      }, (error) => {
        console.log(error);
      }
    );
  }

  getMoreData() {
    this.timelineService.getAllTimelinePost(this.nextData).subscribe(data => {
        this.tmpData = data;
        if (this.tmpData.next) {
          this.nextData = this.tmpData.next;
        } else {
          this.nextData = '';
        }
        this.postData = this.postData.concat(this.tmpData.list);
      }, (error) => {
        console.log(error);
      }
    );
  }

  onScrollDown() {
    if (this.nextData) {
      this.getMoreData();
    }
  }

  // auto open up select file/ select visibility
  openFile(data) {
    setTimeout(() => {
      this.inputDialog.componentInstance.openFile.emit(data);
    }, 500);
  }

  // update post
  updatePost($event, index) {
    console.log($event);
  }

  // boolean function
  checkMedia() {
    return this.mediaPreview && (this.mediaPreview.length > 0 || this.filePreview.length > 0);
  }

  isTextQuote() {
    return (this.timelineUploadService.text && this.timelineUploadService.text.length > 0) &&
      (this.timelineUploadService.text.length <= this.quoteLimit) &&
      !this.linkPreviewService.checkIfUrl(this.timelineUploadService.text) &&
      !this.timelineUploadService.text.includes('#') && !this.timelineUploadService.text.includes('@') &&
      !this.checkMedia();
  }
}
