import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ThemeService} from '../../../service/Theme/theme.service';
import {DefaultThemeComponent} from '../../../component/Common/Theme/default-theme/default-theme.component';
import {OrganizationService} from '../../../service/Organization/organization.service';

@Component({
  selector: 'app-exception-dialog',
  templateUrl: './exception-dialog.component.html',
  styleUrls: ['./exception-dialog.component.css']
})
export class ExceptionDialogComponent implements OnInit {

  public userData;          // data input
  public canSeeUser = [];   // exception user
  public willSeeUser = [];  // user that see automatically
  public tmpData;

  public sAll;              // select all
  public killScroll;        // prevent scroll bug

  public theme: DefaultThemeComponent;

  @ViewChild('con') con: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialog: MatDialogRef<ExceptionDialogComponent>,
              private themeService: ThemeService,
              private organizationService: OrganizationService) {
  }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.updateData();
    this.killScroll = false;
  }

  onLoad() {
    if (!this.killScroll) {
      this.con.nativeElement.scrollTop = 0;
      this.killScroll = true;
    }
  }

  onClose() {
    this.dialog.close(false);
  }

  onAdd() {
    // get all ticked member
    this.userData.forEach((f) => {
      if (f.checked) {
        this.canSeeUser.push(f);
      }
    });
    this.userData = this.userData.filter(val => !this.canSeeUser.includes(val));

    /// prepare data before send
    const other = [];
    this.canSeeUser.forEach((f) => {
      other.push(f.user);
    });

    const sendData = {
      user: this.data,
      others: other
    };

    // sent data
    this.organizationService.addorUpdateManualBound(this.data, sendData).subscribe(data => {
      this.sAll = false;
    }, error => {
      console.log(error);
    });
  }

  onDelete(data) {
    // sent data back and delete form can see
    data.checked = false;
    this.userData.push(data);
    this.canSeeUser = this.canSeeUser.filter(val => !this.userData.includes(val));

    /// prepare data before send
    const other = [];
    this.canSeeUser.forEach((f) => {
      other.push(f.user);
    });

    const sendData = {
      user: this.data,
      others: other
    };

    // sent data
    this.organizationService.addorUpdateManualBound(this.data, sendData).subscribe(data => {
    }, error => {
      console.log(error);
    });
  }

  onUpdateNumClick() {
    if (this.userData) {
      for (const u of this.userData) {
        if (u.checked) {
          return false;
        }
      }
    }
    return true;
  }

  updateData() {
    // get all subordination data
    this.organizationService.getAllSubordination().subscribe(
      data => {
        this.userData = data;
        this.userData = this.userData.filter((f) => {
          return f.isDeleted != 'true';
        });
        this.userData.forEach((f, i) => {
          if (i > 0) {
            f.firstName = f.fullUser.firstName;
            f.lastName = f.fullUser.lastName;
            f.checked = false;
          }
        });

        // delete the company name because it not person
        this.userData.splice(0, 1);

        // get all the child
        const child = this.userData.filter((f) => {
          return f.parent == this.data;
        });
        child.forEach((f) => {
          this.willSeeUser.push(f);
        });

        // get the parent
        const myParent = this.userData.find((f) => {
          return f.user == this.data;
        }).parent;
        const parent = this.userData.find((f) => {
          return f.user == myParent;
        });
        if (parent) { this.willSeeUser.push(parent); }

        // get all that in same level with same parent
        const bro = this.userData.filter((f) => {
          if (f.user != this.data) {
            return f.parent == myParent;
          }
        });
        bro.forEach((f) => {
          this.willSeeUser.push(f);
        });

        // delete yourself you can't talk to yourself
        const findData = this.userData.find((f) => {
          return f.user == this.data;
        });
        const key = this.userData.indexOf(findData);
        if (key >= 0) {
          this.userData.splice(key, 1);
        }

        // remove duplicate
        this.userData = this.userData.filter(val => !this.willSeeUser.includes(val));
        this.userData = this.userData.filter(val => !this.canSeeUser.includes(val));

        // get all exception member id
        this.organizationService.getAllManualBound().subscribe(data => {
          let findData = [];
          this.tmpData = data;
          this.tmpData.forEach((f) => {
            if (f.user == this.data) {
              findData = f.others;
            }
          });

          // get all exception member data
          const fixData = [];
          findData.forEach((f) => {
            if (this.userData) {
              fixData.push(this.userData.find((g) => {
                return g.user == f;
              }));
            }
          });

          // add all exception member to can see and remove duplicated
          this.canSeeUser = fixData;
          this.userData = this.userData.filter(val => !this.canSeeUser.includes(val));
        }, error => {
          console.log(error);
        });
      }, error => {
        console.log(error);
      }
    );
  }

  onSelectAll(data) {
    this.userData.forEach(f => {
      f.checked = data.checked;
    });
  }

  // profile picture
  hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i) {
    const c = (i & 0x00FFFFFF).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

  renderBackgroundColor(user) {
    return this.intToRGB(this.hashCode(user.firstName + ' ' + user.lastName));
  }

  renderInitial(user) {
    return user.firstName.substring(0, 1).toUpperCase() + user.lastName.substring(0, 1).toUpperCase();
  }
}
