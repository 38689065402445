import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DefaultThemeComponent} from '../../../Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {init} from 'protractor/built/launcher';

@Component({
  selector: 'app-day-notification-setting',
  templateUrl: './day-notification-setting.component.html',
  styleUrls: ['./day-notification-setting.component.css']
})
export class DayNotificationSettingComponent implements OnInit {

  private weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  @Input() title;
  @Input() dateArray;
  @Input() active;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  public startTimeArray = [];
  public endTimeArray = [];

  // theme
  public theme: DefaultThemeComponent;

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    // time array
    for(let i = 0; i < this.dateArray.length; i++) {
      this.startTimeArray[i] = this.generateTimeArray();
      this.endTimeArray[i] = this.generateTimeArray();
    }

    if (this.startTimeArray.length === 0) { this.startTimeArray.push(this.generateTimeArray()); }
    if (this.endTimeArray.length === 0) { this.endTimeArray.push(this.generateTimeArray()); }
  }

  // action function
  onCheckedActive(event) {
    this.active = event.target.checked;
    if (this.active && this.dateArray.length === 0) {
      this.dateArray.push({start: '', end: ''});
    }
    this.onChange.emit({day: this.title, timeList: this.dateArray, active: this.active, weekday: this.weekday.indexOf(this.title)});
  }

  onSelectedStart(event, index) {
    const selectedIndex = this.startTimeArray[index].indexOf(event.target.value);
    if (selectedIndex > -1) {
      this.endTimeArray[index] = this.generateTimeArray();
      for (let i = 0; i < selectedIndex + 1; i++) {
        this.endTimeArray[index].shift();
      }
    }
    this.onChange.emit({day: this.title, timeList: this.dateArray, active: this.active, weekday: this.weekday.indexOf(this.title)});
  }

  onSelectedEnd(event, index) {
    const array = [];
    this.startTimeArray[index] = this.generateTimeArray();
    const selectedIndex = this.startTimeArray[index].indexOf(event.target.value);
    if (selectedIndex > -1) {
      for (let i = 0; i < selectedIndex; i++) {
        array.push(this.startTimeArray[index][i]);
      }
    }
    this.startTimeArray[index] = array;
    this.onChange.emit({day: this.title, timeList: this.dateArray, active: this.active, weekday: this.weekday.indexOf(this.title)});
  }

  onAddHour() {
    this.dateArray.push({start: '', end: ''});
    this.startTimeArray.push(this.generateTimeArray());
    this.endTimeArray.push(this.generateTimeArray());
    this.onChange.emit({day: this.title, timeList: this.dateArray, active: this.active, weekday: this.weekday.indexOf(this.title)});
  }

  onDeleteHour(index) {
    if (this.dateArray.length !== 1) {
      this.dateArray.splice(index, 1);
      this.startTimeArray.splice(index, 1);
      this.endTimeArray.splice(index, 1);
    } else {
      this.active = false;
    }
    this.onChange.emit({day: this.title, timeList: this.dateArray, active: this.active, weekday: this.weekday.indexOf(this.title)});
  }

  // helper function
  generateTimeArray() {
    const array = [];
    for (let i = 0; i <= 10; i++) {
      array.push(`${(i + 8) > 12 ? (i + 8) % 12 : (i + 8)}:00 ${(i + 8) >= 12 ? 'PM' : 'AM'}`);
      array.push(`${(i + 8) > 12 ? (i + 8) % 12 : (i + 8)}:30 ${(i + 8) >= 12 ? 'PM' : 'AM'}`);
    }
    return array;
  }

  // boolean function
  checkSwitch(event, array) {
    if (array.length === 0) {
      event.checked = false;
      array.push({start: '', end: ''});
    }
    return event.checked;
  }
}
