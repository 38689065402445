import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../service/User/user.service';
import {DarkmodeService} from '../../service/darkmode.service';
import {MatDialog, MatTabGroup} from '@angular/material';
import {DefaultThemeComponent} from '../Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../service/Theme/theme.service';
import {NotificationDialogComponent} from '../../dialog/notification-dialog/notification-dialog.component';
import {SettingService} from '../../service/Setting/setting.service';
import {LanguageService} from '../../service/Setting/language.service';
import {CurrentProfileDialogComponent} from '../../dialog/current-profile-dialog/current-profile-dialog.component';
import {RealTimeService} from '../../service/common/real-time.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  // Theme
  public theme: DefaultThemeComponent;

  // Notification
  public badge;

  public isDarkmode;
  public admin;
  public picture;
  public hideRoute;
  public indexRoute;

  public logoLight;
  public logoDark;

  @ViewChild('tabs') tabs: MatTabGroup;

  constructor( private router: Router, private route: ActivatedRoute, private userService: UserService,
               private darkmodeService: DarkmodeService, public themeService: ThemeService, private dialog: MatDialog,
               private settingService: SettingService, private languageService: LanguageService,
               private realTimeService: RealTimeService) {
    this.admin = this.userService.getToken().user;
  }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((data) => {
      this.theme = data;
    });

    // setting
    this.settingService.getSetting().subscribe((settingData: any) => {
      this.themeService.setTheme(settingData[0].theme);
      this.languageService.setLanguage(settingData[0].language);
    });

    this.darkmodeService.downloadAdminName();
    this.darkmodeService.getAvatar().subscribe(data => {
      this.admin = data;
    });

    this.darkmodeService.downloadLogo();
    this.darkmodeService.getLogo().subscribe(data => {
      this.logoLight = data.pictureLightmode;
      this.logoDark = data.pictureDarkmode;
    });

    this.isDarkmode = this.darkmodeService.getLocalDarkMode();
    this.darkmodeService.getDarkMode().subscribe(data => {
      this.isDarkmode = data;
    });
    this.onChangeRoute(this.router.url.split('/')[2]);
    if (!this.userService.getToken()) {
      this.onLogout();
    }
  }

  onChangeRoute(data) {
    if (data === 'customer') { this.indexRoute = 0; }
  }

  onChange(data) {
    this.indexRoute = data.index;
    this.switchRoutebyData(data.index);
  }

  onChangeNav(data) {
    if (data === this.indexRoute || this.indexRoute === undefined) {
      this.switchRoutebyData(data);
    }
  }

  onLogout() {
    const self = this;
    setTimeout(() => {
      self.userService.logout();
      self.router.navigate(['/login'], {relativeTo: self.route});
    }, 100);
  }

  switchRoutebyData(data) {
    let nav;
    if (data === 0) { nav = 'customer'; }

    this.hideRoute = false;

    this.router.navigate([`/dashboard/${nav}`], {relativeTo: this.route});
  }

  // profile
  openCurrentProfile(userData) {
    this.dialog.open(CurrentProfileDialogComponent, {
      width: '500px',
      autoFocus: false,
      data: {
        user: userData,
        isUser: true
      }
    });
  }
}
