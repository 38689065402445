import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import DatePostUtil from '../../share/date-post-util';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private language;

  constructor(private translateService: TranslateService) {
    translateService.addLangs(['en', 'fr']);
    translateService.setDefaultLang(this.getLocalLanguage());
  }

  getLanguage() {
    return this.language || this.getLocalLanguage();
  }

  setLanguage(language) {
    this.language = language;
    this.saveLocalLanguage(language);
    this.translateService.use(language);
    DatePostUtil.changeLanguage(language);
  }

  saveLocalLanguage(language) {
    localStorage.setItem('language', language);
  }

  getLocalLanguage() {
    return localStorage.getItem('language');
  }

}
