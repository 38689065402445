import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {JobService} from '../../../../service/Job/job.service';
import {DefaultThemeComponent} from '../../../../component/Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../service/Theme/theme.service';

@Component({
  selector: 'app-intervenant-dialog',
  templateUrl: './intervenant-dialog.component.html',
  styleUrls: ['./../../staff/staff-dialog/staff-dialog.component.css']
})
export class IntervenantDialogComponent implements OnInit {

  private jobs;

  // Theme
  public theme: DefaultThemeComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private dialog: MatDialog, private themeService: ThemeService,
              private jobService: JobService) { }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.jobService.getAllJob().subscribe(data => {
      this.jobs = data;
    }, error => {
      console.log(error);
    });
  }

  onClose() {
    this.dialog.closeAll();
  }

  getJobLabel(id) {
    if (this.jobs) {
      return this.jobs.find((f) => {
        return f.id == id;
      }).label;
    }
  }

  getJobById(id) {
    if (this.jobs) {
      return this.jobs.find((f) => {
        return f.id == id;
      });
    }
  }

  // profile picture
  hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i) {
    const c = (i & 0x00FFFFFF).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

  renderBackgroundColor(user) {
    return this.intToRGB(this.hashCode(user.firstName + ' ' + user.lastName));
  }

  renderInitial(user) {
    return user.firstName.substring(0, 1).toUpperCase() + user.lastName.substring(0, 1).toUpperCase();
  }
}
