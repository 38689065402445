import { Component, OnInit } from '@angular/core';
import {ITheme} from '../../../../Interface/Theme/ITheme';

@Component({
  selector: 'app-default-theme',
  template: ''
})
export class DefaultThemeComponent implements OnInit, ITheme {

  constructor() { }

  ngOnInit() {
  }

  backgroundPrimary() { return 'background-primary'; }
  backgroundSecondary() { return 'background-secondary'; }
  colorPrimary() { return 'color-primary'; }
  bubblePrimary() { return 'bubble-primary'; }
  bubbleSecondary() { return 'bubble-secondary'; }
  bubbleDisable() { return 'bubble-disable'; }
  iconPrimary() { return 'icon-primary'; }
  iconButton() { return 'icon-button'; }
  buttonHover() { return 'button-hover'; }
}
