import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../service/User/user.service';
import {AvatarService} from '../../service/common/avatar.service';

@Component({
  selector: 'app-avatar-update',
  templateUrl: './avatar-update.component.html',
  styleUrls: ['./avatar-update.component.css']
})
export class AvatarUpdateComponent implements OnInit {

  @Input() userData; // single data
  @Input() userDataList = []; // multiple data
  @Input() size; // avatar size (default 40)
  @Input() type; // single, multiple (default single)
  @Input() showOnlineStatus; // show online status or not (default not show)
  @Input() isOnline; // is online? (default offline)
  @Input() allowOpenProfile = true;

  constructor(private avatarService: AvatarService, private userService: UserService) { }

  newArray() {
    const data = [...this.userDataList];
    this.userDataList = data;
  }

  ngOnInit() {
    this.newArray();
    if (this.userDataList && this.userDataList.length === 2) {
      this.userData = this.userDataList.filter(userData => userData._id !== this.userService.getUserId())[0];
      this.type = 'single';
    }
    // for new created conversation, there's only participant
    if (this.userDataList && this.userDataList.length === 1) {
      this.userData = this.userDataList[0];
      this.type = 'single';
    }

    if (!this.size) { this.size = 40; }
    if (!this.type) { this.type = 'single'; }
  }

  checkHaveProfile(userData) {
    return userData && userData.profileUrl;
  }

  // background and font size
  getBackground(userData) {
    if (userData) {
      return this.avatarService.avatarBackground(userData);
    }
    return '#7da207';
  }

  getFontSize() {
    return this.size / 2 + 'px';
  }

  // text and image
  getText(userData) {
    // if (userData) {
    //   return this.avatarService.avatarText(userData);
    // }
    return 'AB';
  }

  getImage(userData) {
    if (userData) {
      return userData.profileUrl;
    }
  }

  // dot size
  getOnlineDotSize() {
    if (this.size >= 100) {
      return this.size / 4 + 'px';
    }
    return this.size / 3 + 'px';
  }

  getOnlineSmallDotSize() {
    if (this.size >= 100) {
      return this.size / 4 - 10 + 'px';
    }
    return this.size / 3 - 4 + 'px';
  }

  // open profile dialog
  openProfile() {
    if (this.allowOpenProfile) {
      this.avatarService.onOpenProfileDialog(this.userData);
    }
  }

}
