import { Component, OnInit } from '@angular/core';
import { MatDialogRef} from '@angular/material';
import {DefaultThemeComponent} from "../../component/Common/Theme/default-theme/default-theme.component";
import {ThemeService} from "../../service/Theme/theme.service";

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {

  // theme
  public theme: DefaultThemeComponent;

  constructor(private dialog: MatDialogRef<DeleteDialogComponent>, private themeService: ThemeService) { }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });
  }

  onClose(){
    this.dialog.close(false);
  }

  onYes(){
    this.dialog.close(true);
  }
}
