import {AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DarkmodeService} from '../../../../../service/darkmode.service';
import {UserService} from '../../../../../service/User/user.service';
import {GifDialogComponent} from '../../../../../dialog/menu/timeline/gif-dialog/gif-dialog.component';
import {MatDialog} from '@angular/material';
import * as $ from 'jquery';
import {LinkPreviewService} from '../../../../../service/Timeline/link-preview.service';
import {Link, NgxLinkifyjsService, NgxLinkifyOptions} from 'ngx-linkifyjs';
import {UpdatePostDialogComponent} from '../../../../../dialog/menu/timeline/update-post-dialog/update-post-dialog.component';
import {DeleteDialogComponent} from '../../../../../dialog/delete-dialog/delete-dialog.component';
import {TimelineService} from '../../../../../service/Timeline/timeline.service';
import {DefaultThemeComponent} from '../../../../Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../../service/Theme/theme.service';
import {AvatarService} from '../../../../../service/common/avatar.service';
import {ViewListDialogComponent} from '../../../../../dialog/menu/timeline/view-list-dialog/view-list-dialog.component';
import {LikedListDialogComponent} from '../../../../../dialog/menu/timeline/liked-list-dialog/liked-list-dialog.component';
import DatePostUtil from '../../../../../share/date-post-util';
import {MentionService} from '../../../../../service/common/mention.service';
import {Caret} from 'textarea-caret-ts';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {

  @Input() postData;
  @Output() onUpdate = new EventEmitter<any>();

  @Input() data;
  @Input() reload: EventEmitter<any>;
  @Output() refresh: EventEmitter<any> = new EventEmitter();
  @ViewChild('textarea') textArea;

  // veiwed
  public viewedUser;

  // comment
  public admin;
  public openEmoji;

  // file
  public showAllFile;

  // text
  public quoteLimit = 100;
  public textLimit = 300;
  public showText;

  // mention
  public showMentionMenu;
  public mentionMenuPosition;

  // theme
  public theme: DefaultThemeComponent;

  constructor(private datepipe: DatePipe, public darkmodeService: DarkmodeService, private themeService: ThemeService,
              private userService: UserService, private dialog: MatDialog, private timelineService: TimelineService,
              private linkifyService: NgxLinkifyjsService, private linkPreviewService: LinkPreviewService,
              public avatarService: AvatarService, public mentionService: MentionService) { }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    // text read more
    this.showText = this.postData.text.substring(0, this.textLimit);
    this.admin = this.userService.getToken().user;
    this.showAllFile = false;
    this.darkmodeService.isEmojiOpen().subscribe(data => {
      this.openEmoji = data;
    });

    // mention
    this.mentionService.singleMention.subscribe((showMentionMenu) => {
      this.showMentionMenu = showMentionMenu;
    });

    // viewed user
    const user = [];
    this.postData.userView.forEach(vu => {
      user.push(vu);
    });
    this.viewedUser = user.splice(0, user.length - Math.max(user.length - 4, 0));

    const self = this;
    $(document).on('click', function(e) {
      if (e.target.nodeName == 'DIV' || e.target.nodeName.includes('MAT-CARD') || e.target.nodeName.includes('MAT-CHIP')) {
        if (e.target.className != 'emoji-mart') {
          if (e.target.childNodes[0] && e.target.childNodes[0].nodeName == 'svg') {
          } else {
            self.openEmoji = false;
          }
        }
      }
    });
  }

  updateData() {
    this.refresh.emit();
  }

  formatDate(date) {
    // return this.datepipe.transform(date, 'dd MMMM yyyy');
    return DatePostUtil.convertDateToPostDate(date);
  }

  removeEnter(data) {
    return data.replace(/(\r\n|\n|\r)/gm, '');
  }

  // like post
  onLikePost() {
    if (this.postData.isLike) {
      this.timelineService.unlikePost(this.postData._id).subscribe(() => {
        this.timelineService.getPostById(this.postData._id).subscribe(data => {
          this.postData = data;
        });
      }, error => {
        console.log(error);
      });
    } else {
      this.timelineService.likePost(this.postData._id).subscribe(() => {
        this.timelineService.getPostById(this.postData._id).subscribe(data => {
          this.postData = data;
        });
      }, error => {
        console.log(error);
      });
    }
  }

  // comment
  onComment(event, data) {
    if (event.key === 'Enter') {
      if (this.mentionService.lastKeyHaveMenu) {
        event.preventDefault();
        this.mentionService.lastKeyHaveMenu = false;
        return;
      }
      if (data.comment.trim()) {
        const sendData = {
          contentType: 'post',
          message: this.mentionService.convertStringToMentionString(this.removeEnter(data.comment))
        };

        this.timelineService.postCommentByPostId(data._id, sendData).subscribe(data => {
          this.updateData();
        }, error => {
          console.log(error);
        });
      }

      data.comment = '';
      event.target.value = '';
      event.target.style.height = '20px';
    }
  }

  onTypeComment(event) {
    event.target.style.height = '20px';
    event.target.style.height = Math.min(event.target.scrollHeight + 15) + 'px';
  }

  onKeyDown(event) {
    if (this.showMentionMenu) {
      this.postData.comment = this.mentionService.onKeyDown(event);
      if (event.key === 'Enter') {
        this.showMentionMenu = false;
      }
    } else {
      this.mentionService.singleMention.emit(false);
    }
  }

  onReleaseComment(event) {
    if (this.showMentionMenu) {
      this.mentionService.onKeyUp(event);
      if (this.textArea) {
        this.mentionMenuPosition = Caret.getAbsolutePosition(this.textArea.nativeElement);
      }
    }
    if (!event.target.value) {
      event.target.style.height = '20px';
    } else {
      event.target.style.height = '20px';
      event.target.style.height = Math.min(event.target.scrollHeight + 15) + 'px';
    }
  }

  onTextChange(event) {
    this.showMentionMenu = this.mentionService.onTextChange(event);
  }

  onSelectedMention(mention) {
    this.textArea.nativeElement.focus();
    this.postData.comment = this.mentionService.onSelectMention(mention, this.postData.comment);
    this.showMentionMenu = false;
  }

  selectedEmoji(event, data) {
    if (data.comment) {
      data.comment += event.emoji.native;
    } else {
      data.comment = '';
      data.comment += event.emoji.native;
    }
  }

  onGifDialog(data) {
    const sentData = {
      post: data,
      type: 'comment'
    };

    const dia = this.dialog.open(GifDialogComponent, {
      data: sentData,
      maxWidth: '360px',
    });

    dia.afterClosed().subscribe((result) => {
      if (result) {
        this.updateData();
      }
    });
  }

  // link
  hashtag(text) {
    text = this.linkifyService.linkify(text);
    text = this.mentionService.convertMentionStringToViewString(text);
    // return text.replace(/#(\p{L}+)/ugi, `<a class="hashtag${this.isDarkMode === 'dark-mode'?' hashtag-darkmode': ''}" href="#">#$1</a>`);
    text = text.replace(/\n/g, ' <br />');
    return text.replace(/#(\S*)/ugi, `<a class="hashtag" href="#">#$1</a>`);
  }

  // update / delete post
  openUpdatePostDialog() {
    const updatePostDialog = this.dialog.open(UpdatePostDialogComponent, {
      width: '650px',
      data: this.postData
    });
    updatePostDialog.afterClosed().subscribe((updatedPost) => {
      if (updatedPost) {
        this.reload.emit();
        this.onUpdate.emit(updatedPost);
      }
    });
  }

  openDeletePostDialog() {
    const deletePostDialog = this.dialog.open(DeleteDialogComponent, {});
    deletePostDialog.afterClosed().subscribe((isAllowDelete) => {
      if (isAllowDelete) {
        this.timelineService.deleteTimelinePost(this.postData._id).subscribe(res => {
          this.reload.emit();
        }, error => {
          console.log(error);
        });
      }
    });
  }

  onGotoMap() {
    window.open('http://maps.google.com/maps?z=12&t=m&q=loc:' + this.postData.latitude + '+' + this.postData.longitude, '_blank');
  }

  onExpandText() {
    this.showText = this.postData.text;
  }

  // viewed user list
  onViewedUserList() {
    this.dialog.open(ViewListDialogComponent, {
      width: '400px',
      height: '500px',
      data: this.postData._id
    });
  }

  // like list
  onLikedUserList() {
    this.dialog.open(LikedListDialogComponent, {
      width: '400px',
      height: '500px',
      data: this.postData._id
    });
  }

  // boolean
  isTextQuote() {
    return (this.postData.text.length <= this.quoteLimit && this.postData.text.length > 0) &&
      (!this.linkPreviewService.checkIfUrl(this.postData.text)) &&
      (!this.postData.text.includes('#')) &&
      (!this.postData.text.includes('@')) &&
      (this.postData.media.length <= 0) &&
      (this.postData.metaPreview.length <= 0);
  }

  haveMap() {
    if (this.postData.metaPreview && !this.linkPreviewService.checkIfUrl(this.postData.text)) {
      const postWithMap = this.postData.metaPreview.find((post) => {
        return post.type === 'map';
      });
      if (postWithMap) {
        return postWithMap.shouldPreview;
      }
    }
    return false;
  }

  textNotUrl() {
    if (this.linkPreviewService.checkIfUrl(this.postData.text) &&
      (this.linkifyService.linkify(this.postData.text).match(/<a/g) && this.linkifyService.linkify(this.postData.text).match(/<a/g).length)) {
      return false;
    } else {
      return true;
    }
  }
}
