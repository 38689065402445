import { Injectable } from '@angular/core';
import {BaseService} from '../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(private baseService: BaseService) { }

  // job data
  addNewJob(data) {
    return this.baseService.post('jobs', data);
  }

  getAllJob() {
    return this.baseService.get('jobs');
  }

  updateJob(id, data) {
    return this.baseService.put('jobs/' + id, data);
  }
}
