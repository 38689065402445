import {EventEmitter, Injectable} from '@angular/core';
import {BaseService} from './base.service';
import * as $ from 'jquery';
import {AvatarService} from './avatar.service';
import {StaffDialogComponent} from '../../dialog/menu/staff/staff-dialog/staff-dialog.component';
import {MatDialog} from '@angular/material';
import {UserService} from '../User/user.service';

@Injectable({
  providedIn: 'root'
})
export class MentionService {

  // mention
  public selectedMention = 0;
  public haveKeyword = false;
  public mentionList = [];
  public saveMentionList = [];

  public lastKeyHaveMenu = false;
  public singleMention: EventEmitter<any> = new EventEmitter();

  constructor(private baseService: BaseService, private avatarService: AvatarService, private dialog: MatDialog,
              private userService: UserService) {
  }

  // mention Input
  onKeyUp(event) {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      $('.mention-menu').animate({
        scrollTop: $('.mention-list.focus')[0].offsetTop
      }, 100);
    }
  }

  onKeyDown(event) {
    const text = event.srcElement.value;
    if (event.key === 'ArrowDown') {
      if (this.selectedMention !== this.mentionList.length - 1) {
        this.selectedMention++;
      } else {
        this.selectedMention = 0;
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (this.selectedMention !== 0) {
        this.selectedMention--;
      } else {
        this.selectedMention = this.mentionList.length - 1;
      }
    } else if (event.key === 'Enter') {
      event.preventDefault();
      this.lastKeyHaveMenu = true;
      return this.onSelectMention(null, text);
    }
    return text;
  }

  onTextChange(event) {
    const txtPost = event;
    this.selectedMention = 0;
    if (!txtPost) {
      return false;
    }
    const wordArray = txtPost.split(/\n| /);
    const lastWord = wordArray[wordArray.length - 1];
    if (lastWord.charAt(0) === '@') {
      const word = lastWord.substr(1);
      this.haveKeyword = word.length > 2;
      if (word) {
        this.getMentionList(word).subscribe((mention: any) => {
          this.mentionList = mention;
        });
      }
      return true;
    }
    return false;
  }

  onSelectMention(user, txtPost) {
    if (!txtPost) { txtPost = ''; }
    let wordArray = txtPost.split(/( )|(\n)/);
    wordArray = this.cleanNullData(wordArray);
    wordArray.pop();
    if (wordArray.length > 0) {
      txtPost = wordArray.reduce((a, b) => a + b);
    } else {
      txtPost = '';
    }
    if (user) {
      txtPost += '@' + user.firstName + ' ' + user.lastName + ' ';
      this.saveMentionList.push({
        oldText: '@' + user.firstName + ' ' + user.lastName,
        newText: '@[' + user._id  + ':' + user.firstName + ' ' + user.lastName + ']'
      });
    } else {
      const mentionUser = this.mentionList[this.selectedMention];
      txtPost += '@' + mentionUser.firstName + ' ' + mentionUser.lastName + ' ';
      this.saveMentionList.push({
        oldText: '@' + mentionUser.firstName + ' ' + mentionUser.lastName,
        newText: '@[' + mentionUser._id  + ':' + mentionUser.firstName + ' ' + mentionUser.lastName + ']'
      });
    }
    return txtPost;
  }

  // mention converter
  convertStringToMentionString(text) {
    this.saveMentionList.forEach((user) => {
      text = text.replace(user.oldText, user.newText);
    });
    return text;
  }

  convertMentionStringToString(str) {
    const tmpA = '\\@\\[(\\S*)\\:(\\S*)(\\s)(\\S*)\\]';
    const regex = new RegExp(tmpA, 'g');
    return str.replace(regex, '@$2 $4');
  }

  convertMentionStringToViewString(str) {
    const tmpA = '\\@\\[(\\S*)\\:(\\S*)(\\s)(\\S*)\\]';
    const regex = new RegExp(tmpA, 'g');
    return str.replace(regex, '<a href="$1" class="name-mention">$2 $4</a>');
  }

  // helper function
  resetData() {
    this.lastKeyHaveMenu = false;
  }

  cleanNullData(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  onLoseFocus() {
    setTimeout(() => {
      this.singleMention.emit(false);
    }, 200);
  }

  /////////////
  // NOT USE //
  /////////////

  // get mention user by search key word
  getMentionList(text) {
    const sendData = { typingText: text };
    // if (!sendData.typingText || sendData.typingText.length < 2) { return; }
    if (!sendData.typingText) { return; }
    return this.mentionUser(sendData);
  }

  // get the search key word for mention in string
  getMentionKeyWord(text) {
    const mentionInText = text.split(' ');
    const lastWord = mentionInText[mentionInText.length - 1];
    if (lastWord.charAt(0) === '@') {
      return lastWord.substr(1);
    } else {
      return '';
    }
  }

  // click on mention
  onOpenProfile(event) {
    event.preventDefault();
    const id = event.target.getAttribute('href');
    if (id && !id.match(/[#]/i)) {
      this.avatarService.onOpenProfileDialogViaId(id);
    } else if (id && id.match(/[#]/i)) {
      // this.conversationSharedService.hashTagData = id;
    }
  }

  openPeopleInformation(event) {
    event.preventDefault();
    const id = event.target.getAttribute('href');
    this.userService.getUserById(id).subscribe((userData) => {
      this.dialog.open(StaffDialogComponent, {
        data: userData,
        width: '500px'
      });
    });
  }

  // mention api
  mentionUser(data) {
    return this.baseService.post('api/post/mention', data);
  }
}
