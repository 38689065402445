import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {SettingService} from '../../../service/Setting/setting.service';
import {DefaultThemeComponent} from '../../../component/Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../service/Theme/theme.service';

@Component({
  selector: 'app-edit-company-node-dialog',
  templateUrl: './edit-company-node-dialog.component.html',
  styleUrls: ['./../edit-dialog/edit-dialog.component.css']
})
export class EditCompanyNodeDialogComponent implements OnInit {

  // form
  public editUserForm: FormGroup;
  public oldOrganizationName;

  // theme
  public theme: DefaultThemeComponent;

  constructor(private dialog: MatDialogRef<EditCompanyNodeDialogComponent>, private settingService: SettingService,
              @Inject(MAT_DIALOG_DATA) public organizationName, private themeService: ThemeService) {
  }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    // init form
    this.editUserForm = new FormGroup({
      name: new FormControl('name', [Validators.required])
    });

    // set form
    this.oldOrganizationName = this.organizationName;
    this.editUserForm.get('name').setValue(this.organizationName);
  }

  public hasError = (controlName, errorName: string) => {
    return this.editUserForm.controls[controlName].hasError(errorName);
  };

  onClose() {
    this.dialog.close(false);
  }

  onSubmit() {
    if (this.editUserForm.valid) {
      const data = {
        organisationName: this.editUserForm.get('name').value
      };

      this.settingService.updateSetting(data).subscribe((response: any) => {
        this.dialog.close(response.organisationName);
      });
    }
  }

  // bool
  isAllowChangeName() {
    return this.editUserForm.valid && (this.oldOrganizationName !== this.editUserForm.get('name').value);
  }

}
