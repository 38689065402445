import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {UserService} from '../../../../service/User/user.service';
import * as $ from 'jquery';
import {MatDialogRef, MatSnackBar} from '@angular/material';
import {DarkmodeService} from '../../../../service/darkmode.service';
import {UploadService} from '../../../../service/common/upload.service';
import {TimelineUploadService} from '../../../../service/Timeline/timeline-upload.service';
import {ErrorDialogComponent} from '../../../organizationchart/error-dialog/error-dialog.component';
import {DomSanitizer} from '@angular/platform-browser';
import {LinkPreviewService} from '../../../../service/Timeline/link-preview.service';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {DefaultThemeComponent} from '../../../../component/Common/Theme/default-theme/default-theme.component';
import {TimelineService} from '../../../../service/Timeline/timeline.service';
import {MentionService} from '../../../../service/common/mention.service';
import { Caret } from 'textarea-caret-ts';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['../../../../component/dashboard/menu/timeline/timeline.component.css', './input-dialog.component.css']
})
export class InputDialogComponent implements OnInit, AfterViewInit {

  public isDarkMode;

  // preview Data
  public mediaPreview = [];
  public filePreview = [];

  // Data before post
  private pictureData = [];
  private videoData = [];
  private fileData = [];
  public loading = false;

  /// post thing data
  public txtPost = '';
  public quoteLimit = 100;

  // mention
  public showMentionMenu;
  public mentionMenuPosition;

  public fileType;
  public gif;
  public openEmoji;

  public progress;
  public allProcess: number;

  public uploader: FileUploader;
  private uploadUrl;
  private uploadClouldName;

  // link preview
  public typingTimer;
  public inputLinkPreview;
  public showLinkPreview;
  public linkLoading;

  // who can see your post
  public selectedShow;

  // auto open file input
  public openFile: EventEmitter<any> = new EventEmitter();
  @ViewChild('fileInput') fileInput;
  @ViewChild('imageInput') imageInput;
  @ViewChild('videoInput') videoInput;

  public screen = 'input';
  public admin;
  public theme: DefaultThemeComponent;

  @ViewChild('Text') textArea;
  @ViewChild('tooh') tooh;

  constructor(private userService: UserService, private dialog: MatDialogRef<InputDialogComponent>,
              private darkmodeService: DarkmodeService, private uploadService: UploadService,
              public timelineUploadService: TimelineUploadService, private _snackBar: MatSnackBar,
              private sanitizer: DomSanitizer, private linkPreviewService: LinkPreviewService,
              private themeService: ThemeService, private timelineService: TimelineService,
              public mentionService: MentionService) {
  }

  ngOnInit() {
    //  Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.admin = this.userService.getToken().user;
    if (this.textArea) {
      setTimeout(() => {
        this.textArea.nativeElement.focus();
      }, 500);
    }

    // mention
    this.mentionService.singleMention.subscribe((showMentionMenu) => {
      this.showMentionMenu = showMentionMenu;
    });

    this.isDarkMode = this.darkmodeService.getLocalDarkMode();
    const self = this;
    this.openEmoji = false;

    // upload media
    this.uploadClouldName = this.uploadService.uploadClouldName;
    this.uploadUrl = this.uploadService.uploadUrl;

    this.allProcess = 0;
    this.progress = 0;

    const uploaderOptions: FileUploaderOptions = {
      url: this.uploadUrl,
      autoUpload: false,
      isHTML5: true,
      removeAfterUpload: true,
      maxFileSize: 10 * 1024 * 1024,
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    // who can see your post
    this.selectedShow = '1';

    this.uploader = new FileUploader(uploaderOptions);
    this.getDataFromTimeline();

    // link
    this.showLinkPreview = false;
    this.linkLoading = false;
    this.lookingForLinkData();

    // auto open when click on fake input
    this.openFile.subscribe(data => {
      if (data === 'file') {
        this.fileInput.nativeElement.click();
      } else if (data === 'image') {
        this.imageInput.nativeElement.click();
      } else if (data === 'video') {
        this.videoInput.nativeElement.click();
      } else if (data === 'emoji') {
        this.openEmoji = !this.openEmoji;
      } else if (data === 'visibility') {
        this.screen = 'visibility';
      }
    });

    // build media for use in tag, filename...
    this.uploader.onBuildItemForm = (fileItem, form) => {
      let fileType = fileItem.file.type.split('/')[0];

      if (fileType !== 'image' && fileType !== 'video') {
        fileType = 'file';
        fileItem.file.type = 'file';
        form.append('upload_preset', this.uploadService.uploadFilePreset);
      } else if (fileType === 'image') {
        form.append('upload_preset', this.uploadService.uploadImagePreset);
      } else if (fileType === 'video') {
        const isLand = this.isVideoLand(URL.createObjectURL(fileItem._file));
        if (isLand) {
          form.append('upload_preset', this.uploadService.uploadVideoLandPreset);
        } else {
          form.append('upload_preset', this.uploadService.uploadVideoPortPreset);
        }
      }

      form.append('file', fileItem);
      form.append('tags', fileType);
      form.append('public_id', 'wallstreet_' + this.admin._id + '_' + new Date().getTime());

      fileItem.withCredentials = false;
      this.progress = 0;
      return {fileItem, form};
    };

    // validate file if it too many
    this.uploader.onAfterAddingAll = (fileItem) => {
      if (this.uploader.queue.length > 10) {
        this.uploader.clearQueue();
        this.filePreview = [];
        this.mediaPreview = [];
        this._snackBar.openFromComponent(ErrorDialogComponent, {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: 'fileTooMany'
        });
        this.allProcess = 0;
      }
      this.timelineUploadService.uploader = this.uploader;
    };

    // validate file post with image/video
    this.uploader.onAfterAddingFile = fileItem => this.addFile(fileItem);

    // validate file is too large
    this.uploader.onWhenAddingFileFailed = (filter: any) => {
      if (filter.size / (1024 * 1024) > 10) {
          this._snackBar.openFromComponent(ErrorDialogComponent, {
            duration: 4000,
            verticalPosition: 'top',
            horizontalPosition: 'end',
            data: 'fileTooBig'
          });
      }
    };

    // each media after upload
    this.uploader.onCompleteItem = (item, response, status, headers) => {
      const res = JSON.parse(response);
      const getData = this.uploadService.getClouldinaryUrl(res);
      if (res.tags[0] === 'image') {
        this.pictureData.push({
          url: getData.mediaTransformUrl,
          thumbnail: getData.mediaTransformUrl,
          width: getData.width,
          height: getData.height
        });
      } else if (res.tags[0] === 'video') {
        this.videoData.push({
          url: getData.mediaTransformUrl,
          thumbnail: getData.videoThm,
          duration: res.duration,
          width: getData.width,
          height: getData.height
        });
      } else {
        this.fileData.push({
          url: res.secure_url,
          thumbnail: res.secure_url,
          format: this.convertFileToStandard(item.file),
          mediaName: item.file.name,
          size: res.bytes
        });
      }
    };

    // when all the media completed upload
    this.uploader.onCompleteAll = () => {
        const data = {
          text: this.txtPost,
          visibility: parseInt(this.selectedShow, 10),
          media: []
        };

        if (this.pictureData.length > 0 || this.videoData.length > 0 || this.fileData.length > 0) {
          this.videoData.forEach((f, i) => {
            data.media.push({
              index: i,
              type: 'video',
              url: f.url,
              thumbnail: f.thumbnail,
              duration: f.duration,
              width: f.width,
              height: f.height
            });
          });

          this.pictureData.forEach((f, i) => {
            data.media.push({
              index: i + this.videoData.length,
              type: 'image',
              url: f.url,
              thumbnail: f.thumbnail,
              width: f.width,
              height: f.height
            });
          });

          this.fileData.forEach((f, i) => {
            data.media.push({
              index: i,
              type: 'file',
              url: f.url,
              thumbnail: f.url,
              size: f.size,
              mediaName: f.mediaName,
              format: f.format
            });
          });
        } else {
          delete data.media;
        }

        this.timelineService.addTimelinePost(data).subscribe(
          (data) => {
            this.loading = false;
            this.timelineUploadService.onClearData();
            this.dialog.close(true);
          }, (error) => {
            console.log(error);
          }
        );
    };

    this.uploader.onProgressAll = (progress) => {
      this.progress = progress;
    };

    $(document).on('click', function(e) {
      if (e.target.nodeName == 'DIV' || e.target.nodeName.includes('MAT-CARD') || e.target.nodeName.includes('MAT-CHIP')) {
        if (e.target.className != 'emoji-mart') {
          if (e.target.childNodes[0] && e.target.childNodes[0].nodeName == 'svg') {
          } else {
            self.openEmoji = false;
          }
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.updateInputHeight();
  }

  // check if user put image/video with file
  addFile(fileItem) {
    const fileType = fileItem.file.type.split('/')[0];
    this.allProcess++;
    if (fileType != 'image' && fileType != 'video') {
      if (this.mediaPreview.length == 0) {
        // console.log(fileItem.file);
        this.filePreview.push({
          mediaName: fileItem.file.name,
          format: this.convertFileToStandard(fileItem.file)
        });
      } else {
        this._snackBar.openFromComponent(ErrorDialogComponent, {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: 'fileWithImageVideo'
        });
        this.uploader.queue[this.uploader.queue.length - 1].remove();
      }
    } else {
      if (this.filePreview.length == 0) {
        this.mediaPreview.push({url: this.showPictureOffline(fileItem), type: fileType});
      } else {
        this._snackBar.openFromComponent(ErrorDialogComponent, {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: 'fileWithImageVideo'
        });
        this.uploader.queue[this.uploader.queue.length - 1].remove();
      }
    }

    this.timelineUploadService.filePreview = this.filePreview;
    this.timelineUploadService.mediaPreview = this.mediaPreview;
  }

  selectedEmoji(event) {
    if (!this.txtPost) {
      this.txtPost = '';
    }
    this.txtPost += event.emoji.native;
  }

  onClearMedia(i) {
    this.mediaPreview.splice(i, 1);
    this.uploader.queue[i].remove();
    if (this.mediaPreview.length <= 0 && this.filePreview.length <= 0) {
      this.lookingForLinkData();
    }
  }

  onClearFile(i) {
    this.filePreview.splice(i, 1);
    this.uploader.queue[i].remove();
    if (this.mediaPreview.length <= 0 && this.filePreview.length <= 0) {
      this.lookingForLinkData();
    }
  }

  // mention
  onKeyup(event) {
    if (this.showMentionMenu) {
      this.mentionService.onKeyUp(event);
      if (this.textArea) {
        this.mentionMenuPosition = Caret.getAbsolutePosition(this.textArea.nativeElement);
      }
    }
    this.updateInputHeight();
    clearTimeout(this.typingTimer);
    if (this.mediaPreview.length <= 0 && this.filePreview.length <= 0) {
      this.typingTimer = setTimeout(() => {
        this.lookingForLinkData();
      }, 1000);
    }
  }

  onKeyDown(event) {
    if (this.showMentionMenu) {
      this.txtPost = this.mentionService.onKeyDown(event);
      if (event.key === 'Enter') {
        this.showMentionMenu = false;
      }
    } else {
      this.mentionService.singleMention.emit(false);
    }

  }

  onTextChange(event) {
    this.showMentionMenu = this.mentionService.onTextChange(event);
  }

  onSelectedMention(mention) {
    this.textArea.nativeElement.focus();
    this.txtPost = this.mentionService.onSelectMention(mention, this.txtPost);
    this.showMentionMenu = false;
  }

  // update input height
  updateInputHeight() {
    if (this.textArea) {
      this.textArea.nativeElement.style.height = '';
      this.textArea.nativeElement.style.height = Math.min(this.textArea.nativeElement.scrollHeight + 17) + 'px';
      this.timelineUploadService.height = Math.min(this.textArea.nativeElement.scrollHeight) + 'px';
    }
    if (this.tooh) {
      this.tooh.nativeElement.scrollTop = this.tooh.nativeElement.scrollHeight;
    }
    this.timelineUploadService.text = this.txtPost;
  }

  async lookingForLinkData() {
    if (this.txtPost) {
      this.showLinkPreview = false;
      const metaData = await this.linkPreviewService.requestLinkPreview(this.txtPost, this);
      if (metaData) {
        this.inputLinkPreview = {metaPreview: [metaData]};
        this.showLinkPreview = true;
      }
    } else {
      this.showLinkPreview = false;
    }
    this.linkLoading = false;
  }

  async onPost() {
    if (!this.checkDisablePost()) {
      if (this.mediaPreview.length > 0 || this.filePreview.length > 0) {
        this.uploader.uploadAll();
        this.loading = true;
      } else if (this.txtPost) {
        this.loading = true;
        const sendData = {
          text: this.txtPost,
          visibility: parseInt(this.selectedShow, 10),
          metaPreview: []
        };

        if (this.linkPreviewService.checkIfUrl(this.txtPost)) {
          await this.linkPreviewService.requestMultipleLinkPreview(this.txtPost).then((metaPreview: any) => {
            sendData.metaPreview = metaPreview;
          }).catch(e => {
            console.log(e);
          });
        } else {
          delete sendData.metaPreview;
        }

        // apply mention text
        sendData.text = this.mentionService.convertStringToMentionString(sendData.text);

        this.timelineService.addTimelinePost(sendData).subscribe(
          (data) => {
            this.timelineUploadService.onClearData();
            document.getElementById('the-text').style.height = '20px';
            this.dialog.close(true);
          }, (error) => {
            console.log(error);
          }
        );
      }
    }
  }

  showPictureOffline(input) {
    return this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(input._file)));
  }

  convertFileToStandard(file) {
    switch (file.type) {
      case 'application/x-zip-compressed':
        return 'zip';

      case 'application/pdf':
        return 'pdf';

      case 'text/plain':
        return 'txt';

      default:
        return file.name.split('.')[1] || 'file';
    }
  }

  getDataFromTimeline() {
    this.txtPost = this.timelineUploadService.text;
    if (this.textArea) {
      this.textArea.nativeElement.style.height = this.timelineUploadService.height;
    }
    if (this.timelineUploadService.uploader) {
      this.uploader = this.timelineUploadService.uploader;
    }
    this.uploader.queue.forEach(f => {
      this.addFile(f);
    });
    this.selectedShow = this.timelineUploadService.typeSee;
  }

  // check if video is land or port
  async isVideoLand(src) {
    const video: any = await this.getVideoWidthHeight(src);
    return video.width > video.height;
  }

  getVideoWidthHeight(src) {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => resolve({ width: video.videoWidth, height: video.videoHeight });
      video.onerror = reject;
      video.src = src;
    });
  }

  // Visibility
  onSelectVisibility(num) {
    this.selectedShow = num;
    this.timelineUploadService.typeSee = num;
    this.screen = 'input';
  }

  // bool
  checkMedia() {
    return this.mediaPreview && (this.mediaPreview.length > 0 || this.filePreview.length > 0);
  }

  isTextQuote() {
    return (this.timelineUploadService.text && this.timelineUploadService.text.length > 0) &&
      (this.timelineUploadService.text.length <= this.quoteLimit) &&
      !this.linkPreviewService.checkIfUrl(this.timelineUploadService.text) &&
      !this.timelineUploadService.text.includes('#') && !this.timelineUploadService.text.includes('@') &&
      !this.checkMedia();
  }

  // check the button post is disable
  checkDisablePost() {
    // return true mean can't post
    return !(((this.mediaPreview.length > 0 || this.filePreview.length > 0 || ((this.txtPost && this.txtPost.trim()) &&
      this.txtPost.replace(/(\r\n|\n|\r)/gm, ''))) && !this.loading));
  }
}
