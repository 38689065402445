import { Injectable } from '@angular/core';
import {BaseService} from '../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private baseService: BaseService) { }

  /// group data
  addNewGroup(data) {
    return this.baseService.post('api/pro/group', data);
  }

  getAllGroup() {
    return this.baseService.get('groups');
  }

  updateGroup(id, data) {
    return this.baseService.put('groups/' + id, data);
  }

  getGroupByParticipants(id) {
    return this.baseService.get('api/pro/group/authorized/' + id);
  }

  getGroupById(id) {
    return this.baseService.get('groups/' + id);
  }

  // conversation
  getConversationByGroupId(id) {
    return this.baseService.get('api/conversationbyconvid/' + id);
  }

  updateConversation(id, data) {
    return this.baseService.put('conversations/' + id, data);
  }
}
