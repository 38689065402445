import {EventEmitter, Injectable, Output} from '@angular/core';
import {DarkThemeComponent} from '../../component/Common/Theme/dark-theme/dark-theme.component';
import {DefaultThemeComponent} from '../../component/Common/Theme/default-theme/default-theme.component';
import {RedThemeComponent} from '../../component/Common/Theme/red-theme/red-theme.component';

enum THEME {
  DARKMODE = 'dark-mode',
  LIGHTMODE = 'light-mode',
  REDMODE = 'red-mode'
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private mode;
  @Output() themeListener: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.mode = this.getLocalTheme() ? this.getLocalTheme() : THEME.LIGHTMODE;
  }
  public setTheme(mode) {
    this.mode = mode;
    this.setLocalTheme(mode);
    this.themeListener.emit(this.getComponent());
  }

  public getTheme() { return this.mode; }
  public isDarkMode() { return this.mode === THEME.DARKMODE; }
  public isLightMode() { return this.mode === THEME.LIGHTMODE; }
  public isRedMode() { return this.mode === THEME.REDMODE; }

  // get theme class
  public getComponent() {
    if (this.isDarkMode()) { return new DarkThemeComponent(); } else
      if (this.isRedMode()) { return new RedThemeComponent(); }
    return new DefaultThemeComponent();
  }

  // localstorage
  setLocalTheme(theme) { localStorage.setItem('theme', theme); }
  getLocalTheme() { return localStorage.getItem('theme'); }
}
