import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatDialogRef,
  MatSelect
} from '@angular/material';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {AbstractControl, FormControl, ValidatorFn, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {UploadService} from '../../../../service/common/upload.service';
import {UserService} from '../../../../service/User/user.service';
import {DefaultThemeComponent} from '../../../../component/Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {SettingService} from '../../../../service/Setting/setting.service';
import {GroupService} from '../../../../service/Group/group.service';

@Component({
  selector: 'app-add-group-dialog',
  templateUrl: './add-group-dialog.component.html',
  styleUrls: ['./add-group-dialog.component.css']
})
export class AddGroupDialogComponent implements OnInit, AfterViewInit {

  public pageLoaded;
  public userData;
  public selectedOwner: string;
  public owner;

  public uploader: FileUploader;
  private uploadPreset;
  private uploadUrl;
  private uploadClouldName;
  public picture;
  public doneProcess;

  // cover picture
  public profileCover;

  public theme: DefaultThemeComponent;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredMembers: Observable<string[]>;
  filteredAdmins: Observable<string[]>;
  members: string[] = [];
  admins: string[] = [];
  allMembers: string[] = [];
  allAdmins: string[] = [];

  @ViewChild('memberInput') memberInput: ElementRef<HTMLInputElement>;
  @ViewChild('adminInput') adminInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoAdmin') matAutocompleteAdmin: MatAutocomplete;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('ownerDOM') ownerDOM: MatSelect;
  @ViewChild('memberDOM') memberDOM: ElementRef;
  @ViewChild('adminDOM') adminDOM: ElementRef;
  @ViewChild('groupNameDOM') groupNameDOM: ElementRef;

  groupName = new FormControl('', [Validators.required]);
  memberCtrl = new FormControl('', [this.chipNumber(this.members.length)]);
  adminCtrl = new FormControl('', [this.chipNumber(this.admins.length)]);

  constructor(private dialog: MatDialogRef<AddGroupDialogComponent>,
              private uploadService: UploadService, private userService: UserService, private themeService: ThemeService,
              private settingService: SettingService, private groupService: GroupService) {
    this.userService.getAllUser().subscribe(data => {
      this.userData = data;
      this.userData.forEach((f) => {
        if (f.isDeleted !== 'true') {
          this.allMembers.push(f.id);
          this.allAdmins.push(f.id);
        }
      });
      this.allMembers = this.allMembers.filter(val => !this.members.includes(val));
      this.allAdmins = this.allAdmins.filter(val => !this.admins.includes(val));
      this.onUpdateData();
    }, error => {
      console.log(error);
    });
  }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.doneProcess = true;
    this.uploadClouldName = this.uploadService.uploadClouldName;
    this.uploadUrl = this.uploadService.uploadUrl;
    this.uploadPreset = this.uploadService.uploadProfilePreset;

    this.getCoverPicture();

    const uploaderOptions: FileUploaderOptions = {
      url: this.uploadUrl,
      autoUpload: false,
      isHTML5: true,
      removeAfterUpload: true,
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onBuildItemForm = (fileItem, form) => {
      const fileType = fileItem.file.type.split('/')[0];
      form.append('upload_preset', this.uploadPreset);
      form.append('file', fileItem);
      form.append('tag', fileType);
      form.append('public_id', 'group_' + new Date().getTime());

      fileItem.withCredentials = false;
      this.doneProcess = false;
      return {fileItem, form};
    };

    this.uploader.onCompleteItem = (item, response) => {
      const res = JSON.parse(response);

      const getData = this.uploadService.getClouldinaryUrl(res);

      this.picture = getData.mediaTransformUrl;
      this.sentCreateGroup();
      this.doneProcess = true;
    };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 1000);
  }

  onFileSelected(event) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.picture = e.target.result;
    };
    reader.readAsDataURL(event[0]);
  }

  onClose() {
    this.dialog.close(false);
  }

  onClearImage() {
    this.picture = null;
  }

  /// add, delete and filter chip
  remove(member: string): void {
    const index = this.members.indexOf(member);

    if (index >= 0) {
      this.allMembers.push(member);
      this.members.splice(index, 1);
      this.allMembers = this.allMembers.filter(val => !this.members.includes(val));
      this.memberCtrl = new FormControl('', [this.chipNumber(this.members.length)]);
    }
  }

  removeAdmin(admins: string): void {
    const index = this.admins.indexOf(admins);

    if (index >= 0) {
      this.allAdmins.push(admins);
      this.admins.splice(index, 1);
      this.allAdmins = this.allAdmins.filter(val => !this.admins.includes(val));
      this.memberCtrl = new FormControl('', [this.chipNumber(this.admins.length)]);
    }
  }

  selectedAdmin(event: MatAutocompleteSelectedEvent): void {
    this.admins.push(event.option.value);
    this.allAdmins = this.allAdmins.filter(val => !this.admins.includes(val));
    this.adminInput.nativeElement.value = '';
    this.adminCtrl.setValue(null);
    this.adminCtrl = new FormControl('', [this.chipNumber(this.admins.length)]);
    this.onUpdateData();
  }

  selectedMember(event: MatAutocompleteSelectedEvent): void {
    this.members.push(event.option.value);
    this.allMembers = this.allMembers.filter(val => !this.members.includes(val));
    this.memberInput.nativeElement.value = '';
    this.memberCtrl.setValue(null);
    this.memberCtrl = new FormControl('', [this.chipNumber(this.members.length)]);
    this.onUpdateData();
  }

  private _filter(value): string[] {
    const filterValue = value.toLowerCase();
    return this.allMembers.filter(member => this.getFullNameById(member).toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterAdmin(value): string[] {
    const filterValue = value.toLowerCase();
    return this.allAdmins.filter(member => this.getFullNameById(member).toLowerCase().indexOf(filterValue) === 0);
  }

  /// update when filter
  onUpdateData() {
    this.filteredMembers = this.memberCtrl.valueChanges.pipe(
      startWith(null),
      map((member: string | null) => member ? this._filter(member) : this.allMembers.slice())
    );

    this.filteredAdmins = this.adminCtrl.valueChanges.pipe(
      startWith(null),
      map((member: string | null) => member ? this._filterAdmin(member) : this.allAdmins.slice())
    );
  }

  /// update data between owner and the member (ex: if they are in owner they won't be in member autocomplete)
  changeOwner() {
    // const key = this.allMembers.indexOf(this.selectedOwner);
    // if(key >= 0){
      // this.allMembers.splice(key, 1);
    // }
    this.allMembers = [];
    this.userData.forEach((f) => {
      // if(f.id != this.selectedOwner){
        this.allMembers.push(f.id);
      // }
    });
    // this.remove(this.selectedOwner);
    this.allMembers = this.allMembers.filter(val => !this.members.includes(val));
    this.onUpdateData();
  }

  /// create new group
  onSave() {
    if (this.groupName.invalid) {
      this.groupNameDOM.nativeElement.focus();
      return;
    } else if (this.memberCtrl.invalid) {
      this.memberDOM.nativeElement.focus();
      return;
    } else if (this.adminCtrl.invalid) {
      this.adminDOM.nativeElement.focus();
    } else {
      if (this.picture) {
        this.uploader.uploadAll();
      } else {
        this.sentCreateGroup();
      }
    }
  }

  sentCreateGroup() {
    const data = {
      name: this.groupName.value,
      owner: this.admins[0],
      participants: this.members,
      picture: this.picture
    };

    if (!this.picture) { delete data.picture; }
    this.groupService.addNewGroup(data).subscribe(() => {
      this.dialog.close(true);
    }, error => {
      console.log(error);
    });
  }

  chipNumber(num: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== undefined && (num <= 0)) {
        return { length: true };
      }
      return null;
    };
  }

  getFullNameById(id) {
    const tmpData = this.userData.find((f) => {
      return f.id === id;
    });
    return tmpData.firstName + ' ' + tmpData.lastName;
  }

  // cover picture
  getCoverPicture() {
    this.settingService.getSetting().subscribe(setting => {
      if (setting[0].contactBackground) {
        this.profileCover = setting[0].contactBackground;
      } else {
        this.profileCover = './assets/image/logo/default_cover_store.jpg';
      }
    }, error => {
      console.log(error);
    });
  }

  getUserById(id) {
    return this.userData.find((user) => {
      return user._id === id;
    });
  }

  // bool
  allowAddGroup() {
    return this.admins.length > 0 && this.members.length > 0 && this.groupName.value;
  }
}
