import {Component, Inject, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material";
import {EmitService} from "../../../../service/emit.service";
import {isCombinedNodeFlagSet} from "tslint";
import {DefaultThemeComponent} from "../../../../component/Common/Theme/default-theme/default-theme.component";
import {ThemeService} from "../../../../service/Theme/theme.service";

@Component({
  selector: 'app-preview-media-dialog',
  templateUrl: './preview-media-dialog.component.html',
  styleUrls: ['./preview-media-dialog.component.css']
})
export class PreviewMediaDialogComponent implements OnInit {

  // media
  public showMedia;
  public showIndex;
  public showType;
  public saveWidth;

  public haveLeft;
  public haveRight;

  // scroll fix
  @ViewChild('theHeight') theHeight;
  @ViewChild('theMedia') theMedia;

  // theme
  public isDarkmode;
  public theme: DefaultThemeComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private emitService: EmitService, private themeService: ThemeService) {}

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.saveWidth = 0;
    this.showIndex = this.data.index;
    this.update();
  }

  onMediaLoaded() {
    this.emitService.setFullHeight(this.theHeight.nativeElement.clientHeight);
    this.theMedia.nativeElement.style.cssText = '';
    const width = this.theMedia.nativeElement.clientWidth;
    if(width > this.saveWidth) {
      this.saveWidth = width;
    }
    if(this.saveWidth > 1500) {
      this.saveWidth = 1500;
    }
    this.theMedia.nativeElement.style.cssText = `width: ${this.saveWidth}px;`;
  }

  update() {
    this.showMedia = this.data.media[this.showIndex].url;
    this.showType = this.data.media[this.showIndex].type;
    this.haveLeft = this.showIndex != 0;
    this.haveRight = this.showIndex < this.data.media.length-1;
  }

  changeViewMedia(dir){
    this.showIndex+=dir;
    this.update();
  }
}
