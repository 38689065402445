import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material";
import {DatePipe} from "@angular/common";
import {UserService} from "../../../../../service/User/user.service";
import * as $ from 'jquery';
import {EmitService} from "../../../../../service/emit.service";
import {DarkmodeService} from "../../../../../service/darkmode.service";
import {GifDialogComponent} from "../../gif-dialog/gif-dialog.component";
import {TimelineService} from "../../../../../service/Timeline/timeline.service";
import {ThemeService} from "../../../../../service/Theme/theme.service";
import {DefaultThemeComponent} from "../../../../../component/Common/Theme/default-theme/default-theme.component";
import {NgxLinkifyjsService} from "ngx-linkifyjs";
import {MentionService} from "../../../../../service/common/mention.service";

@Component({
  selector: 'app-right-preview-media-dialog',
  templateUrl: './right-preview-media-dialog.component.html',
  styleUrls: ['./right-preview-media-dialog.component.css']
})
export class RightPreviewMediaDialogComponent implements OnInit, AfterViewChecked {

  @Input() data;
  @Input() isDarkmode;

  @Output() refresh: EventEmitter<any> = new EventEmitter();

  @ViewChild('theScroll') theScroll;
  @ViewChild('inputDiv') inputDiv;
  @ViewChild('textarea') textArea;

  private theHeight;

  // text
  public textLimit = 300;
  public showText;

  // mention
  public showMentionMenu;

  // comment
  public commentData;
  public commentLength;
  public openEmoji;

  // tmpData
  public tmpData;
  public admin;

  // theme
  public theme: DefaultThemeComponent;

  constructor(private dialog: MatDialogRef<RightPreviewMediaDialogComponent>, private openDialog: MatDialog,
              private datepipe: DatePipe, private userService: UserService, private emitService: EmitService,
              public darkmodeService: DarkmodeService, private timelineService: TimelineService,
              private themeService: ThemeService, private linkifyService: NgxLinkifyjsService,
              public mentionService: MentionService) { }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    // mention
    this.mentionService.singleMention.subscribe((showMentionMenu) => {
      this.showMentionMenu = showMentionMenu;
    });

    this.admin = this.userService.getToken().user;
    this.showText = this.data.text.substring(0, this.textLimit);
    this.darkmodeService.isEmojiOpen().subscribe(data => {
      this.openEmoji = data;
    });
    this.updateData();
    this.update();

    const self = this;
    $(document).on('click', function(e) {
      if(e.target.nodeName == "DIV" || e.target.nodeName.includes('MAT-CARD') || e.target.nodeName.includes('MAT-CHIP')){
        if(e.target.className != "emoji-mart") {
          if(e.target.childNodes[0] && e.target.childNodes[0].nodeName == 'svg') {
          }else{
            self.openEmoji = false;
            setTimeout(()=> {
              self.update();
            }, 1);
          }
        }
      }
    });

    this.emitService.getFullHeight().subscribe(data => {
      this.theHeight = data;
      this.update();
    });
  }

  update() {
    if(this.theHeight) {
      const inputHeight = this.inputDiv.nativeElement.clientHeight;
      let height = this.theHeight - inputHeight;
      if(height < 0) height = 0;
      this.theScroll.nativeElement.style.cssText = `max-height: ${height}px; height: 100%;`;
    }
  }

  // profile, text and close
  formatDate(date){
    return this.datepipe.transform(date, 'dd MMMM yyyy');
  }

  onClose(){
    this.dialog.close();
  }

  // all comment
  removeEnter(data) {
    return data.replace(/(\r\n|\n|\r)/gm, "");
  }

  onComment(event, data) {
    if(event.key === 'Enter') {
      if (this.mentionService.lastKeyHaveMenu) {
        event.preventDefault();
        this.mentionService.lastKeyHaveMenu = false;
        return;
      }

      if (data.comment.trim()) {
        const sendData = {
          contentType: 'post',
          message: this.mentionService.convertStringToMentionString(data.comment.trim())
        };

        this.timelineService.postCommentByPostId(data.postData._id, sendData).subscribe(data => {
          this.updateData();
        }, error => {
          console.log(error);
        });
      }

      data.comment = '';
      event.target.value = '';
      event.target.style.height = '20px';
    }
  }

  checkMoreComment() {
    if(this.commentData) {
      if (this.commentLength - this.commentData.length > 0) {
        return true;
      }
      return false;
    }
  }

  onMoreComment() {
    if(this.commentData) {
      if (this.commentLength - this.commentData.length > 0) {
        this.timelineService.getMoreComment(this.data.postData._id, this.commentData[0]._id).subscribe(data => {
          this.tmpData = data;

          this.tmpData.forEach(f => {
            if(f.totalSubComments && f.totalSubComments > 2) {
              f.subComments = f.subComments.reverse();
            }
          });

          this.commentData = this.tmpData.concat(this.commentData);
        }, error => {
          console.log(error);
        });
      }
    }
  }

  updateData() {
    this.refresh.emit();
  }

  limitComment() {
    if(this.commentData) {
      if(this.commentData.length > 2) {
        this.commentData.splice(0, this.commentData.length-2);
      }
    }
  }

  // input comment
  onTypeComment(event) {
    event.target.style.height = '20px';
    event.target.style.height = Math.min(event.target.scrollHeight+15) + "px";
    this.update();
  }

  onTextChange(event) {
    this.showMentionMenu = this.mentionService.onTextChange(event);
  }

  onReleaseComment(event) {
    if (this.showMentionMenu) {
      this.mentionService.onKeyUp(event);
    }

    if(!event.target.value) {
      event.target.style.height = '20px';
    } else {
      event.target.style.height = '20px';
      event.target.style.height = Math.min(event.target.scrollHeight+15) + "px";
    }
    this.update();
  }

  onKeyDown(event) {
    if (this.showMentionMenu) {
      this.data.comment = this.mentionService.onKeyDown(event);
      if (event.key === 'Enter') {
        this.showMentionMenu = false;
      }
    } else {
      this.mentionService.singleMention.emit(false);
    }
  }

  onSelectedMention(mention) {
    this.textArea.nativeElement.focus();
    this.data.comment = this.mentionService.onSelectMention(mention, this.data.comment);
    this.showMentionMenu = false;
  }

  selectedEmoji(event, data){
    if(data.comment) {
      data.comment += event.emoji.native;
    } else {
      data.comment = '';
      data.comment += event.emoji.native;
    }
  }

  onGifDialog(data) {
    const sentData = {
      post: data.postData,
      type: 'comment'
    };

    const dia = this.openDialog.open(GifDialogComponent, {
      data: sentData,
      maxWidth: '360px',
    });

    dia.afterClosed().subscribe((result) => {
      if(result) {
        this.updateData();
      }
    });
  }

  hashtag(text) {
    text = this.linkifyService.linkify(text);
    // return text.replace(/#(\p{L}+)/ugi, `<a class="hashtag${this.isDarkMode === 'dark-mode'?' hashtag-darkmode': ''}" href="#">#$1</a>`);
    text = text.replace(/\n/g, ' <br />');
    return text.replace(/#(\S*)/ugi, `<a class="hashtag" href="#">#$1</a>`);
  }

  ngAfterViewChecked(): void {
    this.update();
  }

  onLikePost() {
    if (this.data.postData.media[this.data.index].isLike) {
      this.timelineService.unlikePost(this.data.postData.media[this.data.index]._id).subscribe(() => {
        this.timelineService.getPostById(this.data.postData._id).subscribe(data => {
          this.data.postData = data;
        });
      });
    } else {
      this.timelineService.likePost(this.data.postData.media[this.data.index]._id).subscribe(() => {
        this.timelineService.getPostById(this.data.postData._id).subscribe(data => {
          this.data.postData = data;
        });
      });
    }
  }

  onExpandText() {
    this.showText = this.data.text;
  }

}
