import { Injectable } from '@angular/core';
import {BaseService} from '../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  constructor(private service: BaseService) { }

  // Timeline Post Data
  getAllTimelinePost(nextPost) {
    return this.service.get('api/wall/20?nextPost=' + nextPost);
  }

  addTimelinePost(data) {
    return this.service.post('posts', data);
  }

  updateTimelinePost(postID, data) {
    return this.service.put('posts/' + postID, data);
  }

  deleteTimelinePost(postID) {
    return this.service.delete('posts/' + postID);
  }

  likePost(postID) {
    return this.service.post('api/like/post/' + postID, '');
  }

  unlikePost(postID) {
    return this.service.post('api/unlike/post/' + postID, '');
  }

  getPostById(postID) {
    return this.service.get('posts/' + postID);
  }

  getViewedUserList(postID, nextPost) {
    return this.service.get('view/users/' + postID + '/' + nextPost + '/20');
  }

  getLikeUserList(postID, nextPost) {
    return this.service.get('like/users/' + postID + '/' + nextPost + '/20');
  }

  // Comment
  getCommentByPostId(id) {
    return this.service.get('comments/content/' + id + '/0/2');
  }

  deleteMainComment(commentID) {
    return this.service.delete('comments/' + commentID);
  }

  getMoreComment(postId, lastCommentId) {
    return this.service.get('comments/content/' + postId + '/' + lastCommentId + '/10');
  }

  postCommentByPostId(id, data) {
    return this.service.post('comments/' + id, data);
  }

  postLikeComment(commentId) {
    return this.service.post('api/like/comment/' + commentId, '');
  }

  postUnlikeComment(commentId) {
    return this.service.post('api/unlike/comment/' + commentId, '');
  }

  updateComment(commentID, data) {
    return this.service.put('comments/' + commentID, data);
  }

  // sub comment
  updateSubComment(postID, commentID, subCommentID, data) {
    data.subCommentId = subCommentID;
    return this.service.put('comments/' + postID + '/' + commentID, data);
  }

  postUnlikeSubComment(subCommentId) {
    return this.service.post('api/unlike/subcomment/' + subCommentId, '');
  }

  postLikeSubComment(subCommentId) {
    return this.service.post('api/like/subcomment/' + subCommentId, '');
  }

  postSubCommentByCommentId(postId, commentId, data) {
    return this.service.post(`comments/${postId}/${commentId}`, data);
  }

  getMoreSubComment(commentId, lastSubCommentId) {
    return this.service.get('subcomments/' + commentId + '/' + lastSubCommentId + '/3');
  }

  deleteSubComment(commentID, subCommentID) {
    return this.service.delete('comments/' + commentID + '/' + subCommentID);
  }

  getSubCommentByCommentId(id) {
    return this.service.get('subcomments/' + id + '/0/10');
  }
}
