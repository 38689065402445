import {Component, OnChanges, OnInit} from '@angular/core';
import {ThemeService} from '../../../service/Theme/theme.service';
import {not} from 'rxjs/internal-compatibility';
import {SettingService} from '../../../service/Setting/setting.service';

@Component({
  selector: 'app-notification-setting',
  templateUrl: './notification-setting.component.html',
  styleUrls: ['./notification-setting.component.css']
})
export class NotificationSettingComponent implements OnInit {

  // theme
  public theme;

  // notification setting data
  public currentNotificationSettingData = {timezone: '', dateList: []};
  public isDirty;

  constructor(private themeService: ThemeService, private settingService: SettingService) { }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.getNotificationSetting();
  }

  // view function
  getNotificationSetting() {
    this.settingService.getSetting().subscribe((setting: any) => {
      const dateList = [...setting[0].notificationSetting.dateList];
      dateList.forEach(day => {
        day.timeList.forEach(time => {
          time.start = this.convertDateToString(time.start);
          time.end = this.convertDateToString(time.end);
        });
      });
      this.currentNotificationSettingData.dateList = dateList;
      this.currentNotificationSettingData.timezone = setting[0].notificationSetting.timezone;
    });
  }

  // action function
  onNotificationDayChange(event) {
    const findData = this.currentNotificationSettingData.dateList.find(notificationSettingData =>
      notificationSettingData.weekday === event.weekday);
    if (findData) {
      findData.active = event.active;
      findData.timeList = event.timeList;
    } else {
      this.currentNotificationSettingData.dateList.push(event);
    }
    this.isDirty = true;
  }

  onUpdateNotificationSetting() {
    const sendData = {
      timezone: this.currentNotificationSettingData.timezone,
      dateList: [...this.currentNotificationSettingData.dateList]
    };
    sendData.dateList.forEach(day => {
      day.timeList.forEach((time, index) => {
        time.start = this.convertStringToDate(time.start);
        time.end = this.convertStringToDate(time.end);
        if (time.start === '' || time.end === '') {
          delete time.start;
          delete time.end;
        }
      });
    });

    for (let i = 0; i < sendData.dateList.length; i++) {
      sendData.dateList[i].timeList = sendData.dateList[i].timeList.filter(time => time.start && time.end);
      if (sendData.dateList[i].timeList.length === 0) {
        sendData.dateList[i].active = false;
      }
    }

    this.settingService.updateSetting({notificationSetting: sendData}).subscribe((data) => {
      this.isDirty = false;
      this.getNotificationSetting();
    });
  }

  onCancel() {
    this.getNotificationSetting();
  }

  // helper function
  convertDateToString(dateString) {
    if (!dateString) { return ''; }
    const date = new Date(dateString);
    return `${date.getHours() > 12 ? date.getHours() % 12 : date.getHours()}:${date.getMinutes()}${date.getMinutes() === 0 ? '0' : ''} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
  }

  convertStringToDate(str) {
    if (!str || str.trim() === '') { return ''; }
    const now = new Date();
    const timeSplit = str.split(' ');
    const hourMinSplit = timeSplit[0].split(':');
    const hour = parseInt(hourMinSplit[0], 10);
    const min = parseInt(hourMinSplit[1], 10);

    if (timeSplit[1] === 'AM') {
      now.setHours(hour);
      now.setMinutes(min);
    } else if (timeSplit[1] === 'PM') {
      if (hour === 12) {
        now.setHours(hour);
        now.setMinutes(min);
      } else {
        now.setHours(hour + 12);
        now.setMinutes(min);
      }
    }
    return now;
  }

  // boolean function
  isSettingChange() {
    return this.isDirty;
  }
}
