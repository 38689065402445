import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
// @ts-ignore
import data from './data.json';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor() {
  }

  getTenantForHostname(hostname: string) {
    return this.getTenantForHost(hostname.split('.')[0]);
  }

  getTenantForString(s: string) {
    const d = data.find((e) => {
      return (e.name.toLowerCase() === s.toLowerCase());
    });
    if (d) { return d.name; }
    return null;
  }

  getTenantForHost(host: string) {
    return this.getTenantForString(host);
  }

  getTenant() {
    return this.getTenantForHostname(location.hostname);
  }

  addTenantToHeaders(headers: HttpHeaders): HttpHeaders {
    return headers.append('X-Tenant-ID', this.getTenant());
  }
}
