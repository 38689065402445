import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {DefaultThemeComponent} from '../../../../component/Common/Theme/default-theme/default-theme.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {UserService} from '../../../../service/User/user.service';
import {Router} from '@angular/router';
import {DarkmodeService} from '../../../../service/darkmode.service';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {TimelineService} from '../../../../service/Timeline/timeline.service';

@Component({
  selector: 'app-view-list-dialog',
  templateUrl: './view-list-dialog.component.html',
  styleUrls: ['./view-list-dialog.component.css']
})
export class ViewListDialogComponent implements OnInit, AfterViewInit {

  // scroll height
  @ViewChild('container') scrollContainer;
  public scrollHeight = 400;

  // next page
  public nextID = 0;
  public userList = [];

  // load more
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 1;

  public theme: DefaultThemeComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data, private userService: UserService,
              private timelineService: TimelineService, private viewDialog: MatDialogRef<ViewListDialogComponent>,
              private dialog: MatDialog, private router: Router, private snackBar: MatSnackBar,
              private darkmodeService: DarkmodeService, private themeService: ThemeService) { }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.getViewedUserList(this.nextID);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollHeight = this.scrollContainer.nativeElement.offsetHeight;
    }, 500);
  }

  // get view date and load more
  getViewedUserList(nextId) {
    this.timelineService.getViewedUserList(this.data, nextId).subscribe((viewedUser: any) => {
      this.userList = this.userList.concat(viewedUser.list);
      this.nextID = viewedUser.next ? viewedUser.next : 0;
    });
  }

  getMoreViewedUserList() {
    if (this.nextID !== 0) {
      this.getViewedUserList(this.nextID);
    }
  }

  // open profile
  onOpenProfileDialog(member) {
    const user = {
      firstName: member.firstName,
      lastName: member.lastName,
      jobLabel: member.job,
      profileUrl: member.profileUrl,
      email: member.email,
      _id: member._id,
      didShowMenuButton: true
    };
    // if (member._id !== this.userService.getUserId()) {
    //   this.dialog.open(ProfileDialogComponent, {
    //     width: '500px',
    //     restoreFocus: false,
    //     data: {
    //       user,
    //       isUser: true
    //     }
    //   });
    // } else {
    //   this.dialog.open(CurrentProfileDialogComponent, {
    //     width: '500px',
    //     restoreFocus: false,
    //     data: {
    //       user,
    //       isUser: true
    //     }
    //   });
    // }
  }

  onClose() {
    this.viewDialog.close();
  }

  openSnackbar(message, action) {
    const snackBarRef = this.snackBar.open(message, action, {
      duration: 20000,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: ['snackbar']
    });
    snackBarRef.afterDismissed().subscribe(() => {
    });
    snackBarRef.onAction().subscribe(() => {
    });
  }
}
