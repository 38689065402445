import {AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild} from '@angular/core';
import {FileUploader, FileUploaderOptions} from "ng2-file-upload";
import {UserService} from "../../../../service/User/user.service";
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from "@angular/material";
import {DarkmodeService} from "../../../../service/darkmode.service";
import {UploadService} from "../../../../service/common/upload.service";
import {DomSanitizer} from "@angular/platform-browser";
import {LinkPreviewService} from "../../../../service/Timeline/link-preview.service";
import {ErrorDialogComponent} from "../../../organizationchart/error-dialog/error-dialog.component";
import * as $ from 'jquery';
import {v4 as uuid} from 'uuid';
import {TimelineService} from "../../../../service/Timeline/timeline.service";
import {MentionService} from '../../../../service/common/mention.service';
import {ThemeService} from "../../../../service/Theme/theme.service";
import {Caret} from "textarea-caret-ts";

@Component({
  selector: 'app-update-post-dialog',
  templateUrl: './update-post-dialog.component.html',
  styleUrls: ['./update-post-dialog.component.css', '../input-dialog/input-dialog.component.css']
})
export class UpdatePostDialogComponent implements OnInit, AfterViewInit {

  public isDarkMode;

  // preview Data
  public mediaPreview = [];
  public filePreview = [];

  // Data before post
  private pictureData = [];
  private videoData = [];
  private fileData = [];
  public loading = false;

  /// post thing data
  public txtPost = '';
  public quoteLimit = 100;

  public fileType;
  public gif;
  public openEmoji;

  public progress;
  public allProcess: number;

  public uploader: FileUploader;
  private uploadUrl;
  private uploadClouldName;

  // link preview
  public typingTimer;
  public inputLinkPreview;
  public showLinkPreview;
  public linkLoading;

  // mention
  public showMentionMenu;
  public mentionMenuPosition;

  // who can see your post
  public selectedVisibility;

  // auto open file input
  public openFile: EventEmitter<any> = new EventEmitter();
  @ViewChild('fileInput') fileInput;
  @ViewChild('imageInput') imageInput;
  @ViewChild('videoInput') videoInput;
  @ViewChild('visibilityInput') visibilityInput;

  // update post
  public isChanged;
  private orignalText = this.data.text;

  // theme
  public theme;

  public admin;

  @ViewChild('Text') textArea;
  @ViewChild('tooh') tooh;

  constructor(private userService: UserService, private dialog: MatDialogRef<UpdatePostDialogComponent>,
              private darkmodeService: DarkmodeService, private uploadService: UploadService,
              private _snackBar: MatSnackBar, private sanitizer: DomSanitizer, private timelineService: TimelineService,
              private linkPreviewService: LinkPreviewService, @Inject(MAT_DIALOG_DATA) public data,
              public mentionService: MentionService, private themeService: ThemeService) {
    this.admin = this.userService.getToken().user;
  }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    // init data
    this.txtPost = this.mentionService.convertMentionStringToString(this.data.text);
    this.selectedVisibility = this.data.visibility+'';

    setTimeout(() => {
      this.textArea.nativeElement.focus();
    }, 500);
    this.isDarkMode = this.darkmodeService.getLocalDarkMode();
    const self = this;
    this.openEmoji = false;

    // upload media
    this.uploadClouldName = this.uploadService.uploadClouldName;
    this.uploadUrl = this.uploadService.uploadUrl;

    this.allProcess = 0;
    this.progress = 0;

    const uploaderOptions: FileUploaderOptions = {
      url: this.uploadUrl,
      autoUpload: false,
      isHTML5: true,
      removeAfterUpload: true,
      maxFileSize: 10 * 1024 * 1024,
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    this.uploader = new FileUploader(uploaderOptions);

    //link
    this.showLinkPreview = false;
    this.linkLoading = false;
    if(this.mediaPreview.length <= 0 && this.filePreview.length <= 0 && this.data.media.length <= 0 && this.data.metaPreview.length <= 0) {
      this.lookingForLinkData();
    }

    // build media for use in tag, filename...
    this.uploader.onBuildItemForm = (fileItem, form) => {
      let fileType = fileItem.file.type.split('/')[0];

      if (fileType !== 'image' && fileType !== 'video') {
        fileType = 'file';
        fileItem.file.type = 'file';
        form.append('upload_preset', this.uploadService.uploadFilePreset);
      } else if (fileType === 'image') {
        form.append('upload_preset', this.uploadService.uploadImagePreset);
      } else if (fileType === 'video') {
        const isLand = this.isVideoLand(URL.createObjectURL(fileItem._file));
        if(isLand) {
          form.append('upload_preset', this.uploadService.uploadVideoLandPreset);
        } else {
          form.append('upload_preset', this.uploadService.uploadVideoPortPreset);
        }
      }

      form.append('file', fileItem);
      form.append('tags', fileType);
      form.append('public_id', "wallstreet_"+this.admin._id+"_"+new Date().getTime());

      fileItem.withCredentials = false;
      this.progress = 0;
      return {fileItem, form};
    };

    // validate file if it too many
    this.uploader.onAfterAddingAll = (fileItem) => {
      if (this.uploader.queue.length > 10) {
        this.uploader.clearQueue();
        this.filePreview = [];
        this.mediaPreview = [];
        this._snackBar.openFromComponent(ErrorDialogComponent, {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: 'fileTooMany'
        });
        this.allProcess = 0;
      }
    };

    // validate file post with image/video
    this.uploader.onAfterAddingFile = fileItem => this.addFile(fileItem);

    // validate file is too large
    this.uploader.onWhenAddingFileFailed = (filter: any) => {
      if(filter.size / (1024 * 1024) > 10){
        this._snackBar.openFromComponent(ErrorDialogComponent, {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: 'fileTooBig'
        });
      }
    };

    // each media after upload
    this.uploader.onCompleteItem = (item, response, status, headers) => {
      const res = JSON.parse(response);
      const getData = this.uploadService.getClouldinaryUrl(res);
      if (res.tags[0] === 'image') {
        this.pictureData.push({
          url: getData.mediaTransformUrl,
          thumbnail: getData.mediaTransformUrl,
          width: getData.width,
          height: getData.height
        });
      } else if (res.tags[0] === 'video') {
        this.videoData.push({
          url: getData.mediaTransformUrl,
          thumbnail: getData.videoThm,
          duration: res.duration,
          width: getData.width,
          height: getData.height
        });
      } else {
        this.fileData.push({
          url: res.secure_url,
          thumbnail: res.secure_url,
          format: this.convertFileToStandard(item.file),
          mediaName: item.file.name,
          size: res.bytes
        });
      }
    };

    //when all the media completed upload
    this.uploader.onCompleteAll = () => {
      let data = {
        "text": this.txtPost,
        "visibility": parseInt(this.selectedVisibility, 10),
        "media": []
      };
      let originalIndex = 0;
      this.data.media.forEach(media => {
        data.media.push(media);
        originalIndex++;
      });

      if(this.pictureData.length>0 || this.videoData.length>0 || this.fileData.length>0) {
        this.videoData.forEach((f, i) => {
          data.media.push({
            "index": i+originalIndex,
            "type": "video",
            "url": f.url,
            "thumbnail": f.thumbnail,
            "duration": f.duration,
            "width": f.width,
            "height": f.height
          });
        });

        this.pictureData.forEach((f, i) => {
          data.media.push({
            "index": i + this.videoData.length+originalIndex,
            "type": "image",
            "url": f.url,
            "thumbnail": f.thumbnail,
            "width": f.width,
            "height": f.height
          });
        });

        this.fileData.forEach((f, i) => {
          data.media.push({
            "index": i+originalIndex,
            "type": "file",
            "url": f.url,
            "thumbnail": f.url,
            "size": f.size,
            "mediaName": f.mediaName,
            "format": f.format
          });
        });
      }else{
        delete data.media;
      }


      this.timelineService.updateTimelinePost(this.data._id, data).subscribe(
        (res) => {
          this.loading = false;
          this.dialog.close(res);
        }, (error) => {
          console.log(error);
        }
      );
    };

    this.uploader.onProgressAll = (progress) => {
      this.progress = progress
    };

    $(document).on('click', function(e) {
      if(e.target.nodeName == "DIV" || e.target.nodeName.includes('MAT-CARD') || e.target.nodeName.includes('MAT-CHIP')){
        if(e.target.className != "emoji-mart") {
          if(e.target.childNodes[0] && e.target.childNodes[0].nodeName == 'svg') {
          }else{
            self.openEmoji = false;
          }
        }
      }
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      // resize the height
      this.textArea.nativeElement.style.height = Math.min(this.textArea.nativeElement.scrollHeight + 17) + "px";
      this.tooh.nativeElement.scrollTop = this.tooh.nativeElement.scrollHeight;
    }, 500);
  }

  // check if user put image/video with file
  addFile(fileItem) {
    let fileType = fileItem.file.type.split('/')[0];
    this.allProcess++;
    if(fileType != 'image' && fileType != 'video') {
      if(this.mediaPreview.length == 0) {
        this.filePreview.push({
          mediaName: fileItem.file.name,
          format: this.convertFileToStandard(fileItem.file)
        });
      } else {
        this._snackBar.openFromComponent(ErrorDialogComponent, {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: 'fileWithImageVideo'
        });
        this.uploader.queue[this.uploader.queue.length-1].remove();
      }
    } else {
      if(this.filePreview.length == 0) {
        this.mediaPreview.push({url: this.showPictureOffline(fileItem), type: fileType});
      } else {
        this._snackBar.openFromComponent(ErrorDialogComponent, {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: 'fileWithImageVideo'
        });
        this.uploader.queue[this.uploader.queue.length-1].remove();
      }
    }
  }

  selectedEmoji(event){
    this.txtPost += event.emoji.native;
  }

  onClearMedia(i) {
    this.mediaPreview.splice(i, 1);
    if(this.uploader.queue.length > 0) {
      this.uploader.queue[i].remove();
    }
    if(this.mediaPreview.length <= 0 && this.filePreview.length <= 0 && this.data.media.length <= 0 && this.data.metaPreview.length <= 0) {
      this.lookingForLinkData();
    }
  }

  onClearFile(i) {
    this.filePreview.splice(i, 1);
    if(this.uploader.queue.length > 0) {
      this.uploader.queue[i].remove();
    }
    if(this.mediaPreview.length <= 0 && this.filePreview.length <= 0 && this.data.media.length <= 0 && this.data.metaPreview.length <= 0) {
      this.lookingForLinkData();
    }
  }

  // update input height
  updateInputHeight() {
    if (this.textArea) {
      this.textArea.nativeElement.style.height = '';
      this.textArea.nativeElement.style.height = Math.min(this.textArea.nativeElement.scrollHeight + 17) + 'px';
    }
    if (this.tooh) {
      this.tooh.nativeElement.scrollTop = this.tooh.nativeElement.scrollHeight;
    }
  }

  // when user type thing
  onKeyup(event) {
    if (this.showMentionMenu) {
      this.mentionService.onKeyUp(event);
      if (this.textArea) {
        this.mentionMenuPosition = Caret.getAbsolutePosition(this.textArea.nativeElement);
      }
    }
    this.updateInputHeight();
    clearTimeout(this.typingTimer);
    if (this.mediaPreview.length <= 0 && this.filePreview.length <= 0) {
      this.typingTimer = setTimeout(() => {
        this.lookingForLinkData();
      }, 1000);
    }
    this.isChanged = this.txtPost !== this.orignalText;
  }

  async lookingForLinkData() {
    if(this.txtPost) {
      this.showLinkPreview = false;
      const metaData = await this.linkPreviewService.requestLinkPreview(this.txtPost, this);
      if (metaData) {
        this.inputLinkPreview = {metaPreview: [metaData]};
        this.showLinkPreview = true;
      }
    } else {
      this.showLinkPreview = false;
    }
    this.linkLoading = false;
  }

  onKeydown(event) {
    if (this.showMentionMenu) {
      this.txtPost = this.mentionService.onKeyDown(event);
      if (event.key === 'Enter') {
        this.showMentionMenu = false;
      }
    } else {
      this.mentionService.singleMention.emit(false);
    }
  }

  onSelectedMention(mention) {
    this.textArea.nativeElement.focus();
    this.txtPost = this.mentionService.onSelectMention(mention, this.txtPost);
    this.showMentionMenu = false;
  }

  onTextChange(event) {
    this.showMentionMenu = this.mentionService.onTextChange(event);
  }

  async onPost(){
    if(!this.checkAllowPost()) {
      if (this.mediaPreview.length > 0 || this.filePreview.length > 0) {
        this.uploader.uploadAll();
        this.loading = true;
      } else if (this.txtPost) {
        this.loading = true;
        let sendData = {
          "text": this.txtPost,
          "media": this.data.media,
          "visibility": parseInt(this.selectedVisibility, 10)
        };

        if(sendData.media.length <= 0) {
          delete sendData.media;
        }

        if (this.linkPreviewService.checkIfUrl(this.txtPost)) {
          await this.linkPreviewService.requestMultipleLinkPreviewBeforePost(this.txtPost).then(metaPreview => {
            sendData['metaPreview'] = metaPreview;
          }).catch(e => {
            console.log(e);
          });
        }

        this.timelineService.updateTimelinePost(this.data._id, sendData).subscribe((res) => {
          this.dialog.close(res);
        }, err => {
          console.log(err);
        })
      }
    }
  }

  showPictureOffline(input) {
    return this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(input._file)));
  }

  //check enable post button
  checkAllowPost() {
    // return true can't post
    return !(((this.isChanged && (this.txtPost && this.txtPost.replace(/(\r\n|\n|\r)/gm, ""))) || (this.filePreview.length > 0 || this.mediaPreview.length > 0)
            || (this.selectedVisibility != this.data.visibility)) && !this.loading);
  }

  convertFileToStandard(file) {
    switch (file.type) {
      case 'application/x-zip-compressed':
        return 'zip';

      case 'application/pdf':
        return 'pdf';

      case 'text/plain':
        return 'txt';

      default:
        return file.name.split('.')[1]||'file';
    }
  }

  getVideoWidthHeight(src) {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => resolve({ width: video.videoWidth, height: video.videoHeight });
      video.onerror = reject;
      video.src = src;
    });
  }

  // boolean function
  // check if video is land or port
  async isVideoLand(src) {
    const video = await this.getVideoWidthHeight(src);
    return video['width'] > video['height'];
  }

  checkMedia() {
    return this.mediaPreview && (this.mediaPreview.length > 0 || this.filePreview.length > 0);
  }

  isTextQuote() {
    return (this.txtPost && this.txtPost.length > 0) && (this.txtPost.length <= this.quoteLimit) &&
      !this.linkPreviewService.checkIfUrl(this.txtPost) &&
      !this.txtPost.includes('#') && !this.txtPost.includes('@') &&
      !this.checkMedia();
  }
}
