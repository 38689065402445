import { Component } from '@angular/core';
import {DarkmodeService} from "./service/darkmode.service";
import {LanguageService} from "./service/Setting/language.service";
import {ThemeService} from "./service/Theme/theme.service";
import {DefaultThemeComponent} from "./component/Common/Theme/default-theme/default-theme.component";
import {SettingService} from "./service/Setting/setting.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public isDarkmode;
  public theme: DefaultThemeComponent;

  constructor(private darkmodeService: DarkmodeService, private languageService: LanguageService,
              private themeService: ThemeService, private settingService: SettingService){
  }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.isDarkmode = this.darkmodeService.getLocalDarkMode();
    this.darkmodeService.getDarkMode().subscribe(data => {
     this.isDarkmode = data;
    });
  }
}
