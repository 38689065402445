import { Component, OnInit } from '@angular/core';
import {MatDialogRef, MatSnackBar} from '@angular/material';
import {ErrorDialogComponent} from '../../../organizationchart/error-dialog/error-dialog.component';
import {JobService} from '../../../../service/Job/job.service';

@Component({
  selector: 'app-add-job-dialog',
  templateUrl: './add-job-dialog.component.html',
  styleUrls: ['./../job-dialog/job-dialog.component.css']
})
export class AddJobDialogComponent implements OnInit {

  public label;
  constructor(private dialog: MatDialogRef<AddJobDialogComponent>,
              private _snackBar: MatSnackBar, private jobService: JobService) { }

  ngOnInit() {
  }

  onClose() {
    this.dialog.close(false);
  }

  onSave() {
    if (this.label) {
      const data = {label: this.label};

      this.jobService.addNewJob(data).subscribe((data) => {
          this._snackBar.openFromComponent(ErrorDialogComponent, {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'end',
            data: 'addJob'
          });
        }, (error) => {
          console.log(error);
        }
      );
      this.dialog.close(true);
    }
  }

}
