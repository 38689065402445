import {AfterViewInit, Component, EventEmitter, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ErrorDialogComponent} from '../../../organizationchart/error-dialog/error-dialog.component';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {UploadService} from '../../../../service/common/upload.service';
import {UserService} from '../../../../service/User/user.service';
import {DefaultThemeComponent} from '../../../../component/Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {SettingService} from '../../../../service/Setting/setting.service';
import {JobService} from '../../../../service/Job/job.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-add-intervenant-dialog',
  templateUrl: './add-intervenant-dialog.component.html',
  styleUrls: ['./../../staff/add-staff-dialog/add-staff-dialog.component.css']
})
export class AddIntervenantDialogComponent implements OnInit, AfterViewInit {

  public pageLoaded;
  public addIntervenantForm: FormGroup;
  public jobs;
  public inputData;

  // filter job
  filteredOptions: Observable<string[]>;

  public uploader: FileUploader;
  private uploadPreset;
  private uploadUrl;
  private uploadClouldName;
  public picture;
  public doneProcess;

  // cover picture
  public profileCover;
  public theme: DefaultThemeComponent;

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar,
              private uploadService: UploadService, private userService: UserService, private themeService: ThemeService,
              private settingService: SettingService, private jobService: JobService) { }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    // upload image
    this.doneProcess = true;
    this.uploadClouldName = this.uploadService.uploadClouldName;
    this.uploadUrl = this.uploadService.uploadUrl;
    this.uploadPreset = this.uploadService.uploadProfilePreset;
    this.initUpload();

    this.getCoverPicture();

    // init form
    this.addIntervenantForm = new FormGroup({
      name: new FormControl(''),
      encryption: new FormControl(''),
      video: new FormControl(''),
      audio: new FormControl(''),
      contact: new FormControl('')
    });

    // set data to form
    this.addIntervenantForm.get('encryption').setValue(false);
    this.addIntervenantForm.get('video').setValue(false);
    this.addIntervenantForm.get('audio').setValue(false);
    this.addIntervenantForm.get('contact').setValue(false);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 1000);
  }

  initUpload() {
    const uploaderOptions: FileUploaderOptions = {
      url: this.uploadUrl,
      autoUpload: false,
      isHTML5: true,
      removeAfterUpload: true,
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onBuildItemForm = (fileItem, form) => {
      const fileType = fileItem.file.type.split('/')[0];
      form.append('upload_preset', this.uploadPreset);
      form.append('file', fileItem);
      form.append('tag', fileType);
      fileItem.withCredentials = false;
      this.doneProcess = false;
      return {fileItem, form};
    };

    this.uploader.onCompleteItem = (item, response, status, headers) => {
      const res = JSON.parse(response);

      const getData = this.uploadService.getClouldinaryUrl(res);

      this.inputData.profileUrl = getData.mediaTransformUrl;
      this.doneProcess = true;
      console.log(this.inputData);
      this.sentCreateCustomer(this.inputData);
    };
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.jobs.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  onClearImage() {
    this.picture = null;
  }

  public hasError = (controlName, errorName: string) => {
    return this.addIntervenantForm.controls[controlName].hasError(errorName);
  }

  onClose() {
    this.dialog.closeAll();
  }

  findJob(label) {
    return this.jobs.find((job) => {
      return job.label.toLowerCase() === label.toLowerCase();
    });
  }

  sentCreateCustomer(inputData) {
    this.userService.addCustomer(inputData).subscribe(data => {
      this.dialog.closeAll();
    }, error => {
      console.log(error);
    });
  }

  onSubmit() {
    if (this.addIntervenantForm.valid) {
      const inputData = this.addIntervenantForm.value;
      this.sentCreateCustomer(inputData);
    }
  }

    onSlideChange(slideName) {
      const eachSlideToggleValues = this.addIntervenantForm.value;

      switch (slideName) {
        case 'encryption':
          const encryption  = !eachSlideToggleValues.encryption;
          this.addIntervenantForm.get('encryption').setValue(encryption);
          break;
        case 'video':
          const video  = !eachSlideToggleValues.video;
          this.addIntervenantForm.get('video').setValue(video);
          break;
        case 'audio':
          const audio  = !eachSlideToggleValues.audio;
          this.addIntervenantForm.get('audio').setValue(audio);
          break;
        case 'contact':
          const contact  = !eachSlideToggleValues.contact;
          this.addIntervenantForm.get('contact').setValue(contact);
          break;
      }
    }

  // cover picture
  getCoverPicture() {
    this.settingService.getSetting().subscribe(setting => {
      if (setting[0].contactBackground) {
        this.profileCover = setting[0].contactBackground;
      } else {
        this.profileCover = './assets/image/logo/default_cover_store.jpg';
      }
    }, error => {
      console.log(error);
    });
  }

  onFileSelected(event: EventEmitter<File[]>) {
    const file: File = event[0];
    this.viewImageOffline(file);
  }

  viewImageOffline(input) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.picture = e.target.result;
    };
    reader.readAsDataURL(input);
  }

  // bool
  isAllowCreate() {
    return this.addIntervenantForm.valid;
  }
}
