import {Component, Inject, OnInit} from '@angular/core';
import {FileUploader, FileUploaderOptions} from 'ng2-file-upload';
import {DefaultThemeComponent} from '../../component/Common/Theme/default-theme/default-theme.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {UserService} from "../../service/User/user.service";
import {ThemeService} from "../../service/Theme/theme.service";
import {ErrorDialogComponent} from "../organizationchart/error-dialog/error-dialog.component";
import {UploadService} from "../../service/common/upload.service";
import {SettingService} from "../../service/Setting/setting.service";

@Component({
  selector: 'app-current-profile-dialog',
  templateUrl: './current-profile-dialog.component.html',
  styleUrls: ['./current-profile-dialog.component.css']
})
export class CurrentProfileDialogComponent implements OnInit {

  public contactBgUrl;
  public didShow;

  // upload profile picture
  public uploader: FileUploader;
  public uploadUrl;
  public uploadClouldName;
  public allProcess;
  public progress;
  public mediaPreview = [];

  // license
  public profile;

  public theme: DefaultThemeComponent;

  constructor(private currentProfileDialog: MatDialogRef<CurrentProfileDialogComponent>,
              private userService: UserService, @Inject(MAT_DIALOG_DATA) public data,
              private uploadService: UploadService, private snackBar: MatSnackBar,
              private changePwDialog: MatDialog, private themeService: ThemeService,
              private settingService: SettingService) { }

  async ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.getContactBackground();

    // this.profile = licenseData.synchronize.profile;
    // this.userService.getSetting().subscribe(data => {
    //   this.contactBgUrl = data[0].contactBackground;
    // });
    // // get additional info
    // const jsonConvert: JsonConvert = new JsonConvert();
    // jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    // jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // allow null
    // if (!this.data.user.email && this.data.isUser) {
    //   const data = await this.userService.getOtherUserById(this.data.user._id).toPromise();
    //   try {
    //     const user = jsonConvert.deserializeObject(data, UserObj);
    //     this.data.user.email = user.email;
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    this.uploadClouldName = this.uploadService.uploadClouldName;
    this.uploadUrl = this.uploadService.uploadUrl;

    this.allProcess = 0;
    this.progress = 0;

    const uploaderOptions: FileUploaderOptions = {
      url: this.uploadUrl,
      autoUpload: false,
      isHTML5: true,
      removeAfterUpload: true,
      maxFileSize: 10 * 1024 * 1024,
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    this.uploader = new FileUploader(uploaderOptions);

    // build media for use in tag, filename...
    this.uploader.onBuildItemForm = (fileItem, form) => {
      const fileType = fileItem.file.type.split('/')[0];
      if (fileType === 'image') {
        form.append('upload_preset', this.uploadService.uploadImagePreset);
      }
      form.append('file', fileItem);
      form.append('tags', fileType);
      form.append('public_id', 'image/profile_' + new Date().getTime());
      fileItem.withCredentials = false;
      this.progress = 0;
      return {fileItem, form};
    };

    // validate file if it too many
    this.uploader.onAfterAddingAll = (fileItem) => {
      if (this.uploader.queue.length > 10) {
        this.uploader.clearQueue();
        this.mediaPreview = [];
        this.snackBar.openFromComponent(ErrorDialogComponent, {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: 'fileTooMany'
        });
        this.allProcess = 0;
      }
    };

    // validate file post with image/video
    // this.uploader.onAfterAddingFile = fileItem => this.addFile(fileItem);

    // validate file is too large
    this.uploader.onWhenAddingFileFailed = (filter: any) => {
      if (filter.size / (1024 * 1024) > 10) {
        this.snackBar.openFromComponent(ErrorDialogComponent, {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: 'fileTooBig'
        });
      }
    };

    // each media after upload
    this.uploader.onCompleteItem = (item, response, status, headers) => {
      const res = JSON.parse(response);
      const getData = this.uploadService.getClouldinaryUrl(res);
      if (res.tags[0] === 'image') {
        const sendData = {
          profileUrl: getData.mediaTransformUrl
        };
        this.onSaveNameSetting(sendData);
      }
    };

    this.uploader.onProgressAll = (progress) => {
      this.progress = progress;
    };
  }

  async onSaveNameSetting(data) {
    let sendData;
    if (!data) {
    } else {
      sendData = data;
    }
    this.userService.updateUser(this.userService.getUserId(), sendData).subscribe((res: any) => {
      this.data.user.profileUrl = res.profileUrl;
    }, err => {
      console.log(err);
    });
  }

  getContactBackground() {
    this.settingService.getSetting().subscribe((settingList) => {
      const settingData = settingList[0];
      this.contactBgUrl = settingData.contactBackground;
    })
  }

  onOpenChangePasswordDiaog() {
    if (this.profile.enableChangePassword) {
      // this.changePwDialog.open(ChangePasswordDialogComponent, {
      //   width: '340px'
      // });
      this.currentProfileDialog.close();
    }
  }

  onClose() {
    this.currentProfileDialog.close();
  }

}
