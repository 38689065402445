import { Component, OnInit } from '@angular/core';
import {DefaultThemeComponent} from '../default-theme/default-theme.component';

@Component({
  selector: 'app-red-theme',
  template: ''
})
export class RedThemeComponent extends DefaultThemeComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
