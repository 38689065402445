import { Component, OnInit } from '@angular/core';
import {UserService} from '../../service/User/user.service';
import DatePostUtil from '../../share/date-post-util';
import NotificationMessage from './notification-message';
import {PostDetailDialogComponent} from '../menu/timeline/post-detail-dialog/post-detail-dialog.component';
import {PreviewMediaDialogComponent} from '../menu/timeline/preview-media-dialog/preview-media-dialog.component';
import {TimelineService} from '../../service/Timeline/timeline.service';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.css']
})
export class NotificationDialogComponent implements OnInit {

  public newNotification;
  public loadNewData;
  public oldNotification;
  public loadOldData;

  public admin;

  constructor(private userService: UserService, private timelineService: TimelineService, private dialog: MatDialog) { }

  ngOnInit() {
    this.initVariable();
    this.getNewNotification();
    this.getOldNotification();
  }

  initVariable() {
    // loading
    this.loadOldData = false;
    this.loadNewData = false;

    // user data
    this.admin = this.userService.getToken().user;
  }

  getNewNotification() {
    this.userService.getNewNotification().subscribe((notification: any) => {
      this.newNotification = notification.list;
      this.loadNewData = true;
    });
  }

  getOldNotification() {
    this.userService.getOldNotification().subscribe((notification: any) => {
      this.oldNotification = notification.list;
      this.loadOldData = true;
    });
  }

  // DOM function
  getActivity(notification) {
    return NotificationMessage.notificationMessageArrayEnglish.find((messageArray) => {
      return (messageArray[0].localeCompare(notification.verb) === 0);
    })[1];
  }

  postTime(notification) {
    let date = new Date();
    if (notification.object && notification.object.length > 0) {
      date = notification.object[0].createdAt;
    } else {
      date = notification.createdAt;
    }
    return DatePostUtil.convertDateToPostDate(date);
  }

  // open notification
  onOpenNotification(notification) {
    if (notification.object && notification.object.length > 0) {
      const postObject = notification.object[0];
      if (postObject.objectType === 'post') {
        this.timelineService.getPostById(postObject._id).subscribe((post: any) => {
          // is it have image/video
          if (post.media && post.media.length > 0) {
            this.onShowImage(post.media, 0, post.createdAt, post.text, post.media[0].type, post);
          } else {
            this.onShowPost(post);
          }
        });
      }
    }
  }

  // open post detail
  onShowPost(post) {
    const dialog = this.dialog.open(PostDetailDialogComponent, {
      width: '500px',
      data: post
    });
  }

  // open image detail
  onShowImage(media, index, createDate, text, type, postData) {
    const data = {
      media,
      index,
      user: this.admin,
      createdAt: createDate,
      text,
      type,
      postData
    };

    const dialog = this.dialog.open(PreviewMediaDialogComponent, {
      data,
      minWidth: '1020px',
      maxWidth: '100vw',
      disableClose: true
    });

    dialog.backdropClick().subscribe(_ => {
      dialog.close();
    });
  }
}
