import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatMenuTrigger, MatSnackBar} from '@angular/material';
import {AddNewDialogComponent} from '../../../../dialog/organizationchart/add-new-dialog/add-new-dialog.component';
import {DeleteDialogComponent} from '../../../../dialog/delete-dialog/delete-dialog.component';
import {ErrorDialogComponent} from '../../../../dialog/organizationchart/error-dialog/error-dialog.component';
import {EditDialogComponent} from '../../../../dialog/organizationchart/edit-dialog/edit-dialog.component';
import {AddExistDialogComponent} from '../../../../dialog/organizationchart/add-exist-dialog/add-exist-dialog.component';
import {ExceptionDialogComponent} from '../../../../dialog/organizationchart/exception-dialog/exception-dialog.component';
import * as arraytotree from 'array-to-tree';
import * as $ from 'jquery';
import 'orgchart';
import {EditCompanyNodeDialogComponent} from '../../../../dialog/organizationchart/edit-company-node-dialog/edit-company-node-dialog.component';
import {AddDepartmentComponent} from '../../../../dialog/organizationchart/add-department/add-department.component';
import {ChangeUserDialogComponent} from '../../../../dialog/organizationchart/change-user-dialog/change-user-dialog.component';
import {DarkmodeService} from '../../../../service/darkmode.service';
import {StaffDialogComponent} from '../../../../dialog/menu/staff/staff-dialog/staff-dialog.component';
import {DefaultThemeComponent} from '../../../Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {OrganizationService} from '../../../../service/Organization/organization.service';
import {JobService} from '../../../../service/Job/job.service';
import {SettingService} from '../../../../service/Setting/setting.service';
import {LanguageService} from "../../../../service/Setting/language.service";

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {

  public isDarkMode;
  public theme: DefaultThemeComponent;

  // context menu
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  public allowAll;

  public saveFirstNode; /// save first node
  public jobData;       /// save job data

  public dd; // data from api
  public oc; // Oganization Chart
  public data; // data after convert to tree
  public saveID; // selected ID
  public tmpData;

  public zoomSize;
  public widthSize;
  public heightSize;
  public initGhost;

  // set organization name
  public organizationName;

  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar, private settingService: SettingService,
              private darkmodeService: DarkmodeService, private themeService: ThemeService,
              private organizationService: OrganizationService, private jobService: JobService,
              private languageService: LanguageService) { }

  async ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    // get Organization name
    this.organizationName = await this.settingService.getOrganizationName();

    this.initGhost = false;
    this.zoomSize = 1.2;
    this.widthSize = 220;
    this.heightSize = 65;
    this.isDarkMode = this.darkmodeService.getLocalDarkMode();
    this.darkmodeService.getDarkMode().subscribe(data => {
      this.isDarkMode = data;
    });
    this.jobService.getAllJob().subscribe(data => {
      this.jobData = data;
      const self = this;
      this.organizationService.getAllSubordination().subscribe(
        data => {
          /// get all data and convert to tree
          this.updateData(data);
          this.convertArraytoTree(this.dd);

          /// lib to make chart
          this.oc = $('#chart-container').orgchart({
            collapsed: false,
            data: this.data,
            nodeTitle: 'fullName',
            nodeContent: 'body',
            parent: 'parent',
            draggable: true,
            pan: false,
            zoom: false,
            verticalLevel: 4,
            createNode($node, data) {
              const $head = $node.find('.title');
              if (data.level >= 4) {
                $head.addClass('fouth-level-child');
              } else if (!data.department) {
                $head.addClass('no-department');
              }

              if (data.parent != null) {
                if (data.fullUser.profileUrl) {
                  $head.append(`<div class="second-menu"><img src="` + data.fullUser.profileUrl + `" class="profileLogo"></div>`);
                  if (data.department) { $head.addClass('avatar-down'); }
                } else {
                  const secondMenu = `<div class="second-menu"><div class="single-logo" style="background-color: ` +
                    self.renderBackgroundColor(data.fullUser) + `"><div class="default-name">` +
                    self.renderInitial(data.fullUser) + `</div></div></div>`;
                  $head.append(secondMenu);
                  if (data.department) { $head.addClass('avatar-down'); }
                }
              } else {
                if (data.iconLightmode && data.iconDarkmode) {
                  let secondMenu;
                  if (self.isDarkMode == 'dark-mode' || self.isDarkMode == 'red-mode') {
                    secondMenu = '<div class="second-menu"><img class="avatar" src="' + data.iconDarkmode + '"></div>';
                  } else {
                    secondMenu = '<div class="second-menu"><img class="avatar" src="' + data.iconLightmode + '"></div>';
                  }
                  $head.append(secondMenu);
                  $node.addClass('firstNode');
                } else if (data.iconLightmode && self.isDarkMode == 'light-mode') {
                  const secondMenu = '<div class="second-menu"><img class="avatar" src="' + data.iconLightmode + '"></div>';
                  $head.append(secondMenu);
                  $node.addClass('firstNode');
                } else if (data.iconDarkmode && (self.isDarkMode == 'dark-mode' || self.isDarkMode == 'red-mode')) {
                  const secondMenu = '<div class="second-menu"><img class="avatar" src="' + data.iconDarkmode + '"></div>';
                  $head.append(secondMenu);
                  $node.addClass('firstNode');
                } else {
                  let secondMenu;
                  if (self.isDarkMode == 'dark-mode' || self.isDarkMode == 'red-mode') {
                    secondMenu = '<div class="second-menu"><img class="avatar"' +
                      'src="../../../../../assets/image/logo/logo_sompom_white.png"></div>';
                  } else {
                    secondMenu = '<div class="second-menu"><img class="avatar"' +
                      'src="../../../../../assets/image/logo/logo_sompom.png"></div>';
                  }
                  // $head.append(secondMenu);
                  $node.addClass('firstNode');
                }
              }
            }
          });

          /// open context menu (right click)
          this.oc.$chartContainer.on('mouseup', '.node', function(event) {
            if (event.button == 2) {
              self.saveID = $(this).attr('id');
              if (self.saveFirstNode != self.saveID) {
                self.allowAll = true;
              } else {
                self.allowAll = false;
              }
              $('.cdk-overlay-container').prevObject[0].oncontextmenu = function(e) {
                return false;
              };
              $('.ghost-logo').css({
                display: 'none'
              });
              self.contextMenuPosition.x = event.clientX + 'px';
              self.contextMenuPosition.y = event.clientY + 'px';
              self.contextMenu.openMenu();
            }
          });

          /// drag and drop update data
          this.oc.$chartContainer.on('nodedrop.orgchart', function(event, extraParams) {
            const id = extraParams.draggedNode[0].id;
            const pa = extraParams.dropZone[0].id;
            self.organizationService.getAllSubordination().subscribe(data => {
              self.tmpData = data;
              const findData = self.tmpData.find((f) => {
                return f.user == id;
              })._id;

              self.organizationService.updateSubordination(findData, {parent: pa}).subscribe(data => {
                self.updateAfterChange();
                self.convertArraytoTree(self.dd);
                self.oc.init({data: self.data});
              }, error => {
                console.log(error);
              });
            }, error => {
              console.log(error);
            });
          });

        }, error => {
          console.log(error);
        }
      );
    }, error => {
      console.log(error);
    });

    const self = this;
    $('#chart-container').on('drag', (e) => {
      $('.ghost-logo').css({
        display: 'block',
        left: e.pageX - 100,
        top: e.pageY - 25,
        width: self.widthSize + 'px',
        height: self.heightSize + 'px'
      });
    });

    $('#chart-container').on('dragend', (e) => {
      $('.ghost-logo').css({
        display: 'none'
      });
    });
  }


  // context menu option
  onAddNewUser() {
    const dialogRef = this.dialog.open(AddNewDialogComponent, {
      data: this.saveID,
      width: '500px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateAfterChange();
        this.convertArraytoTree(this.dd);
        this.oc.init({data: this.data});
      }
    });
  }

  onAddExistingUser() {
    if (this.saveID) {
      const dialog = this.dialog.open(AddExistDialogComponent, {
        data: this.saveID,
        width: '500px'
      });

      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.updateAfterChange();
          this.convertArraytoTree(this.dd);
          this.oc.init({data: this.data});
        }
      });
    }
  }

  onDepartment() {
    if (this.saveID) {
      const dialogRef = this.dialog.open(AddDepartmentComponent, {
        data: this.saveID,
        width: '500px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.updateAfterChange();
          this.convertArraytoTree(this.dd);
          this.oc.init({data: this.data});
        }
      });
    }
  }

  onAuthorization() {
    if (this.saveID) {
      const dialog = this.dialog.open(ExceptionDialogComponent, {
        data: this.saveID,
        width: '1000px',
      });

      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.convertArraytoTree(this.dd);
          this.oc.init({data: this.data});
        }
      });
    }
  }

  onEditUser() {
    if (this.saveID) {
      const myData = this.findNodeByID(this.saveID);
      myData.profileUrl = myData.fullUser.profileUrl;
      const dialogRef = this.dialog.open(EditDialogComponent, {
        data: myData,
        width: '500px',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.updateAfterChange();
          this.convertArraytoTree(this.dd);
          this.oc.init({data: this.data});
        }
      });
    }
  }

  onEditRoot() {
    if (this.saveID) {
      const dialogRef = this.dialog.open(EditCompanyNodeDialogComponent, {
        data: this.organizationName,
        width: '500px',
      });

      dialogRef.afterClosed().subscribe(organizationName => {
        if (organizationName) {
          this.organizationName = organizationName;
          this.updateAfterChange();
          this.convertArraytoTree(this.dd);
          this.oc.init({data: this.data});
        }
      });
    }
  }

  onChangeUser() {
    if (this.saveID) {
      const dialogRef = this.dialog.open(ChangeUserDialogComponent, {
        data: this.saveID,
        width: '500px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.updateAfterChange();
          this.convertArraytoTree(this.dd);
          this.oc.init({data: this.data});
        }
      });
    }
  }

  onDisplayUser() {
    if (this.saveID) {
      const myData = this.findNodeByID(this.saveID);
      const dialog = this.dialog.open(StaffDialogComponent, {
        data: myData.fullUser,
        width: '500px',
      });

      dialog.afterClosed().subscribe((result) => {
        this.convertArraytoTree(this.dd);
      });
    }
  }

  onDeleteUser() {
    const deleteDialog = this.dialog.open(DeleteDialogComponent, {
      width: '500px'
    });

    deleteDialog.afterClosed().subscribe((result) => {
      if (result) {
        if (this.saveID) {
          if (!this.existChildID(this.saveID)) {
            this.organizationService.getAllSubordination().subscribe(data => {
              this.tmpData = data;
              const findData = this.tmpData.find((f) => {
                return f.user == this.saveID;
              })._id;
              this.organizationService.deleteSubordination(findData).subscribe(data => {
                this.updateAfterChange();
                this.convertArraytoTree(this.dd);
                this.oc.init({data: this.data});
              }, error => {
                console.log(error);
              });

              // delete expection user who are deleted
              let findDeletedData;
              this.organizationService.getAllManualBound().subscribe(data => {
                this.tmpData = data;
                this.tmpData.forEach(f => {
                  findDeletedData = f.others.find(d => {
                    return d == this.saveID;
                  });
                  if (findDeletedData) {
                    const sentMember = f.others.filter(o => {
                      return o != this.saveID;
                    });
                    this.organizationService.addorUpdateManualBound(f.user, {others: sentMember}).subscribe(data => {
                      console.log(data);
                    }, error => {
                      console.log(error);
                    });
                  }
                });
              }, error => {
                console.log(error);
              });
            }, error => {
              console.log(error);
            });
          } else {
            this._snackBar.openFromComponent(ErrorDialogComponent, {
              duration: 3000,
              verticalPosition: 'top',
              horizontalPosition: 'end',
              data: 'cantDeleteNode'
            });
          }
        }
      }
    });
  }


  // function
  existChildID(id) {
    let find = 0;
    this.dd.forEach((f) => {
      if (f.parent == id) {
        find++;
      }
    });
    return find > 0;
  }

  findNodeByID(id) {
    return this.dd.find((f) => {
      return f.user == id;
    });
  }

  updateData(data) {
    const org = this.languageService.getLanguage() === 'en' ? 'Organization' : 'Organisation';
    const dep = this.languageService.getLanguage() === 'en' ? 'Department' : 'département';

    this.dd = data;
    this.dd.forEach((f, i) => {
      if (i == 0) {
        f.user = f._id;
        f.fullName = '';
        f.body = '<div class="root-title">' + this.organizationName + '</div>' +
                `<div class="root-subtitle">${org}</div>`;
        f.id = f.user;
        this.saveFirstNode = f.user;
      } else {
        let fn, ln;

        if ((f.fullUser.firstName + f.fullUser.lastName).length > 15) {
          fn = f.fullUser.firstName[0].toUpperCase() + '.';
          ln = f.fullUser.lastName + '';
        } else {
          fn = f.fullUser.firstName;
          ln = f.fullUser.lastName;
        }

        f.firstName = f.fullUser.firstName;
        f.lastName = f.fullUser.lastName;
        f.email = f.fullUser.email;
        f.proType = f.fullUser.proType;
        f.id = f.user;


        // if have department show department with the dot on top and bottom
        f.fullName = '<div class="topNode">•</div>';
        if (f.department && (this.isDarkMode == 'dark-mode' || this.isDarkMode == 'red-mode')) {
          f.fullName = '<div><div class="topNode">•</div><div class="full-department">' + f.department +
            '</div><div class="sub-department">Department</div><div class="topNode">•</div><div class="custom-line">|</div></div>';
        }
        if (f.department && (this.isDarkMode != 'dark-mode' && this.isDarkMode != 'red-mode')) {
          f.fullName = '<div><div class="topNode">•</div><div class="light-full-department">' + f.department +
            `</div><div class="light-sub-department">${dep}</div><div class="topNode">•</div><div class="custom-line">|</div></div>`;
        }

        // the body of the organzation chart
        f.body = `<div><div style="font-family: 'SF UI Display Black', sans-serif; font-size: 15px; text-overflow: ellipsis; overflow: hidden; font-weight: bold;">`
          + fn + ' ' + ln.toUpperCase() + '</div>' + '<div style=\'font-size: 12px; color: #818181; width: 100%;text-overflow: ellipsis;overflow: hidden;\'>'
          + f.job.label + '</div></div>';
        f.job = f.job.label;
      }
      if (f.fullUser && f.fullUser.isDeleted == 'true') {
        this.dd.splice(i, 1);
      }
    });

    this.setContainerMiddle();
  }

  updateAfterChange() {
    this.organizationService.getAllSubordination().subscribe(data => {
      this.tmpData = data;
      this.updateData(this.tmpData);
      this.convertArraytoTree(this.dd);
      this.oc.init({data: this.data});
    }, error => {
      console.log(error);
    });
  }

  convertArraytoTree(dd) {
    this.data = arraytotree(dd, {
      parentProperty: 'parent',
      customID: 'user'
    })[0];
  }

  setContainerMiddle() {
    const outerContent = $('#chart-container');
    const innerContent = $('#chart-container > div');
    outerContent.scrollLeft((innerContent.width() - (outerContent.width()) / 2));
    outerContent.scrollTop((innerContent.height() - (outerContent.height()) / 2));
  }

  getJobById(id) {
    if (this.jobData) {
      return this.jobData.find((f) => {
        return f.id == id;
      });
    }
  }

  /*avatar*/
  // Take the char of first and last name
  hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i) {
    // tslint:disable-next-line:no-bitwise
    const c = (i & 0x00FFFFFF).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

  shouldDisplayAvatar(user) {
    if (user.profileUrl !== undefined) {
      return true;
    }
    return false;
  }

  renderBackgroundColor(user) {
    return this.intToRGB(this.hashCode(user.firstName + ' ' + user.lastName));
  }

  renderInitial(user) {
    return user.firstName.substring(0, 1).toUpperCase() + user.lastName.substring(0, 1).toUpperCase();
  }

  /*zoom*/
  onZoom(zoom) {
    if (zoom) {
      if (this.zoomSize < 1.2) {
        this.zoomSize += 0.2;
        this.widthSize += 40;
        this.heightSize += 12;
      }
    } else {
      if (this.zoomSize > 0.8) {
        this.zoomSize -= 0.2;
        this.widthSize -= 40;
        this.heightSize -= 12;
      }
    }
    $('.orgchart').css('transform', 'matrix(0.826472, 0, 0, 0.826472, 0, 0) scale(' + this.zoomSize + ', ' + this.zoomSize + ')');
  }
}
