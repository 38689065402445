import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {AddIntervenantDialogComponent} from '../../../../dialog/menu/intervenant/add-intervenant-dialog/add-intervenant-dialog.component';
import {IntervenantDialogComponent} from '../../../../dialog/menu/intervenant/intervenant-dialog/intervenant-dialog.component';
import {DeleteDialogComponent} from '../../../../dialog/delete-dialog/delete-dialog.component';
import {EditDialogComponent} from '../../../../dialog/organizationchart/edit-dialog/edit-dialog.component';
import {ErrorDialogComponent} from '../../../../dialog/organizationchart/error-dialog/error-dialog.component';
import {DarkmodeService} from '../../../../service/darkmode.service';
import {Router} from '@angular/router';
import {DefaultThemeComponent} from '../../../Common/Theme/default-theme/default-theme.component';
import {ThemeService} from '../../../../service/Theme/theme.service';
import {UserService} from '../../../../service/User/user.service';
import {GroupService} from '../../../../service/Group/group.service';
import {JobService} from '../../../../service/Job/job.service';

@Component({
  selector: 'app-intervenants',
  templateUrl: './intervenants.component.html',
  styleUrls: ['./../staff/staff.component.css']
})
export class IntervenantsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  public isDarkMode;

  public intervenantData;
  public dataSource;
  public jobs;
  private tmpData;

  private groupData;

  // deleted
  public deletedUserList;
  public deletedDataSource;

  // switch
  public isSwitch;

  public theme: DefaultThemeComponent;

  displayedColumns: string[] = ['name', 'encryption', 'video', 'audio', 'contact', 'action'];
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar,
              private darkmodeService: DarkmodeService, private router: Router, private themeService: ThemeService,
              private userService: UserService, private groupService: GroupService, private jobService: JobService) {
    this.updateData();
  }

  ngOnInit() {
    // Theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.isDarkMode = this.darkmodeService.getLocalDarkMode();
    this.darkmodeService.getDarkMode().subscribe(data => {
      this.isDarkMode = data;
    });
  }

  // View Function
  applyFilter(filterValue: string) {
    if (this.jobs && this.intervenantData) {
      this.intervenantData.forEach((f) => {
        f.jobLabel = this.getJobLabelById(f.job);
      });
      this.dataSource = new MatTableDataSource(this.intervenantData);
      this.deletedDataSource = new MatTableDataSource(this.deletedUserList);
    }
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.deletedDataSource.filter = filterValue.trim().toLowerCase();
  }

  updateData() {
    this.userService.getCustomer().subscribe((customerList: any) => {

      // filter not delete customer
      this.intervenantData = customerList.filter((user) => {
        return (user.isDeleted !== 'true' && user.isDeleted !== 'archived' && user.isDeleted !== 'deleted');
      });

      this.dataSource = new MatTableDataSource(this.intervenantData.reverse());

    }, error => {
      console.log(error);
    });
  }

  // action function
  onAddStaff() {
    const addStaffDialog = this.dialog.open(AddIntervenantDialogComponent, {
      width: '600px'
    });

    addStaffDialog.afterClosed().subscribe(() => {
      this.updateData();
    });
  }

  onView(staffData) {
    this.dialog.open(IntervenantDialogComponent, {
      data: staffData,
      width: '500px'
    });
  }

  onEdit(data) {
    data.user = data.id;
    data.isCustomer = true;
    const dia = this.dialog.open(EditDialogComponent, {
      data,
      width: '500px'
    });
    dia.afterClosed().subscribe(result => {
      if (result) {
        this.updateData();
      }
    });
  }

  onDelete(userData) {
    const userID = userData.id;
    this.userService.deleteCustomer(userID).subscribe(() => {
      this.updateData();
    }, error => {
      console.log(error);
    });
  }

  onRecover(data) {
    this.userService.updateUser(data._id, {isDeleted: 'recovered'}).subscribe(() => {
      this.updateData();
    }, error => {
      console.log(error);
    });
  }

  // helper function
  updateJob() {
    this.jobService.getAllJob().subscribe(data => {
      this.jobs = data;
    }, error => {
      console.log(error);
    });
  }

  getJobLabelById(id) {
    if (this.jobs) {
      return this.jobs.find((f) => {
        return f.id === id;
      }).label;
    }
  }

  getJobById(id) {
    if (this.jobs) {
      return this.jobs.find((f) => {
        return f.id === id;
      });
    }
  }

  removeConversationParticipants(groupId, userId) {
    this.groupService.getConversationByGroupId(groupId).subscribe(data => {
      this.tmpData = data;
      const part = [];
      this.tmpData.participants.forEach((f) => {
        if (f !== userId) {
          part.push(f);
        }
      });
      this.groupService.updateConversation(this.tmpData._id, {participants: part}).subscribe(() => {
      }, error => {
        console.log(error);
      });
    }, error => {
      console.log(error);
    });
  }

  removeGroupParticipants(groupId, userId) {
    this.groupService.getGroupById(groupId).subscribe(data => {
      this.tmpData = data;
      const part = [];
      this.tmpData.participants.forEach((f) => {
        if (f !== userId) {
          part.push(f);
        }
      });
      this.groupService.updateGroup(groupId, {participants: part}).subscribe(() => {
      }, error => {
        console.log(error);
      });
    }, error => {
      console.log(error);
    });
  }
}
