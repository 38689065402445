import {Component, Input, OnInit} from '@angular/core';
import {LinkPreviewService} from '../../../service/Timeline/link-preview.service';
import {DefaultThemeComponent} from "../Theme/default-theme/default-theme.component";
import {ThemeService} from "../../../service/Theme/theme.service";

@Component({
  selector: 'app-link-preview',
  templateUrl: './link-preview.component.html',
  styleUrls: ['./link-preview.component.css']
})
export class LinkPreviewComponent implements OnInit {

  @Input() data;
  @Input() isDarkmode;
  public metaPreviewData;

  // theme
  public theme: DefaultThemeComponent;

  constructor(private linkPreviewService: LinkPreviewService, private themeService: ThemeService) { }

  ngOnInit() {
    // theme
    this.theme = this.themeService.getComponent();
    this.themeService.themeListener.subscribe((theme) => {
      this.theme = theme;
    });

    this.data.metaPreview.forEach((meta) => {
      if (meta.type !== 'map') {
        this.metaPreviewData = meta;
      }
    });

    if (this.metaPreviewData && this.metaPreviewData.type === 'file') {
      const metaData = this.linkPreviewService.checkValidFileUrl(this.metaPreviewData);
      if (metaData.type !== 'unknown') {
        this.metaPreviewData.type = this.linkPreviewService.checkUrlFileType(this.metaPreviewData.url);
        this.metaPreviewData.fileExtension = metaData.fileExtension;
        this.metaPreviewData.fileName = metaData.fileName;
      } else {
        this.metaPreviewData.type = 'link';
      }
    }
  }

  onClick() {
    window.open(this.metaPreviewData.url, '_blank');
  }
}
