import { Injectable } from '@angular/core';
import {BaseService} from '../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private baseService: BaseService) { }

  /// organization request data
  getAllSubordination() {
    return this.baseService.get('prosubordination');
  }

  createSubordination(data) {
    return this.baseService.post('subordinations', data);
  }

  deleteSubordination(id) {
    return this.baseService.delete('subordinations/' + id);
  }

  updateSubordination(id, data) {
    return this.baseService.put('subordinations/' + id, data);
  }

  addorUpdateManualBound(user, data) {
    return this.baseService.post('api/pro/manualbound/' + user, data);
  }

  getAllManualBound() {
    return this.baseService.get('manualbounds');
  }
}
